import React,{useEffect, useState} from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik'
import axios from "../../../utilities/axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import './ShowModal.css'

const ShowModal = ({hideCancelForm, cancelationData}) => {
    const [bankList, setBankList] = useState([])
    useEffect(()=>{
        const getBankList =  async () =>{
            const banksList = await axios.get('/registrations/get-banks')
            console.log("bankList", banksList.data)
            setBankList(banksList.data)
          
        }
        getBankList()
    },[])
    
    const navigation = useNavigate();     
    const notifySuccess = (res, values) => {
 toast.success(res.data.message,{
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,    
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",

})

  setTimeout(()=>{
    navigation('/LoginDash/cancel-reservation', {state: {
        values
     }})
  },3000)
  hideCancelForm()
    }

    const formik = useFormik({
      initialValues: {
        accountName: "",
        accountNumber: "",
        cancellationNote: "",
        bank: "",
        sourceType: "TiptopgoWeb",
        reservationId: cancelationData.reservationId,
        reservedRoomId: cancelationData.reservedRoomId,
        reservationType: cancelationData.reservationType,
      },
      validationSchema: Yup.object({
        accountName: Yup.string()
          .min(3, "Account name must be more")
          .required("Account name is Required"),

          accountNumber: Yup.string()
          .matches(/^\d{10}$/, 'Account number must be a 10-digit number')
          .required('Account number is Required'),

          cancellationNote: Yup.string()
          .min(6, "Must not be less than 6 characters")
          .required("Message can't be empty"),

          bank: Yup.string()
          .max(100, "Bank name must be 100 characters")
          .required("Bank name is Required"),
      }),

      onSubmit: async (values) => {
        console.log(values)
        await axios
          .put("/registrations/handleReservedRoomUpdates", values)
          .then((res) => {
            if (res) {
              notifySuccess(res, values);
              // console.log("res", res)
            } else {
              //   notify(res)
              console.log("error");
            }
          })
          .catch((err) => console.log("no connection please check your connection", err));
      },
    
    });
    return (
        <div className='parentCongrat'>
           
            <div className='congratModalBody'>
            <div className='crossBtb'><button onClick={ hideCancelForm }>X</button></div>
            <h1>Cancel Reservation</h1>
            <form className="cancel_form" onSubmit={formik.handleSubmit}>
          <input
            type="text"
            placeholder="Account Name"
            name="accountName"
            id="accountName"
            value={formik.values.accountName.trimStart()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.accountName && formik.errors.accountName ? (
            <p className="error">{formik.errors.accountName}</p>
          ) : null}
          <input
            type="text"
            placeholder="Account Number"
            id="accountNumber"
            name="accountNumber"
            value={formik.values.accountNumber.trimStart()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.accountNumber && formik.errors.accountNumber ? (
            <p className="error">{formik.errors.accountNumber}</p>
          ) : null}
          <select 
          placeholder="Select Bank"
          name="bank"
          id="bank"
          value={formik.values.bank.trimStart()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          >
            <option value="">Select Bank</option>
            {
                bankList && bankList?.map((bank) => (
                    <option key={bank.bankId} value={bank.bankName}>{bank.bankName}</option>
                ))
            }
          </select>
          {formik.touched.bank && formik.errors.bank ? (
            <p className="error">{formik.errors.bank}</p>
          ) : null}
          <textarea
            id="cancellationNote"
            name="cancellationNote"
            rows="10"
            cols="50"
            placeholder="message"
            value={formik.values.cancellationNote.trimStart()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.cancellationNote && formik.errors.cancellationNote ? (
            <p className="error">{formik.errors.cancellationNote}</p>
          ) : null}
          <button  type='submit' className='doneBtn'> continue cancellation </button>
      </form>
      <p className='warningNote'><span style={{color:'red', fontSize:'1.5em'}}>Note!!</span> There will be a processing fee of  &#8358;500.00. Your balance will be deposited into the account with 3 business days. We appologize for any inconviences this might cause. Thank you for your understanding</p>
            </div>
            <ToastContainer />
        </div>
      )
}

export default ShowModal
