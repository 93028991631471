import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'

const Advertising = () => {
  return (
    <div>
    <NavBar />
    
    <div className='check_parent check_parent2'>
        <h1>Advert Here</h1>
     </div>
<Subscribe/>
 <Footer/>
</div>
  )
}

export default Advertising