import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import './LoadingSkeleton.css'

export const LoadingSkeleton = () => {
  return (
    <>
      <div className='skeletonParent'>
        <div className='room_type'>
          {/* <div className='book_img'>
            <Skeleton height={150} />
          </div> */}
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={500} height={30} />
            </h3>
            <span className='room_type_price'>
             
            </span>
          </div>
        </div>
        {/* <Skeleton width={50} style={{ marginTop: "-10%" }} /> */}
      </div>
      </>
      );
};

export const LoadingSkeletonAddress = () => {
  return(
    <>
    <div className='skeletonAddress'>
        <div className='room_type'>
          {/* <div className='book_img'>
            <Skeleton height={150} />
          </div> */}
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={500} height={25} />
            </h3>
            <span className='room_type_price'>
             
            </span>
          </div>
        </div>
        {/* <Skeleton width={50} style={{ marginTop: "-10%" }} /> */}
      </div>
      </>
  )
}

export const LoadingSkeletonDescription = () => {
  return(
    <>
    <div className='skeletonDescription'>
        <div className='room_type'>
          {/* <div className='book_img'>
            <Skeleton height={150} />
          </div> */}
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={7} width={700} height={20} />
            </h3>
            <span className='room_type_price'>
             
            </span>
          </div>
        </div>
      </div>

      <div className="skeletonImage">
        <div style={{width:'100%', height:'100%'}}>
        <div className="firstPartImage" style={{display:'flex', justifyContent:'space-evenly', alignItems:'center', width:'100%', height:'100%'}}>
        <Skeleton width={150}  height={170} />
        <Skeleton width={150}  height={170} />
        </div>
        <div className="firstPartImage" style={{display:'flex', justifyContent:'space-evenly', alignItems:'center', width:'100%'}}>
        <Skeleton width={150}  height={170} />
        <Skeleton width={150}  height={170} />
        </div>
        </div>
        <Skeleton width={400}  height={350} />
        </div>
      </>
  )
}

export const LoadingSkeletonAmenityName = () => {
  return(
    <>
    <div className='skeletonAmenityName'>
        <div className='room_type'>
          {/* <div className='book_img'>
            <Skeleton height={150} />
          </div> */}
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={400} height={20} />
            </h3>
            <span className='room_type_price'>
             
            </span>
          </div>
        </div>
        {/* <Skeleton width={50} style={{ marginTop: "-10%" }} /> */}
      </div>
      </>
  )
}

export const LoadingSkeletonAmenity = () => {
  return(
    <>
    <div className='skeletonAmenity'>
        <div className='room_type'>
          {/* <div className='book_img'>
            <Skeleton height={150} />
          </div> */}
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={150} height={50} />
            </h3>

            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={150} height={50} />
            </h3>

            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={150} height={50} />
            </h3>

            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={150} height={50} />
            </h3>
            <span className='room_type_price'>
             
            </span>
          </div>
        </div>
        {/* <Skeleton width={50} style={{ marginTop: "-10%" }} /> */}
      </div>
      </>
  )
}

export const LoadingSkeletonHotelList = () => {
  return(
    <div className="parentSkeletonHotelList">
    <div className='skeletonHotelList'>
        <div className='room_type'>
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={1} width={200} height={30} />
              <p></p>
              <Skeleton count={1} width={700} height={30} />
              <p></p>
              <Skeleton count={3} width={800} height={20} />
              <p></p>
              <Skeleton count={1} width={1000} height={70} />
              
            </h3>
          </div>
        </div>
      </div>
      <div className="skeletonImage">
        <div>
        <div className="firstPartImage">
        <Skeleton width={1000}  height={170} />
        <p></p>
        <Skeleton width={1000}  height={170} />
        <p></p>
        <Skeleton width={1000}  height={170} />
        </div>
        </div>
        </div>
      </div>
  )
}
    