import React, { useState, useEffect } from "react";
import "./HotelAmenities.css";
import {FiWifi} from 'react-icons/fi'
import { BiSolidFridge } from 'react-icons/bi'
import {MdOutlineCable, MdOutlineRestaurant} from 'react-icons/md'
import {FaMattressPillow} from 'react-icons/fa6'
import { FaTable, FaDumbbell, FaTv, FaSwimmer } from 'react-icons/fa';
import {LiaFanSolid} from 'react-icons/lia';
import { TbAirConditioning } from 'react-icons/tb';
import {GiConcentrationOrb} from 'react-icons/gi'
import { IconContext } from 'react-icons';
import {LoadingSkeleton, LoadingSkeletonAmenityName, LoadingSkeletonAmenity} from "../Hotel_Info/LoadingSkeleton/LoadingSkeleton";
const HotelAmenities = ({ selectedInfo }) => {
  const [hotelAmenities, setHotelAmenity] = useState([]);

  const amenityIconMap = {
    "Table": <FaTable  className="tableIcon"/>,
    "Gym": <FaDumbbell />,
    "TV": <FaTv />,
    "Swimming pool": <FaSwimmer className="poolIcon" />,
    "Pool": <FaSwimmer className="poolIcon" />,
    "Wifi": <FiWifi className="wifiIcon"/>,
    "Refrigerator": <BiSolidFridge className="fridgeIcon"/>,
    "Gotv": <MdOutlineCable className="cableIcon"/>,
    "mat": <FaMattressPillow/>,
    "Fan" : <LiaFanSolid/>,
    "Restaurant": <MdOutlineRestaurant/>,
    "A.C" : <TbAirConditioning/>,
    "AC" : <TbAirConditioning/>,
    "Air condition" : <TbAirConditioning/>,
    
    // Add more mappings as needed
  };

  useEffect(() => {
    setHotelAmenity(selectedInfo?.amenities);
  }, [selectedInfo]);

  return (
    <div className='parent_amen'>         
      <div className='hotel_amen_header'>
        <h2>Information about {selectedInfo?.hotelName || <LoadingSkeletonAmenityName />}</h2>
      </div>

      <div className='amen_list' >
        <ul>
          {/* {hotelAmenities &&
            hotelAmenities
              .map((amenity, i) => (
                <div key={i}>
                  <MdRamenDining className='icon' />
                  <li>{amenity.amenity}</li>
                </div>
              ))
              .slice(0, 8) || <LoadingSkeletonAmenity/>} */}

              {hotelAmenities && hotelAmenities.map((amenity, index) => (
                            <div key={index} >
                              <IconContext.Provider value={{ className: 'amenity-icon' }}>
                                {amenityIconMap[amenity.amenity] ? amenityIconMap[amenity.amenity] : <GiConcentrationOrb className="genIcon"/> }
                              </IconContext.Provider>
                              <li className="amenity">{amenity.amenity}</li>
                            </div>
              )).slice(0,8)}
        </ul>
      </div>
    </div>
  );
};

export default HotelAmenities;
