import React from "react";
import NavBar from "../../../NavBar/NavBar";
import Subscribe from "../../../Subscribe/Subscribe";
import Footer from "../../Footer";
import './Aboutus.css'

const AboutTiptop = () => {
  return (
    <div className="aboutTiptopgo">
      <NavBar />

      <div className='check_parent check_parent2 ptext'>
        <h1>About TipTopGo.com</h1>

        <p>
          Tiptopgo.com was created in 2022 in Abuja, Nigeria by Intellims
          Limited an indigenous Nigerian company, which has grown from a small
          startup company to one of the leading software development companies
          in Africa. We are an online travel agency specialising in hotel
          bookings within Nigeria. We help customers book hotel rooms online,
          provide comprehensive help and support to our clients and make the
          hotel booking process smooth and easy.Tiptopgo.com connects millions
          of travelers to a range of incredible places to stay from shortlets to
          hotels and much more. Tiptopgo.com also enables accommodations from
          hotels all over Nigeria reach their intended audience and grow their
          businesses.We also provide hotel recommendations and reviews, making
          sure that our clients stay in good, clean and safe hotels all over the
          country. Our Office is located at No. 3c Durban Street, Wuse 2, Abuja.
        </p>

        <p>
          For bookings -  <br />
          Email - 
          <br />
          Website – www.tiptopgo.com  <br />
          <p></p>
          All enquiries, whether made through Email or Phone, would be responded
          to not later than 48 hours from time of receipt. <p></p>Tiptopgo.com makes it
          easy and backs it all up with 24/7 customer support.
        </p>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};

export default AboutTiptop;
