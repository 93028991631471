import OTPInput from "react-otp-input";
import React, { useState } from "react";
import './Verify.css'
import NavBar from "../../HomePage/NavBar/NavBar";
import { useNavigate, useLocation} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Verify() {
  const [OTP, setOTP] = useState("");
  
  const history = useLocation();
  const navigation = useNavigate();
const {response, email, tipTopGoGuestId} = history.state

console.log("message", response)

  function handleChange(OTP) {
    setOTP(OTP.toString());
  }

  const notify = () => {
    toast.success("your email has been confirm",{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    
    })
  }

  const removeRoomNotify = () => {
    toast.warn("incorrect otp",{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      })
  }


const checkOtp = () => {
   
    setOTP(OTP);

    
    
    if(response === OTP){
      notify()

      setTimeout(()=>{
        navigation('/forget',{state:{
          tipTopGoGuestId
        }})
      },5000)
       
    }else{
      removeRoomNotify()
        // console.log("incorrect otp")
    }
}
  return (
    <>
    <NavBar/>
    <div className="verifyDiv">
      <p className="p1 p">Verify Account</p>
      <p className="p2 p">
        An OTP has been sent to your email {email}
      </p>
      
      <div className="otpElements">
        <p className="p3 p">Enter your Code here</p>
        <div className="otp">
          <OTPInput
            onChange={handleChange}
            value={OTP}
            inputStyle="inputStyle"
            numInputs={4}
            separator={<span></span>}
          />
        </div>

        <p className="p3 p">Didn't receive the code?</p>
        <p className="resend p">Resend</p>
      </div>
      <button type="submit" className="verifyBtn" onClick={checkOtp}>Verify</button>
     
    </div>
    <ToastContainer />
    </>
  );
}

export default Verify;