import React from "react";
import { Circles } from "react-loader-spinner";
const HotelsLoader = () => {
  return (
    <div
      className='hotelLoader'
      style={{
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: "0.5",
        position: "fixed",
        // height: '100vh',
        width: "100vw",
        top: "0",
        left:"0",
        right:"0",
        bottom: '0'
      }}
    >
      <Circles
        height='80'
        width='80'
        color='#2E2D4D'
        ariaLabel='circles-loading'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
      />
    </div>
  );
};

export default HotelsLoader;
