import React from 'react'
import './AboutIntel.css'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'

const AboutIntel = () => {
  return (
    <div>
        <NavBar />
        
        <div className='check_parent check_parent2'>
            <h1>About intellims Limited</h1>
         </div>
   <Subscribe/>
     <Footer/>
    </div>
  )
}

export default AboutIntel