import { CHANGE_CURRENCY } from "../Types/room_types";
import { useSelector } from "react-redux";

export const handleCurrencyChange = (currency) => async (dispatch) => {
  dispatch({
    type: CHANGE_CURRENCY,
    payload: currency,
  });
};

export const GetCurrencyData = () => {
  const currency = useSelector((state) => state.currency);
  return currency;
};
