import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import GetApp from '../Get App/GetApp'
import NavBar from '../NavBar/NavBar'
import * as Yup from "yup"
import { useFormik } from 'formik'
// import { handleClearStore } from '../../Redux/Actions/room_actions';
import axios from '../../utilities/axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleClearStore } from '../../Redux/Actions/room_actions'
  import { connect } from 'react-redux';
import './addHotel.css'
import {MdAppRegistration} from 'react-icons/md'
import {GiPencil} from 'react-icons/gi'
import Subscribe from '../Subscribe/Subscribe'

const AddHotel = ({handleClearStore}) => {
 
  const [countries, setCountries] = useState([]);
  const [stateName, setStateName] = useState('');
  const [states, setStates] = useState();
  const [states2, setStates2] = useState();
  const [defaultValue, setDefaultValue] = useState(null);
  const [newLGA, setNewLGA] = useState()
  const [showTextField, setShowTextField] = useState(false)
  
  const navigation = useNavigate();


  const notify = (res) => {
    toast.error(res.data.message,{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
        
    })
  }
  
  const notifySuccess = (res, values) => {
   toast.success(res.data.message,{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  
  })
}

    useEffect(()=>{
        handleClearStore()
       },[])

       const formik = useFormik({
        initialValues: {
            hotelName: "",
            hotelEmailAddress: "",
            hotelAddress: "",
            city: "",
            country: "",
            state: "",
            numberOfRooms: "",
            firstName: "",
            lastName: "",
            email:"",
            phoneNumber:"",
        },
        
        validationSchema: Yup.object({
          hotelName: Yup.string().min(3, "hotel name must be more").required("hotel name is required"),
          hotelEmailAddress: Yup.string().email("invalid email address").required("email is required"),
      
          hotelAddress: Yup.string().max(100, "please enter hotel address").required("address is required"),
          city: Yup.string().required("city is required"),
      
          state: Yup.string().required("state is required"),
        country: Yup.string().required("country is required"),
      
          numberOfRooms: Yup.string().matches(/^[0-9]+$/, 'number of room is only digit').required("number of room is required"),
          firstName: Yup.string().min(3, "first name must be more").required("first name is Required"),
            lastName: Yup.string().min(3, "last name is require").required("last name is Required"),
            email: Yup.string().email("invalid email address").required("Email is Required"),
            phoneNumber: Yup.string().matches(/^[0-9]+$/, 'Phone number must be only digits').required('Phonenumber is required'),
        }),
      
        onSubmit: async (values, { resetForm }) => {
        //   setLoader(true)
          await axios.post('/hotels/hotelRequest', values)
          .then(res =>  {
            if(res.data.message === "Registrated Successfully" ){
              notifySuccess(res, values)
              resetForm();
            }else{
              notify(res);
              resetForm();
            }
           })
          .catch(err => console.log("resgistration unsuccessfull", err))

         }

         
      })

      
      useEffect(()=>{
      const getCountry = async () => {
        await fetch('https://restcountries.com/v2/all')
      .then(response => response.json())
      .then(data => setCountries(data))
      // console.log("countries", countries)

      const defaultOption = countries.find(option => option.value === 'Nigeria ');
      setDefaultValue(defaultOption);
      }
      getCountry()
    },[])

    const getCountry2 = (country) => {
      if (country === "Nigeria") {
        setShowTextField(false);
      } else {
        setShowTextField(true);
      }
    };

      useEffect(()=>{
        const getState = async () =>{
          const resState = await axios.get('https://ipms.intellims.com:5300/api/v1/public/states')
          // console.log("resState", resState.data)
          const relState = await resState.data
          setStates(await relState)
        }
        setStateName(formik.values.state)
        getState()
      },[])

      
    useEffect(()=>{
      const getState2 = async () => {
        const resState2 = await axios.get(
          "https://ipms.intellims.com:5300/api/v1/public/local_govt"
        );

        const relState2 = await resState2.data;
        setStates2(await relState2);
      };
      
      // setStateName(formik.values.state)
      getState2()
    },[])

    const getMainLGA = (state) => {
      let anothrer = states2 && states2.filter((e) => e.state_id === state);

      setNewLGA(anothrer);
    };

  return (
    <div>
      <NavBar />
      <div className="toppart">
        <h2>Register your hotel on TiptopGo.com</h2>
        <p>
          TiptopGo.com has the capacity to reach millions of customer in and
          outside Nigeria.
          <br /> Register your hotel on TiptopGo.com and we will bring millions
          of customer for you
        </p>

        <p>
          Fill the Form below to register your Hotel, and we will contact you
          shortly
        </p>
      </div>
      <form className="parent_form_add" onSubmit={formik.handleSubmit}>
        <div className="sideImg">
          <img
            src="https://img.freepik.com/free-vector/mobile-login-concept-illustration_114360-135.jpg"
            alt="side"
          />
        </div>
        <div className="add_div">
          <div>
            <h2 className="personal_info">
              Hotel Information <GiPencil className="icon" />
            </h2>
            <div className="share">
              <div className="form_input">
                <label>Hotel Name</label>
                <input
                  type="text"
                  value={formik.values.hotelName.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="hotelName"
                  id="hotelName"
                />

                {formik.touched.hotelName && formik.errors.hotelName ? (
                  <span className="error">{formik.errors.hotelName}</span>
                ) : null}
              </div>

              <div className="form_input">
                <label>Hotel Email</label>
                <input
                  type="text"
                  value={formik.values.hotelEmailAddress.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="hotelEmailAddress"
                  id="hotelEmailAddress"
                />

                {formik.touched.hotelEmailAddress &&
                formik.errors.hotelEmailAddress ? (
                  <span className="error">
                    {formik.errors.hotelEmailAddress}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="addhotel_location">
              <div className=" form_input form_input2">
                <label className="labels">Country</label>
                <select
                  name="country"
                  id="country"
                  className="selectGender"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // onClick={getCountry}
                  onClick={() => getCountry2(formik.values.country)}
                >
                  <option disabled value="">
                    select country
                  </option>
                  {countries &&
                    countries.map((country, i) => (
                      <>
                        {/* <option disabled value="">state</option> */}
                        <option key={country.alpha2Code} value={country.name}>
                          {" "}
                          {country.name} {country.alpha2Code}{" "}
                        </option>
                      </>
                    ))}
                </select>

                {formik.touched.country && formik.errors.country ? (
                  <span className="error">{formik.errors.country}</span>
                ) : null}
              </div>

              <div className="form_input form_input2">
                <label className="labels">State</label>

                {showTextField ? (
                  <input
                    name="state"
                    id="state"
                    className="selectGender"
                    value={formik.values.state.trimStart()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <select
                    name="state"
                    id="state"
                    className="selectGender"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onClick={() => getMainLGA(formik.values.state)}
                  >
                    <option disabled value="">
                      select state
                    </option>
                    {states &&
                      states.map((state, i) => (
                        <>
                          {/* {console.log("state", state)} */}
                          <option id={i} value={state.stateId}>
                            {" "}
                            {state.name}{" "}
                          </option>
                        </>
                      ))}
                  </select>
                )}

                {formik.touched.state && formik.errors.state ? (
                  <span className="error">{formik.errors.state}</span>
                ) : null}
              </div>
              <div className="form_input form_input3">
                <label>City</label>
                {showTextField ? (
                  <input
                  name="city"
                  id="city"
                  className="selectGender"
                  value={formik.values.city.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  />
                ) : (
                  <select
                    name="city"
                    id="city"
                    className="selectGender"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option disabled value="">
                      select LGA
                    </option>
                    {newLGA &&
                      newLGA.map((lga, i) => (
                        <>
                          <option id={i} value={lga.name}>
                            {" "}
                            {lga.name}{" "}
                          </option>
                        </>
                      ))}
                  </select>
                )}

                {formik.touched.city && formik.errors.city ? (
                  <span className="error">{formik.errors.city}</span>
                ) : null}
              </div>

              <div className="form_input form_input3">
                <label className="numberRoomLab">Number of Rooms</label>
                <input
                  type="text"
                  value={formik.values.numberOfRooms.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="numberOfRooms"
                  id="numberOfRooms"
                />

                {formik.touched.numberOfRooms && formik.errors.numberOfRooms ? (
                  <span className="error">{formik.errors.numberOfRooms}</span>
                ) : null}
              </div>
            </div>

            <div className="form_input textarea">
              <label>Hotel Address</label>
              <textarea
                type="text"
                placeholder="Address"
                value={formik.values.hotelAddress.trimStart()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="hotelAddress"
                id="hotelAddress"
              />

              {formik.touched.hotelAddress && formik.errors.hotelAddress ? (
                <span className="error">{formik.errors.hotelAddress}</span>
              ) : null}
            </div>
          </div>

          <div>
            <h2 className="personal_info">
              Contact Person Information <GiPencil className="icon" />
            </h2>
            <div className="first_part_form"></div>
            <div className="share">
              <div className="form_input">
                <label>First Name</label>
                <input
                  type="text"
                  value={formik.values.firstName.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="firstName"
                  id="firstName"
                />

                {formik.touched.firstName && formik.errors.firstName ? (
                  <span className="error">{formik.errors.firstName}</span>
                ) : null}
              </div>

              <div className="form_input">
                <label>Last Name</label>
                <input
                  type="text"
                  value={formik.values.lastName.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="lastName"
                  id="lastName"
                />

                {formik.touched.lastName && formik.errors.lastName ? (
                  <span className="error">{formik.errors.lastName}</span>
                ) : null}
              </div>
            </div>
            <div className="share">
              <div className="form_input">
                <label>Email</label>
                <input
                  type="text"
                  value={formik.values.email.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="email"
                  id="email"
                />

                {formik.touched.email && formik.errors.email ? (
                  <span className="error">{formik.errors.email}</span>
                ) : null}
              </div>

              <div className="form_input">
                <label>Phone Number</label>
                <input
                  // placeholder='081312334'
                  type="text"
                  value={formik.values.phoneNumber.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="phoneNumber"
                  id="phoneNumber"
                />

                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <span className="error">{formik.errors.phoneNumber}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="share">
            <button className="add_hotelbtn">
              <MdAppRegistration className="icon" /> Register hotel
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
      <Subscribe />
      <GetApp />
      <Footer />
    </div>
  );
}

// const mapStateToProps = (state) => {
//     const cart = state.rootReducer.cart;
//     return {
//       cart
//     };
//   };

  
  export default connect(null, {handleClearStore})(
    AddHotel
  );
