import React from 'react'
import "./Support_Number.css"

export const Support_Number = () => {
  return (
    <div className='supportNum'>
      for support and enquiry call: +234 8135936594
    </div>
  );
}

export const Support_Number_Mobile = () => {
  const supportPhoneNumber = "08135936594";
  return (
    <div className='mobileSupportNum'>
      <a href={`tel:${supportPhoneNumber}`}>
        for support and enquiry call : +234 8135936594
      </a>
    </div>
  );
};

