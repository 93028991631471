import React, { useEffect, useState } from 'react';
import axios from '../../utilities/axios'
import { FaStar } from 'react-icons/fa';
import NavBar from '../../HomePage/NavBar/NavBar'
import GetApp from '../../HomePage/Get App/GetApp'
import Footer from '../../HomePage/Footer/Footer'
import CelebrationEffect from './CelebrationEffect'
import CongratulationModal from './CongratulationModal'
import {VscFeedback} from 'react-icons/vsc'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate, useParams  } from 'react-router-dom';
import {RiSubtractFill} from 'react-icons/ri'
import { Review } from './Revenue';
import './CustomerReview.css'

const CustomerReview = () => {
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTextField, setShowTextField] = useState(false)
  const [showTextField2, setShowTextField2] = useState(0)
  const [showTextField3, setShowTextField3] = useState(false)
  const [showTextField4, setShowTextField4] = useState(false)
  const [displayText, setDisplayText] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [guestDetails, setGuestDetails] = useState([]);
  const navigate = useNavigate()
  const { bookingCode, hotelName} = useParams();

  const stars = [1, 2, 3, 4, 5];

  // console.log("bookingCode", bookingCode)

  useEffect(()=>{
    const getGuestReservation = async () =>{
      
      const sourceType = 'TiptopgoWeb';
      const reservationResult = await axios.get(`registrations/getAppReservationRecords?filterType=${sourceType}&bookingCode=${bookingCode}`)
      .then((res)=>setGuestDetails(res.data))
      .catch((err) => console.log(err));  
      
    }
    getGuestReservation()
  },[bookingCode])

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);

    let text = '';
    switch (selectedRating) {
      case 1:
        text = 'Poor';
        break;
      case 2:
        text = 'Fair';
        break;
      case 3:
        text = 'Good';
        break;
      case 4:
        text = 'Very Good';
        break;
      case 5:
        text = 'Excellent';
        break;
      default:
        text = '';
    }

    setDisplayText(text);
  };

  const handleCloseModal = () => {
    setShowModal(false)
    setSubmitted(false);
    localStorage.removeItem('questionResponses');
    navigate("/",{state:{}})
  }

  // console.log("guestDetails", guestDetails[0])

  const questionResponsArr = {
    hotelName: guestDetails[0]?.hotelName,
    hotelId: guestDetails[0]?.hotelId,
    guestName: `${guestDetails[0]?.firstName} ${guestDetails[0]?.lastName}`,
    bookingCode: bookingCode,
    guestHotelRating: "",
    guestAdditionalInfo: additionalInfo,
    questions: [] 
  };

  const getAdditionalInfo = (e) => {
    e.preventDefault()
    const {value} = e.target
    const holdValue = value
    setAdditionalInfo(holdValue);
    questionResponsArr.guestAdditionalInfo = value
   
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    const userHotelRating = displayText 
    // const guestAdditionalInfo = additionalInfo
    questionResponsArr.guestHotelRating = userHotelRating
    // questionResponsArr.guestAdditionalInfo = guestAdditionalInfo
    const storedData = localStorage.getItem('questionResponses');
    const parsedData = JSON.parse(storedData);
    
    
    questionResponsArr.questions = parsedData
    // console.log("parsedData", parsedData)

    // console.log("questionResponsArr", questionResponsArr)
    axios.post(`/guests/createGuestSurveyQuestions`,questionResponsArr)
    .then(res => {
      setSubmitted(true);
      setShowModal(true)
      // console.log(res)
    }).catch(err => {console.log('error posting your review')})
   
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleOptionChange = (questionAnswer, question) => {
    const selectedOption = questionAnswer;
    const selectedDetails = question.question;
    const selectedDetailsId = question.questionId;
  
    // Create a new object for each question response.
    const existingQuestion = questionResponsArr.questions.find(
      (item) => item.mainQuestion === selectedDetails
    );
  
    if (existingQuestion) {
      // Update the answer for the existing question.
      existingQuestion.questionAns = selectedOption;
  
      // Update local storage with the updated array
      localStorage.setItem('questionResponses', JSON.stringify(questionResponsArr.questions));
    } else {
      // Create a new object for the question response and add it to the array.
      const mergedObject = {questionId: selectedDetailsId, mainQuestion: selectedDetails,  questionAns: selectedOption};
      questionResponsArr.questions.push({ ...mergedObject });
  
      // Update local storage with the updated array
      localStorage.setItem('questionResponses', JSON.stringify(questionResponsArr.questions));
    }
  };

  const handleShowTextF = () =>{
      setShowTextField(true)
  }
  const handleCloseTextF = () =>{
    setShowTextField(false)
}

  return (
    <div>
        <NavBar />
        <div className='parentReviewDiv'>
        <div className='customerReviewDiv'>
            <h1></h1>
            
            <div className='reviewQus'>
            <form>
              <div className='headerDivRev'>
                <span className='text'>Welcome to {Review[0].hotelName}</span>
              </div>
              <ol>
              <div className='mainQust'>
                <li>On a scale of 1 to 5, how would you rate your experience with the hotel?</li>
                
                <div className='radioLabel '>
                {stars.map((star) => (
                        <FaStar
                          key={star}
                          className={star <= rating ? 'selected' : 'notSelected'}
                          onClick={() => handleStarClick(star)}
                        />
                ))}
                </div>
                <div className='starDiv'>{displayText}</div>
                </div>
                {
                  Review && Review[0].hotelQuestions.map((question, i)=>(
              <div className='mainQust' key={question.questionId}>
                <li>{question.question}</li>
                <div className='radioLabel'>
                  <input 
                  type="radio" 
                  id={`radioOption1${question.questionId}`}
                  name={`radioGroup${question.questionId}`}
                   value={question.options1}
                   onChange={() => handleOptionChange(question.options1, question)}/>
                  <label htmlFor="radioOption1">{question.options1}</label>
                </div>
                
                <div className='radioLabel'>
                  <input type="radio"
                   id={`radioOption2${question.questionId}`}
                    name={`radioGroup${question.questionId}`}
                    value={question.options2}
                    onChange={() => handleOptionChange(question.options2, question)}/>
                  <label>{question.options2}</label>
                </div>

                {/* <div className='inputDivRev'>
                <label ><AiOutlinePlus className='addIcon' onClick={() => handleShowTextF(question.questionId)}/>Any Additional Information</label>
                {
                  showTextField && showTextField2 === question.questionId ? <input type="text" value={ additionalInfo} onChange={(e)=>getAdditionalInfo(e, question)}/> : null
                }
                
                </div> */}
                </div>
                  ))
                }
              </ol>
              <div className='inputDivRev'>
                <label >{ showTextField ? <RiSubtractFill className='addIcon'  onClick={ handleCloseTextF} /> :  <AiOutlinePlus className='addIcon' onClick={ handleShowTextF}/> }Any Additional Information</label>

                {
                  showTextField &&  <textarea rows="6"  type="text" value={ additionalInfo} onChange={(e) => getAdditionalInfo(e)}/> 
                }
                </div>
              <div className='submitReview'>
                <button type='button' onClick={(e)=>handleSubmit(e)} ><VscFeedback className='feedBackIcon'/>submit feedBack</button>
              </div>
              </form>
            </div>
          
        </div>
        {
          showModal ?  <CongratulationModal closeModal={handleCloseModal}/> : null
        }
       
        <CelebrationEffect active={submitted} />
        </div>
       
        <GetApp />
        <Footer />
    </div>
  )
}

export default CustomerReview