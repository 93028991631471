import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HotelsList.css";
import "./pagination.css";

import { MdOutlineFavoriteBorder } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import ReactPaginate from "react-paginate";
import axios from "../../utilities/axios";
import { Hearts } from "react-loader-spinner";
// import Loader from '../../utilities/Loader'
import HotelsLoader from "../../utilities/HotelsLoader";
import LoadingSkeleton from "../../IndividualHotel/HotelBooking/LoadingSkeleton/LoadingSkeleton";
import { FaStar, FaLocationArrow } from "react-icons/fa";
import { GrLike } from "react-icons/gr";
import { MdApartment } from "react-icons/md";
import { FiWifi } from "react-icons/fi";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { MdOutlineCable, MdOutlineRestaurant } from "react-icons/md";
import { FaMattressPillow } from "react-icons/fa6";
import { FaTable, FaDumbbell, FaTv, FaSwimmer } from "react-icons/fa";
import { LiaFanSolid } from "react-icons/lia";
import { TbAirConditioning } from "react-icons/tb";
import { GiConcentrationOrb } from "react-icons/gi";
import { IconContext } from "react-icons";
import { GetCurrencyData } from "../../Redux/Actions/currency_actions";
import { convertAmount } from "../../utilities/GeneralFunctions";

const HotelsComponents = ({
  hotelList,
  value,
  defaultDate,
  receivedData,
  propTypeclick,
  newHotelCount,
  showPaginate,
}) => {
  const storedCount = localStorage.getItem("pageNo");
  const navigation = useNavigate();
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [intersection, setIntersection] = useState([]);
  const [userData, setUserData] = useState();
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(storedCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [isStriked, setIsStriked] = useState(false);
  const [newArrayCont, setnewArrayCont] = useState([]);
  const [changeClass, setChangeClass] = useState(false);

  let allResult2 = defaultDate;
  let allResult = hotelList;

  if (receivedData.length >= 1) {
    allResult = receivedData;
  }
  // let value = allResult.hotelName;
  // console.log("allResult", allResult)
  useEffect(() => {
    if (allResult.length > 0) {
      // console.log("allResult checking", allResult);
      if (allResult[0]?.hotelHasFlatRate === true) {
        const newArrayCont = allResult.map((obj) => {
          if (obj?.hotelHasFlatRate) {
            const newKey = obj.hotelMinimumPrice - obj.commissionBonus;

            return {
              ...obj,
              newKey,
            };
          }
        });
        setItems(newArrayCont);
        setnewArrayCont(newArrayCont);
        setIsStriked(true);
      } else {
        setItems(allResult);
        setIsStriked(false);
      }
    } else {
      setItems(allResult);
      setIsStriked(false);
    }
  }, [allResult]);

  let favHotelId = [];
  // let guestId2;

  const isLogin = localStorage.getItem("loggInStatus");

  const checkLoggin = () => {
    if (isLogin === "true") {
      const userData = JSON.parse(localStorage.getItem("userData"));
      setUserData(userData);
    }
  };

  useEffect(() => {
    if (allResult.length > 0) {
      if (allResult[0]?.hotelHasFlatRate === true) {
        setItems(newArrayCont);
      } else {
        setItems(allResult);
      }

      let paginationSpread =
        allResult[0]?.hotelHasFlatRate === true
          ? newArrayCont[0]?.hotelCount || 1
          : allResult[0]?.hotelCount || 1;
      setPageCount(Math.ceil(paginationSpread / 12));
      setCurrentPage(storedCount);
    } else {
      setItems(allResult);
    }
  }, [allResult, newArrayCont]);

  useEffect(() => {
    const getListOfHotels = async () => {
      setShowSkeleton(true);
      if (storedCount !== null) {
        setPageNumber(storedCount);
      }
      if (allResult.length <= 0) {
        setShowSkeleton(false);
      }
      // console.log(value, pageNumber)
      if (propTypeclick === false) {
        const newHotels = await axios.get(
          `/registrations/hotels?genFilter=${value}&sourceType=TiptopgoWeb&pageSize=12&page=${
            pageNumber || 1
          }`
        );
        // console.log("value", value);
        // console.log("pageNumber", pageNumber);
        // console.log("newHotels checking", newHotels)
        if (newHotels.data[0]?.hotelHasFlatRate === true) {
          const newArrayCont = newHotels.data.map((obj) => {
            if (obj?.hotelHasFlatRate) {
              const newKey = obj.hotelMinimumPrice - obj.commissionBonus;

              return {
                ...obj,
                newKey,
              };
            }
          });
          setItems(newArrayCont);
          setIsStriked(true);
        } else {
          setItems(newHotels.data);
          setIsStriked(false);
        }
        let paginationSpread =
          allResult?.hotelHasFlatRate === true
            ? newArrayCont[0]?.hotelCount || 1
            : allResult[0]?.hotelCount || 1;
        setPageCount(Math.ceil(paginationSpread / 12));
        checkLoggin();
        setShowSkeleton(false);
      } else {
        setShowSkeleton(true);
        if (allResult && allResult[0]?.hotelHasFlatRate === true) {
          const newArrayCont = allResult.map((obj) => {
            if (obj?.hotelHasFlatRate) {
              const newKey = obj?.hotelMinimumPrice - obj.commissionBonus;

              return {
                ...obj,
                newKey,
              };
            }
          });
          setItems(newArrayCont);
          setIsStriked(true);
        } else {
          setItems(allResult);
          setIsStriked(false);
        }
        let paginationSpread =
          allResult?.hotelHasFlatRate === true
            ? newArrayCont[0]?.hotelCount || 1
            : allResult[0]?.hotelCount || 1;
        setPageCount(Math.ceil(paginationSpread / 12));
        checkLoggin();
        setShowSkeleton(false);
      }
    };
    getListOfHotels();
  }, [pageNumber, allResult]);

  const checkFav = async () => {
    let tipTopGoGuestId = userData?.tipTopGoGuestId;

    let favList = await axios.get(
      `/registrations/getGuestFavoriteHotels/${tipTopGoGuestId}`
    );

    let favList2 = favList.data;

    let allResultId = [];

    newArrayCont.forEach((a, i) => {
      let allResultId2 = a.hotelId;
      allResultId.push(allResultId2);
    });

    favList2.forEach((e, i) => {
      let favHotelId2 = e.hotelId;
      favHotelId.push(favHotelId2);
    });

    const intersection = allResultId.filter((element) =>
      favHotelId.includes(element)
    );

    setIntersection(intersection);
  };

  useEffect(() => {
    checkFav();
  }, [userData]);

  const addFavHotel = async (indivHotel) => {
    setLoader(true);
    await axios.post("registrations/addFavoriteHotel", {
      hotelId: indivHotel.hotelId,
      guestId: userData.guestId,
      tipTopGoGuestId: userData.tipTopGoGuestId,
      type: "unlike",
      sourceType: "TiptopgoWeb",
    });
    checkFav();
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  const removeFavHotel = async (indivHotel) => {
    setLoader(true);
    await axios.post("registrations/addFavoriteHotel", {
      hotelId: indivHotel.hotelId,
      guestId: userData.guestId,
      tipTopGoGuestId: userData.tipTopGoGuestId,
      type: "like",
      sourceType: "TiptopgoWeb",
    });
    checkFav();
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  const handlePageClick = async (event) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    localStorage.removeItem("pageNo");
    setPageLoader(true);
    let pageNo = event.selected + 1;
    setPageNumber(pageNo);
    localStorage.setItem("pageNo", pageNo.toString());
    const newHotels = await axios.get(
      `/registrations/hotels?genFilter=${value}&sourceType=TiptopgoWeb&pageSize=12&page=${pageNo}`
    );
    // console.log('hotel', newHotels)

    // const newArrayCont = newHotels.data.map(obj => {
    //   if(obj.hotelHasFlatRate){
    //     const newKey = obj.hotelMinimumPrice - obj.hotelFlatAmount;

    //     return {
    //       ...obj,
    //       newKey
    //     };
    //   }
    // });
    // setItems(newArrayCont);
    // setCurrentPage(storedCount);
    //   setPageLoader(false)

    if (newHotels.data[0]?.hotelHasFlatRate === true) {
      const newArrayCont = newHotels.data.map((obj) => {
        if (obj.hotelHasFlatRate) {
          const newKey = obj.hotelMinimumPrice - obj.commissionBonus;

          return {
            ...obj,
            newKey,
          };
        }
      });
      setItems(newArrayCont);
      setIsStriked(true);
    } else {
      setItems(newHotels.data);
      setIsStriked(false);
    }
  };

  const generateRandomCode = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const passDetails = (indivHotel) => {
    const replaceSpacesWithDashes = (str) => {
      return str.replace(/\s+/g, "-");
    };
    const randomCode = generateRandomCode(20);
    const hotelId = indivHotel.hotelId;
    const hotelName = indivHotel.hotelName;
    const replacedString = replaceSpacesWithDashes(hotelName);

    navigation(
      `/hotel_info/${hotelId}/${randomCode}/${replacedString}/${hotelName}`,
      {
        state: {
          indivHotel,
          allResult2,
        },
      }
    );
  };

  const changeDivClass = () => {
    setChangeClass(true);
  };

  let stars = [];
  items &&
    items.map((rating, index) => {
      for (let i = 1; i <= 5; i++) {
        const checkStar = i <= rating.hotelStar;
        const starStyles = { color: checkStar ? "green" : "gray" };
        stars[i] = <FaStar key={i} className='star' style={starStyles} />;
      }
    });

  const amenityIconMap = {
    Table: <FaTable />,
    Gym: <FaDumbbell />,
    TV: <FaTv />,
    "Swimming pool": <FaSwimmer />,
    Wifi: <FiWifi />,
    Refrigerator: <CgSmartHomeRefrigerator />,
    Gotv: <MdOutlineCable />,
    mat: <FaMattressPillow />,
    Fan: <LiaFanSolid />,
    Restaurant: <MdOutlineRestaurant />,
    "A.C": <TbAirConditioning />,
    AC: <TbAirConditioning />,
    "Air condition": <TbAirConditioning />,
    // Add more mappings as needed
  };

  const getSelectedCurrency = GetCurrencyData();
  const selectedCurrencyObject = getSelectedCurrency.selectedCurrency;
  const { currencySymbol, currencyRate, currencyCode } = selectedCurrencyObject;

  // const convertAmount = (amount, rate, currencyCode) => {
  //   if (currencyCode === "NGN") {
  //     return amount * rate;
  //   } else {
  //     return amount / rate;
  //   }
  // };

  // console.log("hotelHasFlatRate", items);

  return (
    <div>
      {/* <div className="parent_individual_hotel"> */}

      <div className='individual_main_div'>
        {showSkeleton ? (
          <LoadingSkeleton />
        ) : (
          <>
            {/* {
            pageLoader && <HotelsLoader/>
             } */}
            {items &&
              items.map((indivHotel, i) => (
                <div className='indiv_hotel2' key={indivHotel.hotelId}>
                  <div
                    className='indiv_hotel_img'
                    onClick={() => passDetails(indivHotel)}
                  >
                    <img src={indivHotel.picture} alt='' />
                  </div>
                  <div className='indiv_hotel_text'>
                    <div className='hotel_name'>
                      <span className='starDivName'>
                        <div className='nameDiv'>{indivHotel.hotelName}</div>
                        <div className='star-rating'>{stars}</div>
                        <div className='proptypeindication'>
                          <MdApartment /> {indivHotel.propertyType}
                        </div>
                        <div className='guestReview'>
                          {" "}
                          <GrLike />
                        </div>
                      </span>

                      <span className='hotel_amout'>
                        <div
                          className='priceDiv'
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              textDecoration: "none",
                              fontSize: "0.95em",
                              fontStyle: "normal",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              className='tagPrice2'
                              style={{ fontSize: "0.63em" }}
                            >
                              pay at hotel
                            </span>
                            {currencySymbol || "₦"}

                            {convertAmount(
                              indivHotel?.hotelMinimumPrice,
                              currencyRate,
                              currencyCode
                            ).toLocaleString() ||
                              (indivHotel?.hotelMinimumPrice).toLocaleString()}
                          </div>
                          {isStriked ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                className='tagPrice'
                                style={{ fontSize: "0.6em", color: "red" }}
                              >
                                pay online
                              </span>
                              {currencySymbol || "₦"}
                              {convertAmount(
                                indivHotel?.newKey,
                                currencyRate,
                                currencyCode
                              ).toLocaleString() ||
                                (indivHotel?.newKey).toLocaleString()}
                            </div>
                          ) : null}
                        </div>
                        <div className='favAvg'>
                          <span className='avv-nig'>avg/night</span>
                          {isLogin === "true" ? (
                            <span className='avv-nig2'>
                              {loader ? (
                                <Hearts
                                  height='50'
                                  width='50'
                                  color='#2E2D4D'
                                  ariaLabel='hearts-loading'
                                  wrapperStyle={{}}
                                  wrapperClass=''
                                  visible={true}
                                />
                              ) : intersection.includes(indivHotel.hotelId) ? (
                                <MdFavorite
                                  className='fav'
                                  onClick={() => addFavHotel(indivHotel)}
                                />
                              ) : (
                                <MdOutlineFavoriteBorder
                                  className='fav'
                                  onClick={() => removeFavHotel(indivHotel)}
                                />
                              )}
                            </span>
                          ) : null}
                        </div>
                      </span>
                    </div>
                    <div className='hotel_address'>
                      <span className='locationDiv__'>
                        {" "}
                        {indivHotel.location}{" "}
                      </span>{" "}
                      <span className='addressDiv__'>
                        <div className='iconDivHolder'>
                          <FaLocationArrow className='locationIcon' />{" "}
                        </div>
                        <div className='locationDivaddress'>
                          {indivHotel.address}
                        </div>
                      </span>
                    </div>
                    <div className='review'>
                      <i>{indivHotel.shortHotelDescription}</i>{" "}
                      {/* <span>
                      <div>7.2 Very Good</div> <p>from 16 reviews</p>
                    </span> */}
                    </div>
                    <ul>
                      {indivHotel.amenities
                        .map((amenity, index) => (
                          <div key={index} className='Hotel_amenityicon'>
                            <IconContext.Provider
                              value={{ className: "amenity-icon" }}
                            >
                              {amenityIconMap[amenity.amenity] ? (
                                amenityIconMap[amenity.amenity]
                              ) : (
                                <GiConcentrationOrb className='genIcon' />
                              )}
                            </IconContext.Provider>
                            <span className='amenity'>{amenity.amenity}</span>
                          </div>
                        ))
                        .slice(0, 5)}
                    </ul>

                    <div className='breakfastBookdiv'>
                      {/* <div className='breakfastLab'>
                        Breakfast{" "}
                        {indivHotel.hotelHasBreakfast === true
                          ? "available"
                          : "not available"}{" "}
                      </div> */}
                      <div className='book'>
                        <button onClick={() => passDetails(indivHotel)}>
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>

      {/* </div> */}
      {showPaginate === false ? null : (
        <div className='reactpaginate'>
          <ReactPaginate
            breakLabel={"..."}
            nextLabel='>>'
            onPageChange={handlePageClick}
            activePage={pageNumber}
            // forcePage={pageNumber}
            pageRangeDisplayed={3}
            marginPagesDisplayed={4}
            pageCount={pageCount}
            previousLabel='<<'
            renderOnZeroPageCount={null}
            containerClassName='pagination'
            pageLinkClassName='page-num'
            previousLinkClassName='page-num'
            nextLinkClassName='page-num'
            previousClassName={""}
            activeClassName={"active2"}
            activeLinkClassName={"page-num2"}
            breakClassName={"break-lable"}
            breakLinkClassName='breakLink'
          />
        </div>
      )}
    </div>
  );
};

export default HotelsComponents;
