import React from "react";


export const CalculateTax = (book, days) => {
  let totalTaxRoomCat = 0;
  let totalTaxRoomCatNoDiscount = 0;
  const taxDetails = [];
  const taxDetailsNoDiscount = []; // New array to store tax details without discount

  const taxableAmount =
    (book.newKey + book.actualBreakfastCost) * days * book.qty;

  const taxableAmtNoDiscount =
    (book.roomCost + book.actualBreakfastCost) * days * book.qty;

  for (const taxLevy of book.taxLevies) {
    if (
      taxLevy.taxApplicable === "fd" ||
      (taxLevy.taxApplicable === "fb" && book.breakfastType === "notFree")
    ) {
      if (taxLevy.taxType === "flatAmount") {
        const flatAmountTax = taxLevy.flatAmount * book.qty * days;
        totalTaxRoomCat += flatAmountTax;
        totalTaxRoomCatNoDiscount += flatAmountTax;
        taxDetails.push({
          taxLabel: taxLevy.taxLabel,
          taxAmount: flatAmountTax,
          levyId: taxLevy.levyId,
          hotelId: taxLevy.hotelId,
          taxCategory: taxLevy.taxCategory,
          flatAmount: taxLevy.flatAmount,
          taxPercentage: taxLevy.taxPercentage,
        });
        taxDetailsNoDiscount.push({
          // Add tax details without discount to the new array
          taxLabel: taxLevy.taxLabel,
          taxAmount: flatAmountTax,
          levyId: taxLevy.levyId,
          hotelId: taxLevy.hotelId,
          taxCategory: taxLevy.taxCategory,
          flatAmount: taxLevy.flatAmount,
          taxPercentage: taxLevy.taxPercentage,
        });
      } else if (taxLevy.taxType === "percentage") {
        const percentageTax = (taxLevy.taxPercentage / 100) * taxableAmount;
        totalTaxRoomCat += percentageTax;

        const percentageTaxNoDiscount =
          (taxLevy.taxPercentage / 100) * taxableAmtNoDiscount;

        totalTaxRoomCatNoDiscount += percentageTaxNoDiscount;

        taxDetails.push({
          taxLabel: taxLevy.taxLabel,
          taxAmount: percentageTax,
          levyId: taxLevy.levyId,
          hotelId: taxLevy.hotelId,
          taxCategory: taxLevy.taxCategory,
          flatAmount: taxLevy.flatAmount,
          taxPercentage: taxLevy.taxPercentage,
        });
        taxDetailsNoDiscount.push({
          // Add tax details without discount to the new array
          taxLabel: taxLevy.taxLabel,
          taxAmount: percentageTaxNoDiscount,
          levyId: taxLevy.levyId,
          hotelId: taxLevy.hotelId,
          taxCategory: taxLevy.taxCategory,
          flatAmount: taxLevy.flatAmount,
          taxPercentage: taxLevy.taxPercentage,
        });
      }
    }
  }

  return {
    totalTaxRoomCat,
    taxDetails,
    totalTaxRoomCatNoDiscount,
    taxDetailsNoDiscount,
  };
};


export const convertAmount = (amount, rate, currencyCode) => {
  if (currencyCode === "NGN") {
    return amount * rate;
  } else {
    return amount / rate;
  }
};
