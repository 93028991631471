import React,{useEffect} from 'react'
import Footer from '../Footer/Footer'
import NavBar from '../NavBar/NavBar'
import Subscribe from '../Subscribe/Subscribe'
import './ContactUs.css'
import {BsFillTelephoneForwardFill} from 'react-icons/bs'
import {MdAttachEmail} from "react-icons/md"
import axios from "../../utilities/axios"
import * as Yup from "yup"
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import {
  handleAddToCart,
  handleAdjustQty,
  handleClearStore,
  GetCartData
} from '../../Redux/Actions/room_actions';
import { connect } from 'react-redux';


const ContactUs = ({handleClearStore}) => {

  useEffect(()=>{
    handleClearStore()
   },[])
    
const navigation = useNavigate();     
    const notifySuccess = (res, values) => {
 toast.success(res.data.message,{
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,    
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",

})

  setTimeout(()=>{
    navigation('/', {state: {
        values
     }})
  },3000)
    }
    const formik = useFormik({
        initialValues: {
          firstName: "",
        //   lastName: "",
          emailAddress: "",
          message: "",
          subject: "",
           sourceType: "TiptopgoWeb",
          
        },
        validationSchema: Yup.object({
          firstName: Yup.string().min(3, "first name must be more").required("first name is Required"),
        //   lastName: Yup.string().min(3, "last name is require").required("last name is Required"),
      
        emailAddress: Yup.string().email("invalid email address").required("Email is Required"),
          message: Yup.string().min(6,"Must not be less than 6 characters").required("Message can't be empty"),
      
          subject: Yup.string().max(20,"subject must be 20 characters").required("subject is Required"),
         
        }),
      
        onSubmit: async (values) => {
          // console.log(values)
         // notify()
          await axios.post('/registrations/create-feedBack', values)
          .then(res =>  {
            if(res){     
              notifySuccess(res, values)
            // console.log("res", res)
            }else{
            //   notify(res)
            console.log("error");
            }
           })
          .catch(err => console.log("no connection", err))
        
         }
      
    
       
      })
         
  return (
    <div className='parent_contact'>
      <NavBar />
      <div className='contact_form_container'>
        <div className='contact_tex'>
          {/* <div className='contact__us_head'>
                <h2>Contact</h2>
                <div className='hr_div'><hr/> <span>Us</span></div>
                </div> */}
          <div className='contact_text'>
            {/* <p>It is very important for us to keep in touch with you,
                    so we are always ready to<span className='ansQst'> answer any question</span> that 
                    interest you. <span className='ansQst'>Short!!!!!</span> 
                </p> */}
            {/* <div className='fone_Number'>
                        <div className='parent_fone_contact'>
                        <div className='fone_Number2'>
                        <BsFillTelephoneForwardFill/> 
                         </div>
                        <div> 
                             <span className='contact_fone'>Phone Number</span><br/>
                             <span className='ansQst ansQst2'>08139734085</span>
                        </div>
                        </div>
                     <div className='parent_email_contact'>
                        <div className=' fone_con'>
                      <MdAttachEmail/>
                      </div>
                      <div className='email_text'> 
                            <span className='contact_fone'>Email Address</span><br/>
                            <span className='ansQst ansQst2'>anigbo.joel@gmail.com</span>
                        </div>
                        </div>
               
                    </div> */}

            <div className='fone_Number'>
              <div className='parent_fone_contact'>
                <div className='fone_Number2'>
                  <BsFillTelephoneForwardFill />
                </div>
                <div>
                  <span className='contact_fone'>Phone Number</span>
                  <br />
                  <span className='ansQst ansQst2'>+234 08135936594</span>
                </div>
              </div>
              <div className='parent_fone_contact'>
                <div className='fone_Number2'>
                  <BsFillTelephoneForwardFill />
                </div>
                <div>
                  <span className='contact_fone'>Phone Number</span>
                  <br />
                  <span className='ansQst ansQst2'>+234 02094601555</span>
                </div>
              </div>
              <div className='parent_email_contact'>
                <div className=' fone_con'>
                  <MdAttachEmail />
                </div>
                <div className='email_text'>
                  <span className='contact_fone'>Email Address</span>
                  
                  <span className='ansQst ansQst2'>info@tiptopgo.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className='contact_form' onSubmit={formik.handleSubmit}>
          <h3>Contact us At TipTopGo</h3>
          <p>Typically responds within 24 hours</p>

          <input
            type='text'
            placeholder='FullName'
            name='firstName'
            id='firstName'
            value={formik.values.firstName.trimStart()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <p className='error'>{formik.errors.firstName}</p>
          ) : null}
          <input
            type='email'
            placeholder='Email Address'
            id='emailAddress'
            name='emailAddress'
            value={formik.values.emailAddress.trimStart()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.emailAddress && formik.errors.emailAddress ? (
            <p className='error'>{formik.errors.emailAddress}</p>
          ) : null}
          <input
            type='text'
            placeholder='Subject'
            name='subject'
            id='subject'
            value={formik.values.subject.trimStart()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.subject && formik.errors.subject ? (
            <p className='error'>{formik.errors.subject}</p>
          ) : null}
          <textarea
            id='message'
            name='message'
            rows='10'
            cols='50'
            placeholder='message'
            value={formik.values.message.trimStart()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.message && formik.errors.message ? (
            <p className='error'>{formik.errors.message}</p>
          ) : null}
          <button type='submit'>Send Message</button>
        </form>
      </div>
      <ToastContainer />
      <Subscribe />
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  const cart = state.cart;
  return {
    cart
  };
};


export default connect(mapStateToProps, {handleClearStore})(
  ContactUs
);