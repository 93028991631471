import React, { useState } from "react";
import "./LoginDashBoard.css";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaThList,
} from "react-icons/fa";
import { MdFreeCancellation } from "react-icons/md";
import { NavLink, Outlet } from "react-router-dom";
import NavBar from "../../../HomePage/NavBar/NavBar";
import Footer from "../../../HomePage/Footer/Footer";
import Subscribe from "../../../HomePage/Subscribe/Subscribe";
import GetApp from "../../../HomePage/Get App/GetApp";
import { FaBlog } from "react-icons/fa6";

const LoginDashBoard = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/LoginDash/main-dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/LoginDash/userprofile",
      name: "Profile",
      icon: <FaUserAlt />,
    },
    {
      path: "/LoginDash/favourite-hotel",
      name: "Favourite",
      icon: <FaRegChartBar />,
    },
    {
      path: "/LoginDash/history",
      name: "Reservation",
      icon: <FaThList />,
    },
    {
      path: "/LoginDash/cancel-reservation",
      name: "Cancel",
      icon: <MdFreeCancellation />,
    },
    {
      path: "/LoginDash/create-blog-post",
      name: "Blog center",
      icon: <FaBlog />,
    }
  ];

  const blogMenuItem = {
    path: "/LoginDash/create-blog-post",
    name: "Blog center",
    icon: <FaBlog />,
  };

  const getMenuItems = () => {
    const blogUserData = localStorage.getItem("blogUserData");
    const userData = localStorage.getItem("userData");

    if (blogUserData) {
      return [blogMenuItem];
    } else if (userData) {
      return menuItem;
    } else {
      return [];
    }
  };

  const filteredMenuItems = getMenuItems();

  return (
    <>
      <NavBar />
      <div className='containerDashBoard'>
        <div style={{ width: isOpen ? "200px" : "50px" }} className='sidebar'>
          <div className='top_section'>
            <h1 style={{ display: isOpen ? "block" : "none" }} className='logo'>
              Menu
            </h1>
            <div
              style={{ marginLeft: isOpen ? "10%" : "0px" }}
              className='bars'
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {filteredMenuItems.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className='link'
              activeclassName='active'
            >
              <div style={{ display: isOpen ? "" : "block" }} className='icon'>
                {item.icon}
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className='link_text'
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        {/* <main>{children}</main> */}
        <Outlet />
      </div>
      <Subscribe />
      <GetApp />
      <Footer />
    </>
  );
};

export default LoginDashBoard;
