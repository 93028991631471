import React, { useState, useEffect } from "react";
import "./Book.css";
import { connect } from "react-redux";
import {
  handleAddToCart,
  handleAdjustQty,
  handleRemoveFromCart,
  handleBreakFastQty,
} from "../Redux/Actions/room_actions";
import { GetCurrencyData } from "../Redux/Actions/currency_actions";
import { AiTwotoneDelete } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const BookingDetails = ({
  bookingDetails2,
  useDiscount,
  historyData,
  payAtHotelWarning,
  days,
  handleAdjustQty,
  cart,
  handleRemoveFromCart,
  totalBills,
}) => {
  const [cartData, setCartData] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [hasError, setHasError] = useState(false);
  const [showError, setShowError] = useState(false); 
  const dateFormat = "YYYY-MM-DD";
 const storedDates = JSON.parse(localStorage.getItem("dateRange"));

  const { selectedCurrency } = GetCurrencyData(); // Use this inside the component
  const {
    currencySymbol = "₦",
    currencyRate = 1,
    currencyCode = "NGN",
  } = selectedCurrency;

  // Fetch cart data correctly inside useEffect
  useEffect(() => {
    setCartData(cart);
  }, [cart]);

  const convertAmount = (amount, rate, code) =>
    code === "NGN" ? amount * rate : amount / rate;

const onChangeHandler = (event, detail) => {
  const newQuantity = parseInt(event.target.value, 10) || 0; // Handle NaN case
  const { roomTypeId, availableRooms } = detail;

  if (newQuantity > availableRooms) {
    setHasError(true);
    setShowError(true); 
    setQuantities((prev) => ({ ...prev, [roomTypeId]: availableRooms })); // Set to availableRooms
    handleAdjustQty(roomTypeId, availableRooms); // Adjust with available rooms
  } else {
    setHasError(false);
    setShowError(false); // Set showError to false
    setQuantities((prev) => ({ ...prev, [roomTypeId]: newQuantity })); // Set new quantity
    handleAdjustQty(roomTypeId, newQuantity); // Adjust with new quantity
  }
};

  const removeRoomNotify = (roomTypeName) => {
    toast.warn(`${roomTypeName} Room has been removed`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <div>
      <div className='checkincheckoutDate'>
        <span className='checkinspan'>
          <label>Check in date</label>
          {`${moment(storedDates[0]).format(dateFormat)} : 1:00 PM`}
        </span>
        <span className='ckeckoutspan'>
          {" "}
          <label>Check out date</label>
          {`${moment(storedDates[1]).format(dateFormat)} : 1:00 PM`}
        </span>
      </div>
      {cartData.map((detail) => (
        <div className='book_hotel__details2' key={detail.roomTypeId}>
          <div className='book__hotel_info'>
            <h4>
              <a>{detail.roomTypeName}</a>
              <p>
                {currencySymbol}{" "}
                {payAtHotelWarning <= 0
                  ? convertAmount(
                      detail.totalCost,
                      currencyRate,
                      currencyCode
                    ).toLocaleString()
                  : convertAmount(
                      detail.roomCost,
                      currencyRate,
                      currencyCode
                    ).toLocaleString()}
              </p>
            </h4>

            <h4 className='qty'>
              Quantity <i>{detail.qty}</i>
              <div className='inputField_div'>
                <input
                  type='number'
                  min='1'
                  max='100'
                  value={quantities[detail.roomTypeId] || detail.qty || 1}
                  onChange={(event) => onChangeHandler(event, detail)}
                  className='inputField'
                />
              </div>
              <button
                style={{ color: "red", background: "aliceblue" }}
                className='removebtn'
                onClick={() =>
                  handleRemoveFromCart(
                    detail.roomTypeId,
                    removeRoomNotify(detail.roomTypeName)
                  )
                }
              >
                <AiTwotoneDelete
                  className='delbtn'
                  style={{ color: "red", background: "aliceblue" }}
                />
              </button>
            </h4>
            {hasError && (
              <div className='error-message'>
                Quantity exceeds available rooms. Please adjust.
              </div>
            )}
            <ToastContainer />
            <h4 className='days'>
              Days <i>{historyData?.days || 1}</i>
            </h4>

            {detail.numOfBreakfast > 0 && (
              <h4 className='days'>
                Breakfast Cost{" "}
                <i>
                  {currencySymbol}{" "}
                  {convertAmount(
                    detail.breakfastFlatCost,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                </i>
              </h4>
            )}

            <h4 className='days'>
              Breakfast Qty <i>{detail.numOfBreakfast}</i>
            </h4>

            {useDiscount && (
              <h4 className='days' style={{ color: "red", fontSize: "0.6em" }}>
                Discount Amount{" "}
                <i>
                  {convertAmount(
                    detail.discountPrice,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                </i>
              </h4>
            )}
          </div>
        </div>
      ))}

      {/* <div className="total-bills">
        <h4>Total Bills: {currencySymbol} {totalBills.toLocaleString()}</h4>
      </div> */}
    </div>
  );
};

// Calculate total price
const calcQuickTotal = (items) =>
  items.reduce((total, item) => total + item.qty * item.price, 0);

const mapStateToProps = (state) => ({
  cart: state.cart,
  totalBills: calcQuickTotal(state.cart),
});

export default connect(mapStateToProps, {
  handleAddToCart,
  handleBreakFastQty,
  handleAdjustQty,
  handleRemoveFromCart,
})(BookingDetails);
