import React from 'react'
import {Circles} from 'react-loader-spinner'
import './Spinner.css'

const Spinner = () => {
  return (
    <div >
         <div className='spinner' style={{background:"#E7ECF3", position:"fixed",opacity:"0.4", width:"100vw", zIndex:"99", height:"100vh", display:"flex",alignItems:"center", justifyContent:"center", top:"0"}}>
      <Circles
        height="80"
        width="80"
        color="#2E2D4D"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      </div>
    </div>
  );
}

export default Spinner