import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'
import './Newsroom.css'

const Newsroom = () => {
  return (
    <div>
    <NavBar />
    <div className='newsRoom'>
    <div className='check_parent  newsRoomMainDiv'>
        <h1>Newsroom</h1>
        <p className='newsRoomText'>Welcome to the Tiptopgo Newsroom, your go-to destination for the most recent updates, press releases, and media coverage. Stay abreast of our platform's latest achievements, partnerships, and valuable insights into the travel and hospitality industry.</p>  
     </div>
       
    </div>  
<Subscribe/>
 <Footer/>
</div>
  )
}

export default Newsroom