import React from 'react'
import './Modal.css'

export const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    
     const handleClickInsideModal = (e) => {
    // Prevent clicks inside the modal from propagating to the overlay and closing it
    e.stopPropagation();
  };

  return (
    <div className="modalShow" onClick={onClose}>
      <div className="modal-content" onClick={handleClickInsideModal}>
        <span className="modalClose" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  )
}


export const filterModal = ({ isOpen, onClose, children }) => {
  if (isOpen) return null;

  
   const handleClickInsideModal = (e) => {
  // Prevent clicks inside the modal from propagating to the overlay and closing it
  e.stopPropagation();
};

return (
  <div className="modalShow" onClick={onClose}>
    <div className="modal-content" onClick={handleClickInsideModal}>
      <span className="modalClose" onClick={onClose}>&times;</span>
      {children}
    </div>
  </div>
)
}