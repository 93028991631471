import React,{useState, useEffect} from 'react'
// import { useLocation } from 'react-router-dom'
// import NavBar from '../../../HomePage/NavBar/NavBar'
import moment from 'moment'
import './CancelReservation.css'
// import Subscribe from '../../../HomePage/Subscribe/Subscribe'
// import GetApp from '../../../HomePage/Get App/GetApp'
// import Footer from '../../../HomePage/Footer/Footer'
// import SideBar from '../SideBar/SideBar'
import {Loader} from '../../../utilities/Loader'  
import axios from '../../../utilities/axios';
import ShowModal from './ShowModal';


const CancelReservation = () => {
    const [load, setLoad] = useState(false)
    const [reservationDetails, setReservationDetails] = useState([])
    const [showHistory, setShowHistory] = useState(true)
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showMessage, setShowMessage] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [cancelationData, setCancelationData] = useState({});
    const [reloadPage, setReloadPage] = useState(false)
  
const userData = JSON.parse(localStorage.getItem('userData'));

    const guestId = userData.tipTopGoGuestId

useEffect(() => {
  const getReservation = async () => {
    setLoad(true);
    if (isOnline || reloadPage) {
      const reservation = await axios.get(
        `/registrations/getAppReservationRecords?filterType=bookHistory&tipTopGoGuestId=${guestId}`
      );
      // console.log("reservation", reservation)
      setReservationDetails(reservation.data);
      setLoad(false);

      if (reservation.data.length < 1) {
        setShowHistory(false);
      }
      setReloadPage(false);
    }
  };
  getReservation();
}, [isOnline, guestId, reloadPage]);

reservationDetails.forEach((e, i) => {
  e.totalPay = e.bookings.reduce((a, b) => a + b.amountPaid, 0);
});

const updatedReservations = reservationDetails.map((reservation) => {
  const discountPerDay = reservation.discountAmount * reservation.numOfDays;
  const amountPaid = reservation.totalPay - discountPerDay;
  return {
    ...reservation,
    discountPerDay,
    amountPaid,
  };
});

useEffect(() => {
  const handleOnline = () => {
    setIsOnline(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const handleOffline = () => {
    setIsOnline(false);
    setShowMessage(true);
  };
  window.addEventListener("online", handleOnline);
  window.addEventListener("offline", handleOffline);

  return () => {
    window.removeEventListener("online", handleOnline);
    window.removeEventListener("offline", handleOffline);
  };
}, [setShowMessage]);

const showCancelForm = (booking) => {
  setShowModal(true);
  setCancelationData(booking);
  // console.log("booking", booking)
};

const hideCancelForm = () => {
  setShowModal(false);
  setReloadPage(true);
};

const isCheckinDateLessThan24Hours = (checkinDate) => {
  const now = new Date();
  const checkinDateInMilliseconds = new Date(checkinDate).getTime();
  const differenceInMilliseconds = checkinDateInMilliseconds - now.getTime();
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

  return differenceInHours < 24;
};

const newArray =
  updatedReservations &&
  updatedReservations.map((item) => ({
    ...item,
    bookings: item.bookings.map((booking) => ({
      ...booking,
      hotelName: item.hotelName,
      hotelId: item.hotelId,
      checkinDate: item.reservationStartDateTime,
      checkoutDate: item.reservationEndDateTime,
      bookingCode: item.bookingCode,
      bookedDate: item.createdDateTime,
      reservationId: item.reservationId,
      webReferenceNo: item.webReferenceNo,
      payingGuestId: item.payingGuestId,
      numOfRooms: item.numOfRooms,
      roomTypeId: item.roomTypeId,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      location: item.location,
      reservationType: item.reservationType,
      sourceType: item.sourceType,
    })),
  }));
    
    
  return (
    <div className='history__parent'>
    <div className='history__p'>
      <div className='sideBar__div'>
      </div>
      {
        showModal ? <ShowModal cancelationData={cancelationData} hideCancelForm={hideCancelForm}/> : null
      }
        <div className='history__parent2'>   
        <p className='history__header'>cancel reservation</p>
        {isOnline ?  null : <p className='offLine'>Network Error, please check your internet connection</p>}

      {load ?   <div className='__header2'><Loader/></div>:<>{
          showHistory ? <>
           {
           newArray && newArray.map((detail) => (
          detail.bookings.map((booking, index) => (
        <div className='tableContainer' key={index}>
          <span className='createdDate'>
            {moment(booking.bookedDate).format("YYYY-MMMM-DD : h:mm:ss")}
          </span>
          <table>
            <tbody style={{width:'100%'}}>
              <tr className='tableHeader'>
                <th className='hotelNameDivLoc'>
                  {booking.hotelName} <br /><span>{booking.location}</span>
                </th>
                <td>
                  <button className='cancelReserve' 
                  disabled={booking.statuss === 'cancelled' || booking.statuss === 'checkedIn' || booking.cancellationRequest || isCheckinDateLessThan24Hours(booking.checkinDate)}
                  style={booking.statuss === 'cancelled' || booking.statuss === 'checkedIn' || booking.cancellationRequest || isCheckinDateLessThan24Hours(booking.checkinDate) ? 
                  {backgroundColor: 'grey', cursor:'not-allowed', border: '1px solid grey'}: {backgroundColor:'red'}}
                  onClick={() => showCancelForm(booking)}>
                    Cancel
                  </button>
                </td>
              </tr>
              <tr className='tableData'>
                <td>Booking Code</td>
                <td>{booking.bookingCode}</td>
              </tr>
              <tr className='tableData'>
                <td>Reference Number</td>
                <td>{booking.webReferenceNo}</td>
              </tr>
              <tr className='tableData'>
                <td>Guest name</td>
                <td>{booking.firstName} {booking.lastName}</td>
              </tr>
              <tr className='tableData'>
                <td>Check-in Date</td>
                <td>
                  {moment(booking.checkinDate).format("YYYY-MMM-DD : h:mm:ss")}
                </td>
              </tr>
              <tr className='tableData'>
                <td>Check-out Date</td>
                <td>
                  {moment(booking.checkoutDate).format("YYYY-MMM-DD : h:mm:ss")}
                </td>
              </tr>
              <tr className='tableData'>
                <td>Room type</td>
                <td>
                  {booking.roomTypeName}
                </td>
              </tr>
              <tr className='tableData'>
                <td>Room Number</td>
                <td>
                  {booking.roomNumber}
                </td>
              </tr>
              {
                booking.webReferenceNo === null ? 
                <tr className='tableData'>
                <td>payment type</td>
                <td>
                  pay at hotel
                </td>
              </tr> : null
              }
              <tr className='tableData'>
                <td>Status</td>
                <td style={booking.statuss === 'cancelled'|| booking.statuss === 'checkedIn' || booking.cancellationRequest ? {color: 'red'}: {color:'green'}}>
                  {booking.cancellationRequest ? "processing cancellation" : booking.hasCheckedOut ? "checked Out" : booking.statuss}
                </td>
              </tr>
              {/* Additional details from the booking can be added here */}
            </tbody>
          </table>
        </div>
      )))
    )}
          </> : <h1 className=' __header2'>OOps!!! it seems you dont <br/> have any reservation yet</h1>
        } 
       </>}
        
       
        
        
       </div>
    </div>
    {/* <Subscribe  myStyle={bookStyle.backgroundColor}/>
    <GetApp/>
    <Footer/> */}
    </div>
  )
}

export default CancelReservation


