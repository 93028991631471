import React from 'react'
import Footer from '../../HomePage/Footer/Footer'
import NavBar from '../../HomePage/NavBar/NavBar'
import {GrLinkNext} from 'react-icons/gr'
import './Register.css'
import axios from "../../utilities/axios"
import { useNavigate } from 'react-router-dom'
import * as Yup from "yup"
import { useFormik, Formik, Field, Form  } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
import {BiArrowBack} from 'react-icons/bi'
import {BsFillEyeSlashFill} from 'react-icons/bs'
import {AiFillEye} from 'react-icons/ai'
import logo from '../../assets/TiptopGoLogo.png'
import GetApp from '../../HomePage/Get App/GetApp'
import reg from '../../assets/login.png'
import { InfinitySpin } from  'react-loader-spinner'


const Register = () => {
const [loader, setLoader] = useState(false)


const [showForm, setShowForm] = useState(false)

const [showPassword, setShowPassword] = useState(false)


const navigation = useNavigate();

const setShowPass = () => {
  setShowPassword(!showPassword)
}

const handleShowForm = (e) => {
  e.preventDefault()
  setShowForm(!showForm)
}

const notify = (res) => {
  toast.success(res.data.message,{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
      
  })
}

const notifySuccess = (res, values) => {
 toast.success(res.data.message,{
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",

})

  setTimeout(()=>{
   // console.log("navigation values", values);
    navigation('/Login', {state: {
      
      values
    
   }})
  },5000)
}

const formik = useFormik({
  initialValues: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    country: "",
    gender: "",
    guestTitle: "",
     sourceType: "TiptopgoWeb",
     address:"",
  },
  validationSchema: Yup.object({
    firstName: Yup.string().min(3, "first name must be more").required("first name is Required"),
    lastName: Yup.string().min(3, "last name is require").required("last name is Required"),

    emailAddress: Yup.string().matches(/^[^\s]+$/, 'Email must not contain spaces').email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string().matches(/^[0-9]+$/,'Phone number must be only digits').required('Phonenumber is required'),

     password: Yup.string().min(6, "password must be 6 characters").required("password is Required"),
     country: Yup.string().max(100, "please enter your country").required("country is Required"),

    // gender: Yup.string().max(6, "please enter your gender").required("gender is Required"),
    address: Yup.string().max(100, "please enter your address").required("address is Required"),
    guestTitle: Yup.string().max(6, 'please enter your title').required('title is Required'),
    gender: Yup.string().max(6, 'please enter your gender').required('gender is Required'),
  }),

  onSubmit: async (values) => {
    setLoader(true)
    await axios.post('/registrations/createUser', values)
    .then(res =>  {
      if(res.data.message === "Registrated Successfully" ){
        notifySuccess(res, values)
      }else{
        notify(res)
      }
     })
    .catch(err => console.log("resgistration unsuccessfull", err))

    setLoader(false)
  
   }
})



return (
  <div className='parent_register'>
    <NavBar />

    {/* <Banner/> */}

    <div className='loginSwap regplace'>
      <div className='parent_form_contain'>
        {/* <div className='RegImg'>
      <img src={reg} alt='liquid'/>
    </div> */}

        <div className={showForm ? "container2" : "container"}>
          <div className='loginImg2'>
            <img src={reg} alt='liquid' />
          </div>
          <form className='register_form' onSubmit={formik.handleSubmit}>
            <img src={logo} alt='logo' />

            <div className='title_gender'>
              <div>
                {" "}
                <label htmlFor='name'>
                  Gender<span className='required-field__'></span>
                </label>
                <select
                  placeholder='Title'
                  name='gender'
                  id='gender'
                  className='selectGender'
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option disabled value=''>
                    {" "}
                    Gender
                  </option>
                  <option id='Male' value='Male'>
                    Male
                  </option>
                  <option id='Female' value='Female'>
                    Female
                  </option>
                </select>
              </div>
              <br />
              {formik.touched.gender && formik.errors.gender ? (
                <p className='error'>{formik.errors.gender}</p>
              ) : null}
              {/* <input type="text" 
            placeholder="Mr" 
            value={formik.values.title}
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur}
            name="title"
              id='title' 
            /> */}
              <div>
                {" "}
                <label htmlFor='name'>
                  Title<span className='required-field__'></span>
                </label>
                <select
                  placeholder='Title'
                  name='guestTitle'
                  id='guestTitle'
                  className='selectGender'
                  value={formik.values.guestTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option disabled value=''>
                    {" "}
                    Title
                  </option>
                  <option id='Mr' value='Mr'>
                    Mr
                  </option>
                  <option id='Mrs' value='Mrs'>
                    Mrs
                  </option>
                  <option id='Miss' value='Miss'>
                    Miss
                  </option>
                  <option id='Chief' value='Chief'>
                    Chief
                  </option>
                </select>
              </div>
              <br />
              {formik.touched.guestTitle && formik.errors.guestTitle ? (
                <p className='error'>{formik.errors.guestTitle}</p>
              ) : null}
            </div>
            <div>
              {" "}
              <label htmlFor='name'>
                First Name<span className='required-field__'></span>
              </label>
              <input
                type='text'
                placeholder='First Name'
                value={formik.values.firstName.trimStart()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='firstName'
                id='firstName'
              />
            </div>
            <br />
            {formik.touched.firstName && formik.errors.firstName ? (
              <p className='error'>{formik.errors.firstName}</p>
            ) : null}
            <div>
              {" "}
              <label for='name'>
                Last Name<span className='required-field__'></span>
              </label>
              <input
                type='text'
                placeholder='Last Name'
                value={formik.values.lastName.trimStart()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='lastName'
                id='lastName'
              />
            </div>
            <br />

            {formik.touched.lastName && formik.errors.lastName ? (
              <p className='error'>{formik.errors.lastName}</p>
            ) : null}
            <div>
              {" "}
              <label for='name'>
                Address<span className='required-field__'></span>
              </label>
              <textarea
                type='text'
                placeholder='Address'
                value={formik.values.address.trimStart()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='address'
                id='address'
              />
            </div>
            <br />

            {formik.touched.address && formik.errors.address ? (
              <p className='error'>{formik.errors.address}</p>
            ) : null}

            <button onClick={handleShowForm}>
              <GrLinkNext />
            </button>
            <br />
            {/* <a href="#">Forgot Password?</a> */}
          </form>

          {/* <div className="drops">
            <div className="drop drop-1"></div>
            <div className="drop drop-2"></div>
            <div className="drop drop-3"></div>
            <div className="drop drop-4"></div>
            <div className="drop drop-5"></div>
          </div> */}
        </div>

        {showForm && (
          <div className={showForm ? "container" : "container2"}>
            <div className='loginImg2'>
              <img src={reg} alt='liquid' />
            </div>
            <form className='register_form' onSubmit={formik.handleSubmit}>
              <img src={logo} alt='logo' />
              <div>
                {" "}
                <label for='name'>
                  Password<span className='required-field__'></span>
                </label>
                <div className='passwordInput'>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='password'
                    id='password'
                  />{" "}
                </div>
                {showPassword ? (
                  <BsFillEyeSlashFill
                    onClick={setShowPass}
                    className='togglePass'
                  />
                ) : (
                  <AiFillEye onClick={setShowPass} className='togglePass' />
                )}
              </div>
              <br />
              {formik.touched.password && formik.errors.password ? (
                <p className='error'>{formik.errors.password}</p>
              ) : null}

              <div>
                {" "}
                <label for='name'>
                  Country<span className='required-field__'></span>
                </label>
                <input
                  type='text'
                  placeholder='country'
                  value={formik.values.country.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='country'
                  id='country'
                />
              </div>
              <br />
              {formik.touched.country && formik.errors.country ? (
                <p className='error'>{formik.errors.country}</p>
              ) : null}
              {/* <input type="text" 
            placeholder="gender" 
            value={formik.values.gender}
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur}
            name="gender"
              id='gender'
            /> */}
              <div>
                {" "}
                <label for='name'>
                  Email Address<span className='required-field__'></span>
                </label>
                <input
                  type='email'
                  placeholder='email Address'
                  value={formik.values.emailAddress.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='emailAddress'
                  id='emailAddress'
                />
              </div>
              <br />
              {formik.touched.emailAddress && formik.errors.emailAddress ? (
                <p className='error'>{formik.errors.emailAddress}</p>
              ) : null}
              <div>
                {" "}
                <label for='name'>
                  Phone Number<span className='required-field__'></span>
                </label>
                <input
                  type='text'
                  placeholder='Phone Number'
                  value={formik.values.phoneNumber.trim()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='phoneNumber'
                  id='phoneNumber'
                />
              </div>
              <br />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <p className='error'>{formik.errors.phoneNumber}</p>
              ) : null}

              {loader ? (
                <button className='load'>
                  <InfinitySpin width='120' color='#fff' />
                </button>
              ) : (
                <>
                  <button type='submit'>Register</button> <br />
                </>
              )}
              <span onClick={handleShowForm}>
                <BiArrowBack className='bckBtn' />
              </span>
            </form>
            {/* 
            <div className="drops">
              <div className="drop drop-1"></div>
              <div className="drop drop-2"></div>
              <div className="drop drop-3"></div>
              <div className="drop drop-4"></div>
              <div className="drop drop-5"></div>
            </div> */}
          </div>
        )}
      </div>
    </div>
    <ToastContainer />
    <GetApp />
    <Footer />
  </div>
);
}

export default Register