import React from "react";
import NavBar from "../../../NavBar/NavBar";
import Subscribe from "../../../Subscribe/Subscribe";
import Footer from "../../Footer";
import './PrivacyPolicies.css'

const PrivacyPolicy = () => {
  return (
    <div className="privacyPolice">
      <NavBar />
        <div className="privacyPoliceDiv">
        <div className='privacyParent'>
        <h1>Privacy And Policies</h1>

        <h2>privacy policy</h2>
        <p>
          The privacy and security of your information are of utmost importance to us at TiptopGo.com. Whether you're booking a room or simply exploring our website (the "Site") as a prospective client, we want you to trust that the information you provide is being properly managed and protected.
        </p>

        <p>
          This Privacy Policy is issued by TiptopGo.com (the "Company"), covering information collected and used in the course of our business. Your information may be collected and used through your use of this Site. We have prepared this Privacy Policy to explain how we collect and manage your information.
        </p>
        

        <h2>INFORMATION WE COLLECT:</h2>
        <p>
          Information you give us: When you use the Site, you may provide various personally identifiable information. For example, we collect information when you make a booking through our reservation system or stay at a hotel. This may include your name, email address, address, phone number, nationality, and payment card information. Information collected during the reservation and stay may also include stay and room preferences.
        </p>
        <p>
         This information may be provided directly when you make a reservation through our system, websites, directly at the hotel, or through mobile applications. In some cases, we may receive this information from a third party, such as when you book through an online travel agency.
        </p>
        <p>
         Information we get from your use of the Site: We collect information when you browse the Site, use our mobile applications, or participate in services at the hotel, such as wireless internet services. This may include your country information, IP address, media access control address, and other characteristics about your system or device. This information is used for functional purposes, to improve your experience, and for aggregated trend and statistical analysis.
        </p>
        <p>
          Information stored on your computer ("Cookies"): When you visit the Site, we may store information on your computer in the form of a "cookie" or similar file. This helps us maintain and improve the Site. Cookies can be managed through your browser settings.
        </p>
        <h2>HOW WE USE THIS INFORMATION:</h2>

        <p>
          We use the information primarily to fulfill your hotel reservation. This includes sending your information to the hotel, pre-stay communications, and post-stay communications. With your consent or where permitted by law, we may send marketing communications for relevant products and services, display targeted advertisements, and use information for aggregated trend analysis.
        </p>
        <h2>WHO WE SHARE YOUR INFORMATION WITH:</h2>
        <p>
          We share your information with the hotel you are staying at to fulfill your reservation. Third-party service providers may also receive information to support our business and improve products and services. We may share information with third parties for special offers, but they must comply with our privacy requirements.

          <p>For specific circumstances like legal requirements, protecting safety, or business transactions, we may share information.</p>
        </p>

        <h2>DATA TRANSFER:</h2>

        <p>
          As we are affiliated with other hotels, your information may be transferred to our affiliated entities and hotels globally for the purpose of providing services.
        </p>

        <h2>LOCATION INFORMATION AND SERVICES:</h2>

        <p>
         We may receive location information to provide services through our websites and mobile applications. Your location preferences can be set or modified at the device level or through social media platform settings.
        </p>

        <h2>SOCIAL MEDIA:</h2>

        <p>
        Social media features may be used on our websites and applications. Information may be shared through these features, governed by the respective policies of third parties.
        </p>
        
        <h2>HOW WE SECURE YOUR INFORMATION:</h2>
        <p>
          We are committed to protecting the confidentiality and security of your information. Security measures are in place to safeguard against unauthorized access, disclosure, or loss.
        </p>

        <h2>MANAGING YOUR PREFERENCES AND INFORMATION:</h2>

        <p>
          You have tools to control the information you provide and how we communicate with you. Contact us to update inaccurate information.
        </p>

        <h2>SPECIAL INFORMATION FOR CALIFORNIA CONSUMERS:</h2>

        <p>
          California residents may request information about third parties to which we have disclosed personally identifiable information for direct marketing purposes.
        </p>

        <h2>LINKS TO OTHER SITES:</h2>
        <p>
          Our websites and applications may contain links to third-party websites. Review the privacy policies of these websites as their practices may differ from ours.
        </p>
        
        <h2>CHILDREN:</h2>
        <p>
          Our websites are not intended for children under 18. We do not intentionally collect personal information from individuals under 18.
        </p>

        <h2>CHANGES TO THIS PRIVACY POLICY:</h2>
        <p>
          We may change this Privacy Policy to comply with regulations or business needs. Changes will be made subject to applicable legal requirements.
        </p>
       
      </div>
        </div>
      
      <Subscribe />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
