import React, { useEffect, useState } from "react";
import "./BookSummary.css";
import { useLocation } from "react-router-dom";
import NavBar from "../../HomePage/NavBar/NavBar";
import logo from "../../assets/TiptopGoLogo.png";
import Footer from "../../HomePage/Footer/Footer";
import GetApp from "../../HomePage/Get App/GetApp";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { TfiReceipt } from "react-icons/tfi";
import BookingSummary from "../BookingSummary2/BookingSummary";

const BookSummary = () => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [discount, setDiscount] = useState();
  const [payAtHotel, setPayAtHotel] = useState(false);
  let history = useLocation();

  const {
    allValue,
    bookCode,
    payAtHotelWarning,
    address,
    discountAmountDisplay,
    hotelPicture,
    combinedTaxArray,
    totalAmount,
    totalRoomCostNoVat,
    getFlateDiscountAmount,
    totalBillOnDiscountVat,
    comTaxArrayNoDiscount,
    // payAtHotel,
  } = history.state;

  useEffect(() => {
    const checkPaymentmethod = () => {
      if (allValue.totalBill <= 0) {
        setShowDiscount(false);
        setPayAtHotel(true);
      } else {
        setShowDiscount(true);
        const discountAmount = allValue.paymentDetails[0].amount;
        setDiscount(discountAmount && discountAmount);
      }

      if (discountAmountDisplay === 0) {
        setShowDiscount(false);
      } else {
        setShowDiscount(true);
      }
    };
    checkPaymentmethod();
  }, []);

  const downloadPDF = () => {
    // Generate the PDF
    const pdf = new jsPDF();

    const imageUrl = logo;
    const x2 = -8; // X-coordinate (horizontal position)
    const y2 = 1; // Y-coordinate (vertical position)
    const width = 290; // Width of the image
    const height = 50; // Height of the image

    pdf.addImage(imageUrl, "PNG", x2, y2, width, height);

    const userInfo = `
    Hotel Name: ${allValue.hotelName}
    CheckInDate: ${allValue.checkInDate}
    CheckOutDate: ${allValue.checkOutDate}
    Booking code: ${bookCode}
    Hotel Address: ${address}`;

    pdf.setFont("Poppins", "sans-serif");
    pdf.setFontSize(10);
    pdf.setTextColor(0, 0, 0);
    pdf.setFillColor(255, 0, 0);

    // Add content to the PDF
    pdf.text(userInfo, 10, 45);

    const columns = [
      "Room Type",
      "Quantity",
      // "BreakFast Qty",
      // "BreakFast Cost",
      "Day(s)",
      "Room Price",
      "Total",
    ];

    // Define the data to be displayed in the table
    const tableData = allValue.bookings.map((book) => [
      book.roomTypeName,
      book.quantity,
      // book.numOfBreakfast || 0,
      // book.totalBreakfastCost || 0,
      allValue.numOfDays,
      book.roomCost.toLocaleString(),
      book.totalCostNoDiscount.toLocaleString(),
    ]);

    const x = 10; // X-coordinate
    let y = 75; // Y-coordinate
    let m = 98;

    const tableHeaders = [columns];

    // Combine the headers and data
    const fullTable = tableHeaders.concat(tableData);

    // Set the table column widths (adjust as needed)
    const columnWidths = [50, 20, 40];

    pdf.autoTable({
      head: fullTable, // Headers and data
      startY: y,
      stopX: y, // Initial Y-coordinate
      headStyles: { fillColor: [100, 100, 100] }, // Header row style
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
        1: { cellWidth: columnWidths[1] },
        2: { cellWidth: columnWidths[2] },
      }, // Column widths
    });
    // Save the PDF

    const userPaymentDetails = `
    Sub Total Amount: ${totalRoomCostNoVat.toLocaleString()}
    Pay Now Discount: ${
      payAtHotel ? 0 : getFlateDiscountAmount.toLocaleString() || "0"
    }
    Total Amount: ${
      payAtHotel
        ? totalRoomCostNoVat.toLocaleString()
        : totalAmount.toLocaleString()
    }
    Ground Total: ${
      payAtHotel
        ? payAtHotelWarning.toLocaleString()
        : totalBillOnDiscountVat.toLocaleString()
    }`;

    const taxColumns = ["Tax Label", "Amount", "Percentage"];

    const taxTableData = payAtHotel
      ? comTaxArrayNoDiscount.map((tax) => [
          tax.taxLabel,
          `${tax.amount.toLocaleString()}`,
          tax.type === "flatAmount"
            ? tax.percentageOrFlatAmount.toLocaleString()
            : `${tax.percentageOrFlatAmount.toLocaleString()}%`,
        ])
      : combinedTaxArray.map((tax) => [
          tax.taxLabel,
          `${tax.amount.toLocaleString()}`,
          tax.type === "flatAmount"
            ? tax.percentageOrFlatAmount.toLocaleString()
            : `${tax.percentageOrFlatAmount}%`,
        ]);

    pdf.autoTable({
      head: [taxColumns],
      body: taxTableData,
      startY: m + 10, // Adjust startY to leave space after the previous content
      headStyles: { fillColor: [100, 100, 100] },
      columnStyles: {
        0: { cellWidth: 50 }, // Adjust column widths as needed
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
      },
    });

    // Increment y-coordinate for the next content
    m = pdf.autoTable.previous.finalY + 10;
    m += 10;

    pdf.text(userPaymentDetails, 20, 145);

    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0, 128, 0);
    pdf.setLineDash([2]);
    pdf.line(10, 102, 200, 102);

    pdf.setLineWidth(0.5);
    pdf.setDrawColor(128, 128, 128);
    pdf.setLineDash([2.5]);
    pdf.line(10, 166, 200, 166);

    const hotelPolicy = `
    Tiptopgo.com provides temporary accommodation of all types, which include motels, hotels, apartments, etc. 
    The provision of these services are found on the website through which you can book for your chosen service. 
    By placing a reservation through us,you enter into a legally binding agreement with the direct service 
    provider (hotel and the likes) with which your reservation is made to. Right after your reservation is placed,
    Tiptopgo.com acts simply as a mediator between you and the service provider.
    

    For Further Enquiry call 
    
    +234 08135936594
    
    +234 02094601555

    `;

    pdf.text(hotelPolicy, 10, 172);

    pdf.save("tiptopGo-receipt.pdf");
  };

  return (
    <>
      <BookingSummary
        bookingDetails={allValue}
        hotelPicture={hotelPicture}
        discountAmountDisplay={discountAmountDisplay}
        address={address}
        payAtHotelWarning={payAtHotelWarning}
        bookCode={bookCode}
        totalAmount={totalAmount}
        combinedTaxArray={combinedTaxArray}
        downloadPDF={downloadPDF}
        payAtHotel={payAtHotel}
        comTaxArrayNoDiscount={comTaxArrayNoDiscount}
      />
    </>
  );
};

export default BookSummary;
