import React, { useState, useRef } from "react";
import { useOnHoverOutside } from "../../hooks/useOnHoverOutside";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import "./NavBar.css";
import logo from "../../assets/TiptopGoLogo.png";
import { handleClearStore } from "../../Redux/Actions/room_actions";
import { connect } from "react-redux";
import MobileMenu from "./MobileMenu";
import JobsButton from "../JobsModule/JobsButton/JobsButton";
import CurrencyChangeButton from "./currency/currencyButton/currencyChangeButton";
import { Support_Number, Support_Number_Mobile } from "./Support_Number";

const NavBar = ({ handleClearStore }, props) => {
  const [showlink, setShowLink] = useState(false);
  // const { hotelId, randomCode, replacedString } = useParams();
  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  //console.log('isMenuDropDownOpen2233', isMenuDropDownOpen);
  const navigate = useNavigate();
  const history = useLocation();
  const [currentPage, setCurrentPage] = useState(history.pathname);

  // Function to close dropdown
  const closeHoverMenu = (e) => {
    e.preventDefault();
    setMenuDropDownOpen(false);
  };

  const getUserData = () => {
    const userData = localStorage.getItem("userData");

    navigate("/book", {
      state: {
        userData,
      },
    });
  };

  const logOut = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("loggInStatus");
    localStorage.removeItem("historyData");
    handleClearStore();
    navigate("/", { state: {} });
  };

  const isLogin = localStorage.getItem("loggInStatus");

  //console.log('isMenuDropDownOpen', isMenuDropDownOpen);

  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook

  const generateRandomCode = (length) => {
    const characters = "0123456789";
    let code = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const randomCode = generateRandomCode(5);

  return (
    <>
      <Support_Number_Mobile />

      <section>
        <nav>
          <Link to='/' style={{ textDecoration: "none" }}>
            <JobsButton />
            <div className='logos'>
              <img src={logo} alt='logo' />
            </div>
          </Link>

          <ul className='navBarLink' id={showlink ? " " : "hidden"}>
            <li className='currHomeBtn'>
              <CurrencyChangeButton />
            </li>
            {/* <li className='reservationFind'>
              <Link to='/tiptopgo-blog'>View Articles</Link>
            </li> */}
            <li>
              <Link to='/contact'>Support</Link>
            </li>
            <li className='reservationFind'>
              <Link to='/check'>Find Reservation</Link>
            </li>

            {isLogin === "true" ? (
              <Link to='/LoginDash/main-dashboard'>
                <li>Account</li>{" "}
              </Link>
            ) : null}

            <div className='parentregLog'>
              <div className='regLog'>
                <li>
                  <Link
                    to='/register-hotel'
                    style={{
                      display:
                        currentPage === "/register-hotel" ? "none" : "block",
                    }}
                  >
                    <button className='login'>Register Hotel</button>
                  </Link>
                </li>
                {/* <li><Link to='/Register' style={{display: currentPage === '/Register' ? 'none' : 'block'}}><button className='register'>Register</button></Link></li> */}

                {isLogin === "true" ? (
                  <li>
                    <button className='login' onClick={logOut}>
                      {" "}
                      Sign out
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link
                      to='/Login'
                      style={{
                        display: currentPage === "/book" ? "none" : "block",
                      }}
                    >
                      <button className='login'>Sign in / Register</button>
                    </Link>
                  </li>
                )}
              </div>
              <Support_Number />
            </div>
          </ul>

          <MobileMenu closeMobileMenu={props.closeMobileMenu} />
        </nav>
      </section>
    </>
  );
};

// const mapStateToProps = (state) => {
//   const cart = state.rootReducer.cart;
//   return cart;
// };

export default connect(null, { handleClearStore })(NavBar);
