import React from "react";
import "./Blog.css";

import NavBar from "../../HomePage/NavBar/NavBar";
import Footer from "../../HomePage/Footer/Footer";
import GetApp from "../../HomePage/Get App/GetApp";
import TrendingPost from "../TrendingPost.jsx/TrendingPost";

const Blog = () => {
  return (
    <>
      <NavBar />

      <div className='parentBlog'>
        <div className='blogherosection'>
          <div className='blogimgtext'>
            <h3>Webflow review: My honest experience after 5 years</h3>
            <div className='blogimgtextparadiv'>
              <p>
                If you’ve been thinking about using Webflow, this Webflow review
                will give you literally everything you need to know about this
                website builder.
              </p>
            </div>
            <div className='blogpostauthor'>
              <div className='postauthorDetails'>
                <div className='authorimg'>
                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0KehqXBrMLd32HsfjDoaq098WeNA0b3g_2A&s'
                    alt=''
                  />
                </div>
                <div>charlse woke</div>
                <div>10/03/2024</div>
              </div>
            </div>

            <div className='blogheroimage'>
              <img
                src='https://cdn.prod.website-files.com/6243c3bb3b5a1852803d0c7f/6707e161868aed52343bfb6b_webflow-review.jpg'
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='firstparagraph'>
          <div className='postcontent'>
            <p>
              This is the article I wish existed when I first heard about
              Webflow. By the end of this Webflow review, you will know if
              Webflow is right for you or not. It’s taken me four years of
              experience with the platform to write down everything I know about
              it in this article.
            </p>
            <p>
              On a windy night in 2017, three cups of coffee deep, I found
              myself banging my head trying to figure out how I could create a
              custom website using HTML and CSS.
            </p>
            <p>
              Website templates weren't cutting it for me, and I wanted to build
              a fully custom website — without having to learn how to code.
            </p>
            <p>
              After a few Google searches, I came across Webflow for the first
              time. Excited, I signed up.
            </p>
            <p>
              But soon after, I realized this wasn’t your regular site builder.
              Flustered with the learning curve, I gave up.
            </p>
            <p>
              It wouldn't be until almost a year later, when I was working at a
              marketing agency, that I would find our creative director building
              our clients’ websites with Webflow. Her enthusiasm about the
              platform intrigued me, and I decided I should give Webflow another
              chance.
            </p>
            <h2>6 Best Cruise Ships for Your European Adventure</h2>
            <div className='paragraphImg'>
              <img
                src='https://cdn.prod.website-files.com/6243c3bb3b5a1852803d0c7f/6707e1543fed7b76ef24a929_66fb0d9e986f24a3b993b833_668b41e71cbf1ef9f9d01218_webflow.jpeg'
                alt=''
              />
            </div>

            <p>
              Europe is one of the most amazing continents to explore diverse
              cuisines, breathtaking landscapes and rich histories. One of the
              most relaxing and enjoyable ways to explore this continent is by
              cruise. Imagine waking up each day in a new country, set to go on
              endless adventures.
            </p>

            <div className='commentSection'>
              <h3>Leave A Reply</h3>
              <div className='commentparentdiv'>
                <div className='commentBioparentdiv'>
                  <div className='commentiinputdiv'>
                    <label htmlFor=''>Name</label>
                    <input type='text' />
                  </div>
                  <div className='commentiinputdiv'>
                    <label htmlFor=''>Email</label>
                    <input type='text' />
                  </div>
                </div>
                <div className='textareadiv'>
                  <label htmlFor=''>comment</label>
                  <textarea name='' id=''></textarea>
                </div>
                <div className='commentsubmitbtn'>
                  <button>post comment</button>
                </div>
              </div>
            </div>
          </div>
          <div className='trendingComdiv'>
            <TrendingPost />
          </div>
        </div>
      </div>
      <GetApp />
      <Footer />
    </>
  );
};

export default Blog;
