import React from 'react'
import './CongratulationModal.css'
import goodMark from '../../assets/goodIcon.gif'
import {FaExclamation} from 'react-icons/fa'

const CongratulationModal = ({closeModal}) => {


  return (
    <div className='parentCongrat'>
        <div className='congratModalBody'>
            <img src={goodMark} />
        <p className='congratText' >Congratulation<FaExclamation className='iconColor'/><FaExclamation className='iconColor'/><FaExclamation className='iconColor'/></p>
        <p className='congratSecondText'>We appreciate your valuable feedback, and it will help us enhance our services to better <br/> serve you in the future.</p>
        <p></p>
        <button onClick={closeModal} className='doneBtn'> I'm Done </button>
        </div>
    </div>
  )
}

export default CongratulationModal