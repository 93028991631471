import React, { useEffect, useState, useReducer } from "react";
import NavBar from "../../HomePage/NavBar/NavBar";
import "./BookingSummary.css";
import { GoLocation } from "react-icons/go";
import Footer from "../../HomePage/Footer/Footer";
import GetApp from "../../HomePage/Get App/GetApp";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { GetCurrencyData } from "../../Redux/Actions/currency_actions";
import { convertAmount } from "../../utilities/GeneralFunctions";
import { TfiReceipt } from "react-icons/tfi";

const BookingSummary = ({
  bookingDetails,
  bookCode,
  discountAmountDisplay,
  payAtHotelWarning,
  downloadPDF,
  address,
  combinedTaxArray,
  comTaxArrayNoDiscount,
  hotelPicture,
  totalAmount,
  payAtHotel,
}) => {
  const [date, setDate] = useState();
  // const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0)
  const getSelectedCurrency = GetCurrencyData();
  const selectedCurrencyObject = getSelectedCurrency.selectedCurrency;

  const { currencySymbol, currencyRate, currencyCode } = selectedCurrencyObject;

  

  useEffect(() => {
    const date = new Date();
    const formattedDate = moment(date).format("YYYY-MM-DD HH : mm : ss");
    setDate(formattedDate);
  }, []);

  const history = useLocation();
  // const {allBookingDetails, roomTypes, bookCode} = history.state


  let totalAmountpaid = 0;
  let disCountAmount = 0;

  for (const book of bookingDetails && bookingDetails.bookings) {
    let discount = book.newKey;
    let amountForRoom = book.totalCostNoDiscount;

    totalAmountpaid += amountForRoom;
    disCountAmount += discount;
  }

  const getFlatDiscountAmount =
    convertAmount(totalAmountpaid, currencyRate, currencyCode) -
    convertAmount(totalAmount, currencyRate, currencyCode);

  const totalAmountNoDiscount = totalAmountpaid - getFlatDiscountAmount;

  

  return (
    <div>
      <NavBar />
      <div className='containerSummary'>
        <div className='parent_summ'>
          <div className='row heardpart'>
            <div className=' col-md-6'>
              <span>Booking Date : {date}</span>
            </div>
            <div className='col-md-6'>
              <span>Booking Code : {bookCode}</span>
            </div>
          </div>

          <div className='detailPart'>
            <div className='hotelTextPart'>
              {/* <p className='NB'>
                NB: This payment is valid for only one time check-in{" "}
              </p> */}
              <p className='textofNote'>
                Thank you for using tiptopGo.com with us! We are excited to have
                you as a guest. Your booking confirmation email has been sent to{" "}
                <span className='email'>
                  {bookingDetails.payingGuest.email}
                </span>
                . Please check your inbox for the email. The email will contain
                a copy of your booking receipt, as well as all of the important
                details about your stay. If you have any questions, please do
                not hesitate to contact us. We look forward to seeing you soon!
              </p>
              <h3>{bookingDetails.hotelName}</h3>
              <p>
                {" "}
                <GoLocation className='locate' /> {address}
              </p>
              <p></p>
              <table className='table table-striped table-hover'>
                <tbody>
                  <tr>
                    <th scope='row'>Guest Name</th>
                    <td>
                      {bookingDetails.payingGuest.lastName}{" "}
                      {bookingDetails.payingGuest.firstName}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>Guest Email</th>
                    <td>{bookingDetails.payingGuest.email}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Mobile Number</th>
                    <td>{bookingDetails.payingGuest.phoneNumber}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Check-in Date</th>
                    <td>{bookingDetails.checkInDate}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Check-Out Date</th>
                    <td>{bookingDetails.checkOutDate}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className=' imgDiv'>
              <img src={hotelPicture} alt='hotel image' />
            </div>
          </div>

          <div className='tableDiv3'>
            <table className='table table-striped table-hover table2'>
              <thead>
                <tr>
                  <th scope='col'>S/N</th>
                  <th scope='col'>Room Type</th>
                  <th scope='col'>Quantity</th>
                  {/* <th scope='col'>BreakFast Qty</th>
                  <th scope='col'>BreakFast Cost</th> */}
                  <th scope='col'>No of Days</th>
                  <th scope='col'>Room price</th>
                  <th scope='col'>Total</th>
                </tr>
              </thead>
              {bookingDetails &&
                bookingDetails.bookings.map((booking, index) => (
                  <tbody>
                    <tr className='bookingDetailRow'>
                      <th scope='row'>{index + 1}</th>
                      <td>{booking.roomTypeName}</td>
                      <td>{booking.qty}</td>
                      {/* <td>{booking.numOfBreakfast || 0}</td>
                      <td>
                        {convertAmount(
                          booking.totalBreakfastCost,
                          currencyRate,
                          currencyCode
                        ) || 0}
                      </td> */}
                      <td>{bookingDetails.numOfDays}</td>
                      <td>
                        {currencySymbol || "₦"}{" "}
                        {convertAmount(
                          booking.roomCost,
                          currencyRate,
                          currencyCode
                        ).toLocaleString()}
                      </td>
                      <td>
                        {currencySymbol || "₦"}{" "}
                        {convertAmount(
                          booking.totalCostNoDiscount,
                          currencyRate,
                          currencyCode
                        ).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
            <br />
            <br />

            <div className='totBill'>
              <div className='totBillDescrib'>
                <th scope='row' colSpan='5'>
                  Sub Total Amount
                </th>
                <td colSpan='3' className='amountStyleDiv'>
                  {" "}
                  {currencySymbol || "₦"}{" "}
                  {convertAmount(
                    totalAmountpaid,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                </td>
              </div>
            </div>

            <div className='disAmount'>
              <div className='disAmountDescrib'>
                <th scope='row' colSpan='5'>
                  Pay Now Discount
                </th>
                <td colSpan='3' className='amountStyleDiv'>
                  {" "}
                  {currencySymbol || "₦"}{" "}
                  {payAtHotel
                    ? 0
                    : discountAmountDisplay === undefined
                    ? parseFloat(
                        getFlatDiscountAmount.toFixed(2)
                      ).toLocaleString()
                    : convertAmount(
                        discountAmountDisplay,
                        currencyRate,
                        currencyCode
                      ) || 0}
                  {/* {convertAmount(
                    discountAmountDisplay,
                    currencyRate,
                    currencyCode
                  ) || 0} */}
                </td>
              </div>
            </div>

            <div className='totalAmount'>
              <div className='totalAmountDescrib'>
                <th scope='row' colSpan='5'>
                  Total Amount
                </th>
                <td colSpan='3' className='amountStyleDiv'>
                  {" "}
                  {currencySymbol || "₦"}{" "}
                  {convertAmount(
                    payAtHotel ? totalAmountpaid : totalAmount,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                </td>
              </div>
            </div>

            {payAtHotel ? (
              <div className='tax-container_Summary'>
                {comTaxArrayNoDiscount &&
                  comTaxArrayNoDiscount.map((taxObject, i) => (
                    <div className='tax-item_summary'>
                      <span className='taxLabel_summary'>
                        {taxObject.taxLabel}{" "}
                        <span>
                          ({taxObject.percentageOrFlatAmount}
                          {taxObject.type === "flatAmount" ? "" : "%"})
                        </span>
                      </span>
                      <div className='taxAmount_summary_summary'>
                        <span className='taxindiviAmount_summary'>
                          {currencySymbol || "₦"}{" "}
                          {taxObject.amount.toLocaleString()}
                          {taxObject.type === "flatAmount" ? ".00" : ""}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className='tax-container_Summary'>
                {combinedTaxArray &&
                  combinedTaxArray.map((taxObject, i) => (
                    <div className='tax-item_summary'>
                      <span className='taxLabel_summary'>
                        {taxObject.taxLabel}{" "}
                        <span>
                          ({taxObject.percentageOrFlatAmount}
                          {taxObject.type === "flatAmount" ? "" : "%"})
                        </span>
                      </span>
                      <div className='taxAmount_summary_summary'>
                        <span className='taxindiviAmount_summary'>
                          {currencySymbol || "₦"}{" "}
                          {taxObject.amount.toLocaleString()}
                          {taxObject.type === "flatAmount" ? ".00" : ""}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            <div className='totPayment'>
              <div className='total_describ'>
                <th scope='row' colSpan='5'>
                  Ground Total
                </th>
                <td colSpan='3' className='amountStyleDiv'>
                  {" "}
                  {currencySymbol || "₦"}{" "}
                  {convertAmount(
                    payAtHotel ? payAtHotelWarning : bookingDetails.totalBill,
                    currencyRate,
                    currencyCode
                  ).toLocaleString()}
                  .00
                </td>
              </div>
            </div>
          </div>
          <div className='receiptDiv'>
            <button onClick={downloadPDF} className='downloadRep'>
              <TfiReceipt /> Download Receipt
            </button>
          </div>
        </div>
      </div>
      <GetApp />
      <Footer />
    </div>
  );
};

export default BookingSummary;
