import React, { useState, useRef } from "react";
import { Formik, Field, FieldArray, Form } from "formik";
import * as Yup from "yup";
import { MdPlaylistRemove } from "react-icons/md";
import { FaEye, FaBlog, FaPlus } from "react-icons/fa";
import { GoEyeClosed } from "react-icons/go";
import "./CreateBlog.css";
import axios from "../../../utilities/axios";

// Validation schema (optional)
const BlogSchema = Yup.object().shape({
  blogType: Yup.string().required("Blog type is required"),
  blogCategory: Yup.string().required("Blog category is required"),
  title: Yup.string().required("Blog title is required"),
  summary: Yup.string()
    .required("Title summary is required")
    .max(200, "Summary must be at most 200 characters"),
  mainImage: Yup.mixed().required("Main image is required"),
  firstMainParagraph: Yup.string().required("First paragraph is required"),
});

const AdminBlogPostForm = () => {
  const [previewData, setPreviewData] = useState(null); // State to hold preview data
  const [showPreviewpanel, setShowPreviewPanel] = useState(false);
  // const [formValues, setFormValues] = useState({
  //   blogType: "",
  //   blogCategory: "",
  //   title: "",
  //   summary: "",
  //   mainImage: null,
  //   firstMainParagraph: "",
  //   paragraphs: [],
  //   subSections: [],
  // });

  // const blogPostCreatedBy = JSON.parse(localStorage.getItem("userData"));
  // console.log("blogPostCreatedBy", blogPostCreatedBy);

  const formValuesRef = useRef({
    createdBy: "",
    blogType: "",
    blogCategory: "",
    title: "",
    summary: "",
    mainImage: null,
    firstMainParagraph: "",
    paragraphs: [],
    subSections: [],
  });

  const handleSubmit = async (values) => {
    console.log("Submitted Data:", values);
    const formData = new FormData();

    // Append simple fields
    formData.append("blogType", values.blogType);
    formData.append("blogCategory", values.blogCategory);
    formData.append("title", values.title);
    formData.append("summary", values.summary);
    formData.append("firstMainParagraph", values.firstMainParagraph);
    
    // Append main image (if exists)
    if (values.mainImage?.file) {
      formData.append("mainImage", values.mainImage.file);
    }
    
    // Append paragraphs (as JSON string)
    formData.append("paragraphs", JSON.stringify(values.paragraphs));
    
    // Serialize and append sub-sections (without images)
    const serializedSubSections = values.subSections.map((subSection) => ({
      ...subSection,
      image: undefined, // Exclude images from JSON serialization
    }));
    formData.append("subSections", JSON.stringify(serializedSubSections));
    
    // Append sub-section images individually (if exists)
    values.subSections.forEach((subSection, index) => {
      if (subSection.image?.file) {
        formData.append(`subSectionImage_${index}`, subSection.image.file);
      }
    });
    
    // Log all FormData entries for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    
    try {
      const response = await axios.post("/registrations/createBlogData", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit blog post");
      }
      console.log("Blog post submitted successfully!");
    } catch (error) {
      console.error("Error submitting blog post:", error);
    }
  };

  const handlePreview = (values) => {
    const isValid =
      values.title &&
      values.summary &&
      values.firstMainParagraph &&
      values.blogType &&
      values.blogCategory;

    if (isValid) {
      setPreviewData(values); // Store form data for preview
      setShowPreviewPanel(true); // Open preview panel
    } else {
      alert("Please fill in the required fields to preview the post.");
    }
  };

  const closePreview = () => {
    setShowPreviewPanel(false); // Close preview panel
  };

  return (
    <div className="parentCreatBlog">
      <div className="creatblogDiv">
        {showPreviewpanel ? (
          <div className="previewSection">
            <h2>{previewData.title}</h2>
            <p>{previewData.summary}</p>
            {previewData.mainImage && (
              <img src={previewData.mainImage.previewURL} alt="Main Blog" />
            )}
            <p>{previewData.firstMainParagraph}</p>
            {previewData.paragraphs.map((para, index) => (
              <p key={index}>{para}</p>
            ))}
            {previewData.subSections.map((subHeader, index) => (
              <div key={index}>
                <h3>{subHeader.title}</h3>
                {subHeader.image && (
                  <img src={subHeader.image.previewURL} alt={`Sub-Header ${index + 1}`} />
                )}
                {subHeader.paragraphs.map((para, pIndex) => (
                  <p key={pIndex}>{para}</p>
                ))}
              </div>
            ))}
            <div className="blogPostBtn">
              <button
                type="button"
                onClick={closePreview}
                className="previewBtn"
              >
                <GoEyeClosed /> Close Preview
              </button>
              <button
                type="button"
                onClick={() => handleSubmit(previewData)}
                className="submitpostBtn"
              >
                <FaBlog /> Submit Blog Post
              </button>
            </div>
          </div>
        ) : (
          <Formik
            initialValues={formValuesRef.current}
            enableReinitialize={false}
            validationSchema={BlogSchema}
            onSubmit={(values) => {
              formValuesRef.current = values; // Save form values
              handleSubmit(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form className="blogPostArea">
                <h2>Create a Blog Post</h2>

                <div className="blogCategoryselection">
                  <div className="blogInputDiv">
                    <label htmlFor="blogCategory">Blog Category</label>
                    <Field as="select" name="blogCategory">
                      <option value="">Select a category</option>
                      <option value="fashion">Fashion</option>
                      <option value="hospitality">Hospitality</option>
                      <option value="event">Event</option>
                    </Field>
                    {errors.blogCategory && touched.blogCategory && (
                      <div className="error">{errors.blogCategory}</div>
                    )}
                  </div>

                  <div className="blogInputDiv">
                    <label htmlFor="blogType">Blog Type</label>
                    <Field as="select" name="blogType">
                      <option value="">Select a type</option>
                      <option value="trending">Trending</option>
                      <option value="regular">Regular</option>
                      <option value="news">News</option>
                    </Field>
                    {errors.blogType && touched.blogType && (
                      <div className="error">{errors.blogType}</div>
                    )}
                  </div>
                </div>

                <div className="blogInputDiv">
                  <label htmlFor="title">Blog Post Title</label>
                  <Field
                    name="title"
                    type="text"
                    placeholder="Enter blog title"
                  />
                  {errors.title && touched.title && (
                    <div className="error">{errors.title}</div>
                  )}
                </div>

                <div className="blogInputDiv">
                  <label htmlFor="summary">Title Summary</label>
                  <Field
                    as="textarea"
                    name="summary"
                    placeholder="Enter summary"
                    onInput={(e) =>
                      setFieldValue("summary", e.target.value.slice(0, 200))
                    }
                  />
                  <div className="summaryErrorDiv">
                    {errors.summary && touched.summary && (
                      <div className="error">{errors.summary}</div>
                    )}
                    <p>{values.summary.length} / 200</p>
                  </div>
                </div>

                <div className="blogInputDiv">
                  <label htmlFor="mainImage">Blog Post Main Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const previewURL = URL.createObjectURL(file); // Create preview URL
                        setFieldValue("mainImage", { file, previewURL }); // Store both
                      }
                    }}
                  />
                  {errors.mainImage && touched.mainImage && (
                    <div className="error">{errors.mainImage}</div>
                  )}
                </div>

                <div className="blogInputDiv">
                  <label htmlFor="firstMainParagraph">First Paragraph</label>
                  <Field
                    as="textarea"
                    name="firstMainParagraph"
                    placeholder="Enter the first paragraph"
                  />
                  {errors.firstMainParagraph && touched.firstMainParagraph && (
                    <div className="error">{errors.firstMainParagraph}</div>
                  )}
                  <FieldArray name="paragraphs">
                    {({ push, remove }) => (
                      <div style={{ width: "100%" }}>
                        {values.paragraphs.map((_, index) => (
                          <div
                            key={index}
                            className="blogInputDiv"
                            style={{
                              width: "100%",
                              borderBottom: "2px solid grey",
                              marginBottom: "1%",
                            }}
                          >
                            <label>Paragraph {index + 2}</label>
                            <Field
                              as="textarea"
                              name={`paragraphs[${index}]`}
                              placeholder={`Enter paragraph ${index + 2}`}
                              className="FieldShow"
                            />
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="removeField"
                            >
                              <MdPlaylistRemove />
                              Remove
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => push("")}
                          className="addField"
                        >
                          Add Paragraph
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>

                <FieldArray name="subSections">
                  {({ push, remove }) => (
                    <div className="subContentiv">
                      {values.subSections.map((subHeader, index) => (
                        <div key={index} className="blogInputDivsubCont">
                          <h3>Sub Section {index + 1}</h3>
                          <label>Sub Section Header</label>
                          <Field
                            name={`subSections[${index}].title`}
                            type="text"
                            placeholder={`Enter sub-header title ${index + 1}`}
                          />

                          <label>Sub Section Image</label>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const previewURL = URL.createObjectURL(file);
                                setFieldValue(`subSections[${index}].image`, {
                                  file,
                                  previewURL,
                                });
                              }
                            }}
                          />

                          <FieldArray name={`subSections[${index}].paragraphs`}>
                            {({
                              push: pushParagraph,
                              remove: removeParagraph,
                            }) => (
                              <>
                                {subHeader.paragraphs.map((_, pIndex) => (
                                  <div key={pIndex} className="blogInputDiv">
                                    <label>Paragraph {pIndex + 1}</label>
                                    <Field
                                      as="textarea"
                                      name={`subSections[${index}].paragraphs[${pIndex}]`}
                                      placeholder={`Enter paragraph`}
                                    />
                                    <button
                                      type="button"
                                      onClick={() => removeParagraph(pIndex)}
                                      className="removeField"
                                    >
                                      <MdPlaylistRemove />
                                      Remove sub paragraph
                                    </button>
                                  </div>
                                ))}
                                <button
                                  type="button"
                                  className="addSubParagraph"
                                  onClick={() => pushParagraph("")}
                                >
                                  <FaPlus className="addIcon" />
                                  Add sub paragraph
                                </button>
                              </>
                            )}
                          </FieldArray>

                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="removeField"
                          >
                            <MdPlaylistRemove />
                            Remove Sub Section
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() =>
                          push({ title: "", image: null, paragraphs: [] })
                        }
                        className="addField"
                      >
                        <FaPlus className="addIcon" /> Add Sub Section
                      </button>
                    </div>
                  )}
                </FieldArray>

                <div className="blogPostBtn">
                  <button
                    type="button"
                    onClick={() => {
                      formValuesRef.current = values; // Save form data
                      handlePreview(values);
                    }}
                    className="previewBtn"
                  >
                    <FaEye /> Preview Post
                  </button>
                  <button type="submit" className="submitpostBtn">
                    <FaBlog /> Submit Blog Post
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AdminBlogPostForm;
