import { CHANGE_CURRENCY } from "../Types/room_types";

const initialState = {};

const currencyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_CURRENCY:
      return {
        ...state,
        selectedCurrency: payload,
      };
    

    default:
      return state;
  }
};

export default currencyReducer;
