import React, { useEffect, useState } from "react";
import "./currencyChangeComponent.css";
import { handleCurrencyChange } from "../../../../Redux/Actions/currency_actions";
import { connect } from "react-redux";

const CurrencyChange = ({ updateImage, currencies, handleCurrencyChange }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCurrencyImage, setSelectedCurrencyImage] = useState("");

  const handleCurrencyToggle = (currency) => {
    setSelectedCurrency(currency.currencyCode);
    setSelectedCurrencyImage(currency.currencyImage);
    const currencyJsonString = JSON.stringify(currency);
    localStorage.setItem("currency", currencyJsonString);
    handleCurrencyChange(currency);
  };

  useEffect(() => {
    updateImage(selectedCurrencyImage, selectedCurrency);
  }, [selectedCurrencyImage, selectedCurrency, updateImage]);

  return (
    <div className='parentCurrency'>
      <p className='currIntro'>Choose your preferred currency</p>

      <div className='currencyList'>
        {currencies &&
          currencies.map((currency) => (
            <p
              key={currency.currencyId}
              onClick={() => {
                handleCurrencyToggle(currency);
              }}
              className={`currencyItem ${
                currency === selectedCurrency ? "active" : ""
              }`}
            >
              <img
                src={currency.currencyImage}
                alt={`${selectedCurrency} flag`}
                style={{ width: "20%", height: "auto" }}
              />{" "}
              {currency.currencyCode} | {currency.currencySymbol}
            </p>
          ))}
      </div>
    </div>
  );
};


export default connect(null, { handleCurrencyChange })(CurrencyChange);