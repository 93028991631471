import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoadingSkeleton = () => {
  return (
    <>
      <div className='form1'>
        <div className='room_type'>
          <div className='book_img'>
            <Skeleton height={150} />
          </div>
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={4} />
            </h3>
            <span className='room_type_price'>
              {/* <p>
                       <span className='price_span'> <Skeleton/></span> <br /> <i><Skeleton/></i>{" "}
                      </p>

                      <div className='addRoomDiv'>
                        <Skeleton/>
                      </div> */}
            </span>
          </div>
        </div>
        <Skeleton width={50} style={{ marginTop: "-10%" }} />
      </div>

      <div className='form1'>
        <div className='room_type'>
          <div className='book_img'>
            <Skeleton height={150} />
          </div>
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={4} />
            </h3>
            <span className='room_type_price'>
              {/* <p>
                       <span className='price_span'> <Skeleton count={1}/></span> <br /> <i><Skeleton count={1}/></i>{" "}
                      </p>

                      <div className='addRoomDiv'>
                        <Skeleton/>
                      </div> */}
            </span>
          </div>
        </div>
        <Skeleton
          width={50}
          style={{ marginTop: "-10%", marginLeft: ".6rem" }}
        />
      </div>

      <div className='form1'>
        <div className='room_type'>
          <div className='book_img'>
            <Skeleton height={150} />
          </div>
          <div className='namediv'>
            <h3 className='roomNamePlc'>
              <Skeleton count={4} />
            </h3>
            <span className='room_type_price'>
              {/* <p>
                       <span className='price_span'> <Skeleton count={1}/></span> <br /> <i><Skeleton count={1}/></i>{" "}
                      </p>

                      <div className='addRoomDiv'>
                        <Skeleton/>
                      </div> */}
            </span>
          </div>
        </div>
        <Skeleton
          width={50}
          style={{ marginTop: "-10%", marginLeft: ".6rem" }}
        />
      </div>
    </>
  );
};

export default LoadingSkeleton;
