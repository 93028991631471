import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'
import './HotelsT&C.css'
import Addendum from '../../AgreementForm/Addendum'

const HotelsTAndC = () => {
  return (
    <div>
    <NavBar />
    <div className='hotelTC'>
    <div className='tandc' >
        <h1>TIPTOPGO.COM - HOTEL BOOKING TERMS AND CONDITIONS</h1>
        <p>
          <ol>
            <li className='acceptanceTerms'>ACCEPTANCE OF TERMS</li>
            {/* <p></p> */}
              By accessing and using the TiptopGo.com website (the "Site") and its services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use our services.
              {/* <p></p> */}
            <li className='acceptanceTerms'>BOOKING AND RESERVATIONS</li>
              2.1 Booking Confirmation:
              <ul className='ulItems'>
                <li> <span className='tick'>&#10004;</span> All hotel bookings and reservations made through TiptopGo.com are subject to availability.</li>
                <li> <span className='tick'>&#10004;</span> Upon successful booking, you will receive a confirmation email with the details of your reservation. Please review this information carefully.</li>
              </ul>
              2.2 Payment:
              <ul className='ulItems'>
                <li> <span className='tick'>&#10004;</span>  Payment for hotel bookings is processed securely through our payment gateway.</li>
                <li> <span className='tick'>&#10004;</span> Prices displayed on the Site are inclusive of taxes and fees unless stated otherwise.</li>
              </ul>
              2.3 Cancellations and Modifications:
              <ul className='ulItems'>
                <li> <span className='tick'>&#10004;</span>  Cancellation and modification policies vary by hotel. Please review the specific policies provided during the booking process.</li>
                <li> <span className='tick'>&#10004;</span>  TiptopGo.com reserves the right to charge cancellation fees in accordance with the hotel's policy.</li>
              </ul>
              <li className='acceptanceTerms'>USER RESPONSIBILITIES</li>
              3.1 Accuracy of Information:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span>  Users are responsible for providing accurate and complete information during the booking process.</li>
                <li><span className='tick'>&#10004;</span> TiptopGo.com is not responsible for errors or discrepancies resulting from inaccurate information provided by the user.</li>
              </ul>
              3.2 Account Security:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span>  Users are responsible for maintaining the security of their account credentials.</li>
                <li><span className='tick'>&#10004;</span>  Notify TiptopGo.com immediately of any unauthorized access or suspicious activities related to your account.</li>
              </ul>

              <li className='acceptanceTerms'>HOTEL POLICIES AND RESPONSIBILITIES</li>
              4.1 Check-in and Check-out:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span>  Check-in and check-out times are determined by the individual hotels. Please check the specific hotel's policies for details.</li>
              </ul>
              4.2 Hotel Amenities:
              <ul className='ulItems'>
              <li> <span className='tick'>&#10004;</span>  TiptopGo.com provides information about hotel amenities based on the details provided by the hotels. Any discrepancies should be addressed directly with the hotel.</li>
              </ul>
              4.3 Behavior and Conduct:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span>  Users are expected to adhere to the hotel's rules and regulations. TiptopGo.com reserves the right to cancel bookings and deny access to individuals not complying with hotel policies.</li>
              </ul>

              <li className='acceptanceTerms'>INTELLECTUAL PROPERTY</li>
              5.1 Site Content:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span>  All content on the TiptopGo.com website, including text, graphics, logos, and images, is the property of TiptopGo.com and is protected by copyright laws.</li>
              </ul>
              <li className='acceptanceTerms'>LIMITATION OF LIABILITY</li>
              6.1 Disclaimer:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span>  TiptopGo.com is not liable for any damages, losses, or expenses arising from the use of the Site or hotel services, including but not limited to booking errors, cancellations, or changes.</li>
              </ul>
              <li className='acceptanceTerms'>PRIVACY POLICY</li>
              7.1 Data Collection:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span>  User data is collected and processed in accordance with the TiptopGo.com Privacy Policy. By using the Site, you consent to the collection and use of your data as outlined in the Privacy Policy.</li>
              </ul>
              <li className='acceptanceTerms'>AMENDMENTS TO TERMS</li>
              8.1 Changes to Terms:
              <ul className='ulItems'>
                <li>
                TiptopGo.com reserves the right to modify or update these Terms and Conditions at any time. Users are encouraged to review the terms periodically for any changes.
                </li>
              </ul>
              <li className='acceptanceTerms'>GOVERNING LAW</li>
              9.1 Jurisdiction:
              <ul className='ulItems'>
                <li><span className='tick'>&#10004;</span> These Terms and Conditions are governed by the laws of Nigeria, and any disputes arising from the use of the Site will be subject to the exclusive jurisdiction of the courts in Nigeria.</li>

                <li><span className='tick'>&#10004;</span>  By using TiptopGo.com, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</li>
              </ul>
          </ol>
        </p>
        <Addendum/>  
     </div>
     </div>
<Subscribe/>
 <Footer/>
</div>
  )
}

export default HotelsTAndC