import {
  ADD_TO_CART,
  ADJUST_QTY,
  CLEAR_ROOM_CART,
  REMOVE_FROM_CART,
  BREAKFAST_QTY,
  // SET_QTY_ZERO
} from "../Types/room_types";
  import { useSelector } from 'react-redux';
  // import { useState } from 'react';


  
  export const handleAddToCart = (item) => async (dispatch) => {
    
    dispatch({  
      type: ADD_TO_CART,
      payload: item
    });
  };

  
  
  export const handleAdjustQty = (itemId, value, numOfDays) => async (dispatch) => {
    dispatch({
      type: ADJUST_QTY,
      payload: {
        roomTypeId: itemId,
        qty: +value,
        numOfDays: +numOfDays,
      },
    });
  };

  export const handleBreakFastQty = (itemId, value, numOfDays) => async (dispatch) => {
    dispatch({
      type: BREAKFAST_QTY,
      payload: {
        roomTypeId: itemId,
        numOfBreakfast: value,
        numOfDays: +numOfDays,
      },
    });
  };
  
  export const handleRemoveFromCart = (itemId) => async (dispatch) => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: {
        roomTypeId: itemId
      }
    });  
  };

  export const handleClearStore = () => async (dispatch) =>{
    dispatch({
       type: CLEAR_ROOM_CART
  })
  }
  
  // function get cart from store
  export const GetCartData = () => {
    const cart = useSelector((state) => state.cart);
    // if(history.goBack() || window.onpopstate){
    //   dispatch(reset())
    // }
    return cart;
  };
  