import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'
import './TermsOfUse.css'
const TermsOfUse = () => {
  return (
    <div className='termsofuse'>
    <NavBar />
    <div className='termsOfUseDiv'>
    <div className='termsOfUse'>
        <h1>Terms Of Use</h1>
        <h2>Terms and Conditions</h2>

        <p>These terms and conditions apply to all types of services Tiptopgo.com ("Tiptopgo.com", "we", "us", "our" or "ours") offer, both direct or indirect. The terms "you", "your", "yourself", "yours" and "customer" refer to the individual person or corporation who subscribes to our newsletters and offers, creates an account with us or makes a booking (whether complete/incomplete, successful/unsuccessful) through this website. The terms "Final Party" and "Accommodation Provider" refer to the hotel (not limited to organisations that go by 'hotel' only, but also applies to 'motels', 'inns', 'apartments', 'suites' and 'estates') that is advertised on Tiptopgo.com regardless of whether a signed deal between us and them exists or does not.</p> 
        <p>Terms and conditions may be revised and modified occasionally, hence, it is advisable not to familiarise yourself with them at any point in time, but review them each and every time you use any of our services. Services (direct and indirect) apply to everything provided online, through mobile devices and computers of all platforms, by email, telephone, post or through direct contact with our officers. You accept that you have read, understood and agreed to the terms and conditions (as well as our privacy policy) below, if and when you make use of our service in any way, including but not limited to browsing this website, rating or giving hotel reviews, commenting on a review, or making a reservation.</p>

        <h2>Legal Use</h2>

        <p>Your utilization of any of our services warrant that you will not make unlawful use of this website or any contents and materials we provide through other means with which we correspond with you or final parties on your behalf.</p>

        <h2>Our Service Scope</h2>

        <p>Tiptopgo.com provides temporary accommodation of all types, which include motels, hotels, apartments, etc. The provision of these services are found on the website through which you can book for your chosen service. By placing a reservation through us, you enter into a legally binding agreement with the direct service provider (hotel and the likes) with which your reservation is made to. Right after your reservation is placed, Tiptopgo.com acts simply as a mediator between you and the service provider.</p>

        <p>We pass along information you have provided towards placing a reservation unto the final party and relay confirmation messages, updates and invoices back to you via SMS and email for and on their behalf. The accuracy of information provided on the website is exclusive to the service providers who are responsible for updating their accounts with Tiptopgo.com on their current status. Though we aim to provide information about final parties which are as correct as possible, we will not confirm, neither will we guarantee that they are complete or accurate.</p>

        <p>Hotel recommendations on our website are not given by us, we do not verify them and therefore cannot give assurance about them. We will only offer suggestions based on criteria provided by you, but choices made by you are entirely your decision and any disappointments that is met cannot amount to a liability that we would bear.</p>

        <h2>Price Guarantee and Currency Exchange Rates</h2>

        <p>We offer three ways to make payment. There is an option to Pay in Cash on Your Arrival at the hotel, in which case, Tiptopgo.com cannot guarantee that your reservation will be kept for you until you arrive, or that we will inform you about cancellations before you arrive, and we are not responsible for any disappointments that may be faced by choosing this payment method or a hotel that accepts only it. With some hotels, you are only able to make payment for your reservation using this medium.</p>

        <p>We present another option to pay into our locally operated Bank Account; you can pay money in three different ways: if you are not in Nigeria, an international bank transfer can be done, if you are situated anywhere within Nigeria, a local online bank transfer can be done or you may pay money directly into our bank account at any recognised branch of the bank we operate with. </p>

        <p>The third payment option we offer is through the use of ATM Cards. You can pay with your ATM card through a third party link we provide on the website. Tiptopgo.com does not process any other type of card payments, including credit card payments. Any amount charged to you will be in Nigerian Naira. Tax rates and foreign exchange rates could change in the time between booking and stay.</p>

        <h2>Third Parties</h2>

        <p>Backlinks to websites operated by other parties may be found on this website and are provided for reference purposes only. We do not in any way imply an endorsement of the contents and operations on such websites. Tiptopgo.com does not participate in their management and is not responsible for your use of these websites.</p>

        <h2>Cancellation and no-show policy</h2>

        <p>Cancellation policies are two sides of a coin. By booking through Tiptopgo.com you accept both the cancellation and no-show terms of the accommodation provider and ours. Tiptopgo.com will give full refund of amount paid but not yet transferred to the accommodation provider. Refunds of money already paid to the final party will attract their cancellation/no-show charges and also have our delivery/execution of operations cost deducted. If you pay directly to the accommodation provider, Tiptopgo.com will not be accountable for or entitled to reimburse these types of payments and also payments made preceding your arrival at the hotel. Any of such refunds that come through us are only so on request by the accommodation provider and are not in any way our obligation.</p>

        <p>Accommodation providers can only apply cancellation charges to payments already received by them and are fully responsible for payments made directly by clients either on arrival of to electronic means. Tiptopgo.com will not be answerable to any incidence of late or no payment by the customer to the hotel. To that end, we advice all final parties (having a signed deal with us or not) to ensure they provide alternative means of receiving long distance payments, much preferably in the form of a Nigerian Bank Account.</p>

        <h2>Refund policy</h2>

        <p>Refund of payment already paid to your preferred hotel is subject to their cancellation/no show charge. Payment not paid to the hotel at the time of cancellation attracts 100% refund.</p>

        <h2>Delivery and Return Policy</h2>
        <p>Does not apply.</p>

        <h2>Further Correspondence</h2>

        <p>Right after you make a reservation through the website, we will send a confirmation of receipt of this request and send subsequent emails and SMSs to keep you updated on the booking progress. You will most likely confirm your booking over the phone and advice us on how you intend to make payment, if you have not already done so (Please note that some accommodation providers either do not reserve their spaces or cannot guaranty the availability of your desired room/suite/apartment without prepayment).</p>

        <p>Will remain in touch with you from the day you make your reservation, up until a week after you checkout to ensure you had a pleasant experience. We also welcome your feedback on hotels you stay in.</p>

        <h2>Customer Ratings and Reviews</h2>

        <p>Ratings and reviews of hotels are given by the public. Tiptopgo.com has no control over the rating tendencies and we cannot guarantee that the reviews are accurate or true, most of which are solely dependent on the likes and dislikes of the individual.</p>

        <h2>Disclaimer</h2>

        <p>Tiptopgo.com bears no responsibility for any liability that may arise from the booking service, either to the accommodation provider, customer or any government agency. All final parties that offer Pay on Arrival options are liable for any loss they may incure and have to take their own measures to ensure they receive payments from customers.</p>
     </div>
    </div>
    
<Subscribe/>
 <Footer/>
</div>
  )
}

export default TermsOfUse