import Lagos from '../assets/Lagos.jpg'
import abuja from '../assets/Abuja.jpg'
import anambra from '../assets/Anambra.jpg'
import enugu from '../assets/Enugu.jpg'
import benue from '../assets/Benue.jpg'
import imo from '../assets/Imo.jpg'
import Adamawa from '../assets/Adamawa.jpg'
import Bayelsa from '../assets/Bayelsa.jpg'
import Borno from '../assets/Borno.jpg'
import Ebonyi from '../assets/Ebonyi.jpg'
import Kaduna from '../assets/Kaduna.jpg'
import Kano from '../assets/Kano.jpg'
import Kebbi from '../assets/Kebbi.jpg'
import Kogi from '../assets/Kogi.jpg'
import Kwara from '../assets/Kwara.jpg'
import Niger from '../assets/Niger.jpg'
import Ogun from '../assets/Ogun.jpg'
import Ondo from '../assets/Ondo.jpg'
import Osun from '../assets/Osun.jpg'
import Sokoto from '../assets/Sokto.jpg'
import Taraba from '../assets/Taraba.jpg'
import Kastina from '../assets/Kastina.jpg'
// import Bayelsa from '../assets/Bayelsa.jpg'
import Oyo from '../assets/oyo.jpg'
import Rivers from '../assets/Rivers.jpg'
import crossRiver from '../assets/cross-river.jpg'
import Delta from '../assets/Delta.jpg'
import Edo from '../assets/Edo.jpg'
import Akwaibom from '../assets/Akwaibom.jpg'
import Plateau from '../assets/plateau.jpg'


export const HotelsBg = [
    {
        hotelCity: "Anambra",
        // backgroundImg: "src/assets/Anambra.jpg"
        backgroundImg: anambra

    },

    {
        hotelCity: "FCT-Abuja",
        backgroundImg: abuja

    },

    {
        hotelCity: "Benue",
        backgroundImg: benue

    },

    {
        hotelCity: "Enugu",
        backgroundImg: enugu

    },

    {
        hotelCity: "Imo",
        backgroundImg: imo

    },

    {
        hotelCity: "Lagos",
        backgroundImg: Lagos

    },

    {
        hotelCity: "Adamawa",
        backgroundImg: Adamawa

    },

    {
        hotelCity: "Bayelsa",
        backgroundImg: Bayelsa

    },

    {
        hotelCity: "Borno",
        backgroundImg: Borno

    },

    {
        hotelCity: "Ebonyi",
        backgroundImg: Ebonyi

    },

    {
        hotelCity: "Kaduna",
        backgroundImg: Kaduna

    },

    {
        hotelCity: "Kano",
        backgroundImg: Kano

    },

    {
        hotelCity: "Kastina",
        backgroundImg: Kastina

    },

    {
        hotelCity: "Kebbi",
        backgroundImg: Kebbi

    },

    {
        hotelCity: "Kogi",
        backgroundImg: Kogi

    },

    {
        hotelCity: "Kwara",
        backgroundImg: Kwara

    },

    {
        hotelCity: "Niger",
        backgroundImg: Niger

    },

    {
        hotelCity: "Ogun",
        backgroundImg: Ogun

    },

    {
        hotelCity: "Ondo",
        backgroundImg: Ondo

    },

    {
        hotelCity: "Osun",
        backgroundImg: Osun

    },

    {
        hotelCity: "Sokoto",
        backgroundImg: Sokoto

    },

    {
        hotelCity: "Taraba",
        backgroundImg: Taraba

    },
    {
        hotelCity: "Oyo",
        backgroundImg: Oyo

    },
    {
        hotelCity: "Rivers",
        backgroundImg: Rivers

    },
    {
        hotelCity: "Delta",
        backgroundImg: Delta

    },
    {
        hotelCity: "Cross River",
        backgroundImg: crossRiver

    },
    {
        hotelCity: "Akwa Ibom",
        backgroundImg: Akwaibom

    },
    {
        hotelCity: "Edo",
        backgroundImg: Edo

    },
    {
        hotelCity: "Plateau",
        backgroundImg: Plateau

    },

]