import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'

const CancelBooking = () => {
  return (
    <div>
    <NavBar />

<div className='check_parent check_parent2'>
<h1>Cancel Your Booking</h1>
</div>
<Subscribe/>
<Footer/>
</div>
  )
}

export default CancelBooking