

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./utilities/scrollTo";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./Redux/store";
import { SkeletonTheme } from "react-loading-skeleton";
import SimpleReactLightbox from "simple-react-lightbox";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SkeletonTheme>
      <BrowserRouter>
        <ScrollToTop />

        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SimpleReactLightbox>
              <App />
            </SimpleReactLightbox>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </SkeletonTheme>
  </React.StrictMode>
);

reportWebVitals();

