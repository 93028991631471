import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom'
import ReactGA from 'react-ga';
import './App.css';
import Book from './Book/Book';
// import BookRoute from './Book/BookRoute';
import BookSummary from './Book/BookSummary/BookSummary';
import Receipt from './Book/Receipt/Receipt';
// import RoomCounter from './Book/RoomCounter/RoomCounter';
// import Destinations from './HomePage/Destinations/Destinations';
// import Footer from './HomePage/Footer/Footer';
// import GetApp from './HomePage/Get App/GetApp';
// import Hero from './HomePage/Hero/Hero';
import HomeRoute from './HomePage/HomeRoute';
// import NavBar from './HomePage/NavBar/NavBar';
// import PopularCity from './HomePage/Popular city/PopularCity';
// import Search from './HomePage/Search/Search';
// import Subscribe from './HomePage/Subscribe/Subscribe';
// import Todays_deal from './HomePage/Top/Todays_deal';
// import Filters from './Hotels/Filters/Filters';
import HotelsList from './Hotels/HotelsList/HotelsList';
import HotelInfomation from './IndividualHotel/Hotel_Info/HotelInfomation';
import Login from './Register/Login/Login';
// import LoginDashBoard from './Register/LoginDashBoard/LoginDashBoard';
import LoginDashBoard from './Register/LoginDashBoard/DashBoard/LoginDashBoard';
import Register from './Register/Register/Register';  
import AddHotel from './HomePage/Addhotel/AddHotel'; 
import ForgetPassword from './Register/Login/ForgetPassword';
import CheckUser from './Register/Login/CheckUser';
import Verify from './Register/Login/Verify';
import ContactUs from './HomePage/Contact_Us/ContactUs';
import CheckBooking from './HomePage/NavBar/CheckBooking';
import AboutIntel from './HomePage/Footer/company/AboutIntel/AboutIntel';
import AboutTiptop from './HomePage/Footer/company/AboutTiptop/AboutTiptop';
import ListyourHotel from './HomePage/Footer/company/ListYourHotel/ListyourHotel';
import Partnerships from './HomePage/Footer/company/Partnerships/Partnerships';
import Newsroom from './HomePage/Footer/company/Newsroom/Newsroom';
import Advertising from './HomePage/Footer/company/Advertising/Advertising';
import InvestorRelation from './HomePage/Footer/company/InvestorRelations/InvestorRelation';
import PrivacyPolicy from './HomePage/Footer/Policies/PrivacyPolicy/PrivacyPolicy';
import HotelsTAndC from './HomePage/Footer/Policies/HotelsT&C/HotelsT&C';
import TermsOfUse from './HomePage/Footer/Policies/TermsOfUse/TermsOfUse';       
import TipTopGoRewards from './HomePage/Footer/Policies/TipTopGoRT/TipTopGoRewards';
import HotelsRewards from './HomePage/Footer/Policies/HotelsRewards/HotelsRewards';
// import GeneralSupport from './HomePage/Footer/Help/GeneralSupport/GeneralSupport';
import CancelBooking from './HomePage/Footer/Help/CancelBooking/CancelBooking';
import RefundPolice from './HomePage/Footer/Help/RefundPolicies/RefundPolice';
import CheckinID from './HomePage/Footer/Help/CheckinDocument/CheckinID';
import UseCoupon from './HomePage/Footer/Help/UseCoupon/UseCoupon';
import History from './Register/LoginDashBoard/History/History';
import Favourite from './Register/LoginDashBoard/Favorite/Favourite';
import Profile from './Register/LoginDashBoard/Profile/Profile';
import MainDashBoard from './Register/LoginDashBoard/DashBoard/MainDash/MainDash';
import CustomerReview from './Book/CustomerReview/CustomerReview';
import CancelReservation from './Register/LoginDashBoard/CancelReservation/CancelReservation'
import JobsHero from './HomePage/JobsModule/JobHeader/JobsHero';
import Agreement from './HomePage/Footer/Policies/Agreement/Agreement';
import BlogPostHome from './BlogPost/BlogPostHome/BlogPostHome';
import BookingTips from './HomePage/Footer/BookingTips/BookingTips';
import Blog from './BlogPost/NewBlogDesign/Blog';
import CreateBlog from './Register/LoginDashBoard/CreateBlog/CreateBlog';
import AdminBlogPostForm from './Register/LoginDashBoard/CreateBlog/SecondCreateBlogPost';



function App() {
  
  useEffect(() => {
    ReactGA.initialize('G-X3T5WRDP9Q');
    ReactGA.pageview('window.location.pathname');
    // ReactGA.pageview('/book');
  }, []);

  return (
    <>
      <div className='App'>
        <Routes>
          <Route path='/' element={<HomeRoute />} />
          <Route path='/hotels/:seachName/:dates' element={<HotelsList />} />
          <Route
            path='/hotel_info/:hotelId/:string/:hotelName/:mainHotelName'
            element={<HotelInfomation />}
          />
          <Route path='/book' element={<Book />} />
          <Route path='/register' element={<Register />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/receipt' element={<Receipt />} />
          <Route
            path='/customer-review/:bookingCode/:hotelName'
            element={<CustomerReview />}
          />
          <Route path='/LoginDash' element={<LoginDashBoard />}>
            <Route path='main-dashboard' element={<MainDashBoard />} />
            <Route path='userprofile' element={<Profile />} />
            <Route path='favourite-hotel' element={<Favourite />} />
            <Route path='history' element={<History />} />
            <Route path='cancel-reservation' element={<CancelReservation />} />
            {/* <Route path='create-blog-post' element={<CreateBlog />} /> */}
            <Route path='create-blog-post' element={<AdminBlogPostForm />} />
          </Route>

          <Route
            path='/book/booking-summary/:hotelName'
            element={<BookSummary />}
          />
          <Route path='/job-application' element={<JobsHero />} />
          <Route path='/register-hotel' element={<AddHotel />} />
          <Route path='/forget' element={<ForgetPassword />} />
          <Route path='/confirmEmail' element={<CheckUser />} />
          <Route path='/verify' element={<Verify />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/check' element={<CheckBooking />} />
          <Route path='/aboutTiptop' element={<AboutTiptop />} />
          <Route path='/aboutIntel' element={<AboutIntel />} />
          <Route path='/ListHotel' element={<ListyourHotel />} />
          <Route path='/partnership' element={<Partnerships />} />
          <Route path='/newsroom' element={<Newsroom />} />
          <Route path='/investor' element={<InvestorRelation />} />
          <Route path='/advert' element={<Advertising />} />

          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-use' element={<TermsOfUse />} />
          <Route
            path='/hotels-terms-and-conditions'
            element={<HotelsTAndC />}
          />
          <Route path='/TipTopGo-Rewards' element={<TipTopGoRewards />} />
          <Route path='/Hotel-Rewards' element={<HotelsRewards />} />
          <Route path='/hotels-agreement' element={<Agreement />} />

          <Route path='/contact' element={<ContactUs />} />
          <Route path='/cancel-Booking' element={<CancelBooking />} />
          <Route path='/Refund-policy' element={<RefundPolice />} />
          <Route path='/Checkin/ID' element={<CheckinID />} />
          <Route path='/TipTopGo-Coupon' element={<UseCoupon />} />
          <Route path='/tiptopgo-blog' element={<BlogPostHome />} />
          <Route path='/tiptopgo-blog-post' element={<Blog />} />
          <Route path='/tiptopgo-bookingtips' element={<BookingTips />} />
        </Routes>

        {/* <NavBar />
 <Hero />
 <Search/>
 <Destinations/>
 <Todays_deal/>
 <PopularCity/>
 <Subscribe/>
 <GetApp/>
 <Footer/> */}
        {/* <HotelsList/> */}
        {/* <Hotel_info/> */}
        {/* <Book/> */}
        {/* <RoomCounter/> */}
      </div>
    </>
  );
}

export default App;
