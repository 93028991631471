import React from 'react'
import './FrequentlyAsk.css'
import axios from "../../utilities/axios"
import * as Yup from "yup"
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FrequentlyAsk = ({selectedInfo}) => {
  let realDet = selectedInfo;
       
  const notifySuccess = (res, values) => {
    toast.success(res.data.message,{
     position: "top-right",
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     theme: "dark",
   
   })
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
    //   lastName: "",
      emailAddress: "",
      message: "",
      subject: "",
       sourceType: "TiptopgoWeb",
      
    },
    validationSchema: Yup.object({
      firstName: Yup.string().min(3, "first name must be more").required("first name is Required"),
    //   lastName: Yup.string().min(3, "last name is require").required("last name is Required"),
  
    emailAddress: Yup.string().email("invalid email address").required("Email is Required"),
      message: Yup.string().min(6,"Must not be less than 6 characters").required("Message can't be empty"),
  
      subject: Yup.string().max(20,"subject must be 20 characters").required("subject is Required"),
     
    }),
  
    onSubmit: async (values) => {
      // console.log(values)
     // notify()
      await axios.post('/registrations/create-feedBack', values)
      .then(res =>  {
        if(res){     
          notifySuccess(res, values)
        // console.log("res", res)
        }else{
        //   notify(res)
        console.log("error");
        }
       })
      .catch(err => console.log("no connection"))
    
     }
  

   
  })
  
  return (
    <div className="parent_freq">
      <h4 className='freq_header'>Ask any questions about {selectedInfo?.hotelName}</h4>

      <div className='main_question_form'>
        <form className="question_form" onSubmit={formik.handleSubmit}>   
          <h3>Ask a question about {selectedInfo?.hotelName}</h3>
          <p>Typically responds within 24 hours</p>

          <input type="text"  placeholder="FullName" name='firstName' id='firstName' value={formik.values.firstName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          {formik.touched.firstName && formik.errors.firstName ? <p className='error'>{formik.errors.firstName}</p>: null}
          <input type="email" placeholder="Email Address" id='emailAddress' name='emailAddress' value={formik.values.emailAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          {formik.touched.emailAddress && formik.errors.emailAddress ? <p className='error'>{formik.errors.emailAddress}</p>: null}
          <input type="text" placeholder="Subject" name='subject' id='subject' value={formik.values.subject} onChange={formik.handleChange} onBlur={formik.handleBlur} />
          {formik.touched.subject && formik.errors.subject ? <p className='error'>{formik.errors.subject}</p>: null}
          <textarea 
            id="message"
            name="message"
            rows="10"
            cols="50"
            placeholder="message"    
             value={formik.values.message}
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur}
          />
            {formik.touched.message && formik.errors.message ? <p className='error'>{formik.errors.message}</p>: null}
 
          <button>Send Message</button>
        </form>
        <div className='freq_ques_parent'> 

        
        <h2>Do You Wish To Know Anything About {selectedInfo?.hotelName}?</h2>
        <p>Ask and be sure to get response speedily</p>
        {/* <div className="freq_ques">
          <h4>Does {realDet.hotelName} offer free Wi-Fi?</h4>
          <p>No. It doesn’t offer free Wi-Fi.</p>
        </div>

        <div className="freq_ques">
          <h4>Does {realDet.hotelName} have a swimming pool?</h4>
          <p>Yes. It has a swimming pool.</p>
        </div>

        <div className="freq_ques">
          <h4>Does {realDet.hotelName} offer complimentary breakfast?</h4>
          <p>No. It doesn’t offer complimentary breakfast.</p>
        </div>

        <div className="freq_ques">
          <h4>Is there a fitness centre/gym at {realDet.hotelName}?</h4>
          <p>No. There is no fitness centre/gym.</p>
        </div>

        <div className="freq_ques">
          <h4>Does {realDet.hotelName} have a restaurant?</h4>
          <p>Yes. A restaurant is available with various delicacies.</p>
        </div> */}

        {/* {/* <div style={{ height:"34.3em"}} className='FQAImG'>
          <img src='https://img.freepik.com/free-photo/thoughtful-african-american-woman-holding-open-notebook_74855-4749.jpg?size=626&ext=jpg&ga=GA1.2.564374683.1677510160&semt=sph'/>
        </div> */}

        </div> 
        
      </div>
    </div>
  );
}

export default FrequentlyAsk