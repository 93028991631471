import React from "react";
import Footer from "../../HomePage/Footer/Footer";
import NavBar from "../../HomePage/NavBar/NavBar";
import "./BlogPostHome.css";
import featured1 from "../../assets/blogImages/featured/featured-1.jpg";
import feattured2 from "../../assets/blogImages/featured/featured-2.jpg";
import feattured3 from "../../assets/blogImages/featured/featured-3.jpg";
import trending1 from "../../assets/blogImages/trending/trending_1.jpg";
import trending2 from "../../assets/blogImages/trending/trending_2.jpg";
import trending3 from "../../assets/blogImages/trending/trending_3.jpg";
import trending4 from "../../assets/blogImages/trending/trending_4.jpg";
import trending5 from "../../assets/blogImages/trending/trending_5.jpg";
import Blog from "../NewBlogDesign/Blog";
import { Link } from "react-router-dom";
import GetApp from "../../HomePage/Get App/GetApp";
import TrendingPost from "../TrendingPost.jsx/TrendingPost";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const BlogPostHome = () => {
  return (
    <div>
      <NavBar />
      <section className='mainblogPostSection'>
        <div className='parentBlogsect'>
          <div className="parentCardPost">
          <div className='firstGridSection'>
            <div className='firstSectimg_1'>
              <img src={featured1} alt='' />
              <div className="contentContainer">
                <h2 className="contentLabel">Gaming</h2>
                <h1>future of gaming industry</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam recusandae expedita ad commodi dolorum dolore mollitia possimus sequi! Perspiciatis minus voluptatem totam labore, molestias officiis iusto laboriosam est fugit nobis.</p>
              </div>

              <div className="blogpostfooter">
                 <Link to="/tiptopgo-blog-post" className="blogreadMore">
                  more <HiOutlineArrowNarrowRight />
                 </Link>
              </div>
            </div>
            {/* <div className='firstSectimg_2'>
              <img src={feattured2} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Fine dining 101</span>
              </div>
            </div>
            <div className='firstSectimg_3'>
              <img src={feattured3} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Natural fat burner</span>
              </div>
            </div> */}
          </div>

          <div className='firstGridSection'>
            <div className='firstSectimg_1'>
              <img src={feattured3} alt='' />
              <div className="contentContainer">
                <h2 className="contentLabel">Gaming</h2>
                <h1>future of gaming industry</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam recusandae expedita ad commodi dolorum dolore mollitia possimus sequi! Perspiciatis minus voluptatem totam labore, molestias officiis iusto laboriosam est fugit nobis.</p>
              </div>

              <div className="blogpostfooter">
                 <Link to="/tiptopgo-blog-post" className="blogreadMore">
                  more <HiOutlineArrowNarrowRight />
                 </Link>
              </div>
            </div>
            {/* <div className='firstSectimg_2'>
              <img src={feattured2} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Fine dining 101</span>
              </div>
            </div>
            <div className='firstSectimg_3'>
              <img src={feattured3} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Natural fat burner</span>
              </div>
            </div> */}
          </div>

          <div className='firstGridSection'>
            <div className='firstSectimg_1'>
              <img src={featured1} alt='' />
              <div className="contentContainer">
                <h2 className="contentLabel">Gaming</h2>
                <h1>future of gaming industry</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam recusandae expedita ad commodi dolorum dolore mollitia possimus sequi! Perspiciatis minus voluptatem totam labore, molestias officiis iusto laboriosam est fugit nobis.</p>
              </div>

              <div className="blogpostfooter">
                 <Link to="/tiptopgo-blog-post" className="blogreadMore">
                  more <HiOutlineArrowNarrowRight />
                 </Link>
              </div>
            </div>
            {/* <div className='firstSectimg_2'>
              <img src={feattured2} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Fine dining 101</span>
              </div>
            </div>
            <div className='firstSectimg_3'>
              <img src={feattured3} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Natural fat burner</span>
              </div>
            </div> */}
          </div>
          <div className='firstGridSection'>
            <div className='firstSectimg_1'>
              <img src={featured1} alt='' />
              <div className="contentContainer">
                <h2 className="contentLabel">Gaming</h2>
                <h1>future of gaming industry</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam recusandae expedita ad commodi dolorum dolore mollitia possimus sequi! Perspiciatis minus voluptatem totam labore, molestias officiis iusto laboriosam est fugit nobis.</p>
              </div>

              <div className="blogpostfooter">
                 <Link to="/tiptopgo-blog-post" className="blogreadMore">
                  more <HiOutlineArrowNarrowRight />
                 </Link>
              </div>
            </div>
            {/* <div className='firstSectimg_2'>
              <img src={feattured2} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Fine dining 101</span>
              </div>
            </div>
            <div className='firstSectimg_3'>
              <img src={feattured3} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Natural fat burner</span>
              </div>
            </div> */}
          </div>
          <div className='firstGridSection'>
            <div className='firstSectimg_1'>
              <img src={feattured3} alt='' />
              <div className="contentContainer">
                <h2 className="contentLabel">Gaming</h2>
                <h1>future of gaming industry</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam recusandae expedita ad commodi dolorum dolore mollitia possimus sequi! Perspiciatis minus voluptatem totam labore, molestias officiis iusto laboriosam est fugit nobis.</p>
              </div>

              <div className="blogpostfooter">
                 <Link to="/tiptopgo-blog-post" className="blogreadMore">
                  more <HiOutlineArrowNarrowRight />
                 </Link>
              </div>
            </div>
            {/* <div className='firstSectimg_2'>
              <img src={feattured2} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Fine dining 101</span>
              </div>
            </div>
            <div className='firstSectimg_3'>
              <img src={feattured3} alt='' />
              <div className='innfirstscediv'>
                <span>4 dec 2023</span>
                <span>Natural fat burner</span>
              </div>
            </div> */}
          </div>
          </div>
          
          <div className='secondGridSection'>
           <TrendingPost />
          </div>
        </div>
      </section>

      {/* <Blog /> */}
      <GetApp />
      <Footer />
    </div>
  );
};

export default BlogPostHome;
