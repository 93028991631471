import React from 'react'
import './JobsHero.css'
import NavBar from '../../NavBar/NavBar';
import Footer from '../../Footer/Footer';
import GetApp from '../../Get App/GetApp';
import Subscribe from '../../Subscribe/Subscribe';

const JobsHero = () => {
  return (
    <>
      <NavBar />
      <div className='jobHero'>
        <div className='jobHerotext'>
          <div className='jobBanner'>
            {/* <h2>job describtion and details</h2> */}
          </div>

          <div className='jobDescribe'>
            <div className='jobIntroDiv'>
              <p className='jobDescribeDetails'>
                Job vacancies at TIPTOPGO.COM –the only online hotel booking
                site and app you will ever need.
              </p>
              <div className='jobDescribeDetails2'>
                <p>
            
                    <div className='jobCategory'>
                    <p className='customerSer'>1. Customer Service Associate Position: </p>
                    Online hotel booking company is looking for motivated and
                    friendly Customer Service Associate to join their team as a
                    Customer Service Associate. Successful candidates will be
                    responsible for supporting hotel owners/managers, and their
                    customers in a professional manner, including assisting them
                    in the use of our online booking and hotel management
                    software.
                    <p className='customerRespon'>
                      Customer Service Associate duties and responsibilities:
                    </p>
                    <span>
                      <ul>
                        <li>Assisting hotel owners/managers, and their customers with
                      emergency problems or unexpected situations related to
                      online booking.</li>
                      <li>Ensuring customer issues are resolved in a
                      timely manner.</li>
                      <li>Developing and maintaining customer
                      relationships with hotel owners/managers, and their
                      customers.</li>
                      <li> Providing overall great customer experience.
                      Upselling of our different solutions to hotel
                      owners/managers over the phone.</li>
                      </ul>
                        
                    </span>
                    <p className='customerRequire'>
                      Customer Service Associate requirements and qualifications
                    </p>
                    <span>
                      <ul>
                        <li>Minimum of a HND Diploma.</li>
                        <li>Minimum of 3 years of experience
                      in customer service, sales or marketing, or receptionist.</li>
                      <li>Proficiency in MS Office, email, and social media apps.</li>
                      <li>Excellent verbal and written communication skills.</li>
                      <li>Ability to work well both in a team environment and independently.</li>
                      </ul>
                         
                    </span>
                    <p className='customerCV'>
                      Send your CV and cover letter to: jobsTTG@tiptopgo.com
                    </p>
                    </div>
                    <p></p>
                    <div className='salesRep'>
                    <p className='customerSer'>
                      2. Sales Representative 50K - 80K monthly base salary + bonus
                    </p>
                    <p>
                      <p>
                        Are you ready for a career where you can make a real
                        impact while meeting business executives?
                      </p>
                      <span>
                        We are looking for proactive, motivated, and coachable
                        individuals who are interested in getting their foot in
                        the door of the software and hospitality industry. As a
                        Sales Representative, you will be the first point of
                        contact with potential clients through prospecting and
                        creating sales opportunities. To be successful in this
                        position, you must be outgoing and a go-getter who is
                        driven to exceed goals and must be driven for future
                        success in all areas of life. We have found that the
                        right salesperson is one who desires unlimited earning
                        opportunities.
                      </span>

                      <p className='salesRequ'>Responsibilities:</p>

                      <span>
                        <ul>
                          <li>
                            Generate leads and follow up with prospects through
                            our tools, technologies, and lists
                          </li>
                          <li>
                            Conduct product demonstrations live-in-person and
                            online.
                          </li>
                          <li>
                            Arrange and coordinate meetings with clients and
                            company senior executives.
                          </li>
                          <li>
                            Update client databases and maintain a personal log
                            of your contacts.
                          </li>
                          <li>
                            {" "}
                            Hit minimum weekly, monthly, annual productivity and
                            sales goals.
                          </li>
                        </ul>
                      </span>

                      <p className='salesRequ'>What's in it for you:</p>
                      <ul>
                        <li>Competitive pay and performance-based incentives.</li>
                        <li>Flexible work hours.</li>
                        <li>Career advancement opportunities.</li>
                        <li>Free sales training, extensive support, and mentorship program.</li>
                      </ul>

                      <p className='salesLook'>Who are we looking for?</p>
                      <ul>
                        <li>You're self-motivated, persuasive, and results oriented.</li>
                        <li>Computer savvy and capable of learning other computer applications.</li>
                        <li>Exceptional verbal and written communication skills.</li>
                        <li>Flexible scheduling, including evenings and weekends.</li>
                      </ul>
                    </p>
                    <div>
                      <p className='salesRequ'>Experience:</p>
                      <span>Prior sales, marketing, or customer service experiences: </span>

                      <p>Is this that dream job for you? Apply now and secure your spot as a Sales Representative today!</p>

                      <p className='jobType'>Job Type: Full-time</p>

                      <div>
                        <p className='salesRequ'>Schedule:</p>
                        <ul>
                          <li>Evening shift</li>
                          <li>Monday to Friday</li>
                          <li>Weekend availability</li>
                        </ul>
                        <p>Supplemental Pay: Bonus and Commission pay.</p>
                      </div>

                      <div className='customerCV'>Send your CV and cover letter to: jobsTTG@tiptopgo.com</div>
                    </div>
                    </div>
                    
                    
            
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Subscribe />
      <GetApp />
      <Footer />
    </>
  );
}

export default JobsHero