
export const Review = [{
    hotelName : "Residency group of hotels",
   hotelQuestions : [
// {
//     id: '1',
//     question: "On a scale of 1 to 5, how would you rate your experience with the hotel?",
//     options1: "yes", 
//     options2: "No",
//     inputFiled: <input type="text"/>
// },

{
    questionId: '1',
    question: "Were there any aspects of your stay that you found unsatisfactory or would like to see improved?",
    options1: "yes", 
    options2: "No",
    // inputFiled: <input type="text"/>
},

{
    questionId: '2',
    question: "Did you find it convenient to discover and reserve a hotel that met your preferences through tiptopgo.com?",
    options1: "yes", 
    options2: "No",
    // inputFiled: <input type="text"/>
},

{
    questionId: '3',
    question: "Did the staff meet your expectations in terms of friendliness and helpfulness?",
    options1: "yes", 
    options2: "No",
    // inputFiled: <input type="text"/>
},

{
    questionId: '4',
    question: "Was the check-in and check-out process easy? in terms of efficiency and convenience?",
    options1: "yes", 
    options2: "No",
    // inputFiled: <input type="text"/>
}
] 
}]