import React from 'react'
import NavBar from '../../HomePage/NavBar/NavBar'
import { useNavigate } from 'react-router-dom'
import './Login.css'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import axios from '../../utilities/axios';
  // import {BsFillEyeSlashFill} from 'react-icons/bs'
  // import {AiFillEye} from 'react-icons/ai'
  import {FaSignInAlt} from 'react-icons/fa';  
// import { Link } from 'react-router-dom'
import reg from '../../assets/login.png'

const CheckUser = () => {

    // const [showPassword, setShowPassword] = useState(false);
    const navigation = useNavigate();

    
// const setShowPass = () => {
//     setShowPassword(!showPassword)
//   }

    const notify = (res) => {
        toast.success(res.data.message,{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        
        })
      }
      
    //   const notifySuccess = (res,values) => {
    //     toast.success(`${res.data.message} check your email for OTP`,{
    //      position: "top-right",
    //      autoClose: 5000,
    //      hideProgressBar: false,
    //      closeOnClick: true,
    //      pauseOnHover: true,
    //      draggable: true,
    //      progress: undefined,
    //      theme: "dark",
       
    //    })

    // }

    const formik = useFormik({
        initialValues: {
          emailAddress: "",
          phoneNumber: ""
        },
        validationSchema: Yup.object({
          emailAddress: Yup.string().email("invalid email address").required("Email is Required"),
          phoneNumber: Yup.string().max(11, "Must be 11 characters or less")
        }),
      
        onSubmit:  async (values) => {
          // console.log(values)
      
          await axios.post('/registrations/checkUser', values)
          .then((res) => {
            if(res.data.success === false){
             notify(res)
           //console.log("error", res);
            }else{
             // notifySuccess(res, values)
              //notify(res)
            //  console.log("error", res);
             navigation('/verify', {state: {
             response: res.data.guestDetails.otp,
             email: res.data.guestDetails.emailAddress,
             tipTopGoGuestId: res.data.guestDetails.tipTopGoGuestId

             }})
            }
          })
          .catch(err => console.log(" Login unsuccessfull", err))
            
      
          
      
        }
      })
      
  return (
    <div className='parent_login'>
    <NavBar/>
     
{/* <Banner /> */}
<div className='loginSwap'>
    <div className='loginImg'>
      <img src={reg} alt='liquid'/>
    </div>
<div className="containerLogin2">
<form className='login_form' onSubmit={formik.handleSubmit}>
<p>Enter Email <br/>or <br/> Phone Number</p>
<input 
type="email" 
placeholder="email Address" 
value={formik.values.emailAddress}
 onChange={formik.handleChange} 
 onBlur={formik.handleBlur}
 name="emailAddress"
  id='emailAddress'
  />
 
  <br/>
  {formik.touched.emailAddress && formik.errors.emailAddress ? <p className='error'>{formik.errors.emailAddress}</p>: null}
  <div  className='passwordInput'>
<input 
 type='text'
 placeholder="Phone Number"
 value={formik.values.password} 
 onChange={formik.handleChange} 
 onBlur={formik.handleBlur}
 name="phoneNumber" 
 id='phoneNumber'
 />
</div>
 <br/>
 {formik.touched.phoneNumber && formik.errors.phoneNumber ? <p className='error'>{formik.errors.phoneNumber}</p> : null}
 <button type="submit" ><FaSignInAlt className='signinIcon'/>Confirm Email</button><br/>

</form>

<div className="drops">
<div className="drop drop-1"></div>
<div className="drop drop-2"></div>
<div className="drop drop-3"></div>
<div className="drop drop-4"></div>
<div className="drop drop-5"></div>
</div>
</div>
</div>
<ToastContainer />
</div>
  )
}

export default CheckUser