import React from 'react'
import './JobsButton.css'
import { Link } from 'react-router-dom';

const JobsButton = () => {
  return (
    <Link to="/job-application">
     <div className='hiringBtn'>
        <div className='mainText'>    
             We're<span className='hiringTex'> Hiring</span>
        </div>     
    </div>
    </Link>
   
  )
}

export default JobsButton