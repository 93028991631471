import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'
import './CheckinID.css'

const CheckinID = () => {
  return (
    <div>
    <NavBar />
<div className='checkInDIv'>
<div className='checkInMainDiv'>
<h1>Tiptopgo Check-In and ID Documents Policy</h1>

<p>Welcome to Tiptopgo! To ensure a smooth and secure check-in process, please familiarize yourself with our Check-In and ID Documents Policy. By using our services, you agree to comply with the following guidelines:</p>
<p>
1. Check-In Procedures:

<ul>
  <li><span className='tickMark'>&#10004;</span> Guests are required to check in at the designated check-in counter or through our online platform.</li>
  <li><span className='tickMark'>&#10004;</span> A valid reservation confirmation, either in electronic or printed form, is necessary for check-in.</li>
</ul>

2. Identification Requirements:

<ul>
  <li><span className='tickMark'>&#10004;</span> All guests, including adults and children, must present a government-issued photo ID at check-in.</li>
  <li><span className='tickMark'>&#10004;</span> Acceptable forms of ID include passports, national identity cards, and driver's licenses. The ID must be valid and not expired.</li>
</ul>

3. Online Check-In:
<ul>
  <li><span className='tickMark'>&#10004;</span> Tiptopgo offers an online check-in option for a seamless experience. Guests opting for online check-in should follow the provided instructions and ensure that all required information is accurately provided.</li>
</ul>

4. Reservation Verification:
<ul>
  <li><span className='tickMark'>&#10004;</span> Guests may be required to provide the credit card used for the reservation at check-in for verification purposes. If the cardholder is not present, a third-party authorization form may be required.</li>
</ul>

5. Age Requirements:

<ul>
  <li><span className='tickMark'>&#10004;</span> Guests must meet the minimum age requirement specified by the hotel. The primary guest must be at least 18 years old unless otherwise stated by the property.</li>
</ul>

6. Special Requests:

<ul>
  <li><span className='tickMark'>&#10004;</span> Guests with special requests, such as accessibility needs or specific room preferences, are encouraged to communicate these in advance during the reservation process.</li>
</ul>

7. Compliance with Local Laws:

<ul>
  <li><span className='tickMark'>&#10004;</span> Guests must comply with all local laws and regulations. Tiptopgo reserves the right to refuse accommodation to guests who do not adhere to these requirements.</li>
</ul>

8. Privacy and Data Security:

<ul>
  <li><span className='tickMark'>&#10004;</span> Tiptopgo takes guest privacy seriously. Personal information provided during the check-in process is handled in accordance with our Privacy Policy.</li>
</ul>

9. Additional Charges:

<ul>
  <li><span className='tickMark'>&#10004;</span> Guests may be subject to additional charges for incidentals or damages incurred during their stay. These charges will be communicated during the check-in process.</li>
</ul>

10. Check-Out Procedures:

<ul>
  <li><span className='tickMark'>&#10004;</span> Guests are required to check out by the specified time on the departure date. Late check-out requests are subject to availability and additional charges.</li>
</ul>

11. Contact Information:

<ul>
  <li><span className='tickMark'>&#10004;</span> For any questions or concerns related to the Check-In and ID Documents Policy, guests can contact Tiptopgo's customer support at support@tiptopgo.com.</li>
</ul>

<p>Thank you for choosing Tiptopgo. We appreciate your cooperation in adhering to our Check-In and ID Documents Policy, ensuring a safe and enjoyable stay for all our guests.</p>
</p>

</div>
</div>

<Subscribe/>
<Footer/>
</div>
  )
}

export default CheckinID