import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "./HotelBooking.css";
import axios from "../../utilities/axios";
import RoomCards from "./RoomCards";
import {
  handleAddToCart,
  handleAdjustQty,
  handleClearStore,
  GetCartData,
} from "../../Redux/Actions/room_actions";
import { GetCurrencyData } from "../../Redux/Actions/currency_actions";
import { convertAmount } from "../../utilities/GeneralFunctions";
import { connect } from "react-redux";
import hotelplace from "../../assets/hotelplace.webp";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LoadingSkeleton from "./LoadingSkeleton/LoadingSkeleton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD : HH:mm:ss";

const HotelBooking = ({
  selectedInfo,
  totalBills,
  selectedHotel,
  handleClearStore,
}) => {
  // let dates2 = selectedInfo.allResult2
  const [selectRoom, setSelectRoom] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [count, setCount] = useState(1);
  const [individualHotel, setIndividualHotel] = useState([]);
  const [roomtype, setRoomType] = useState();
  // const [dates, setDates] = useState([]);
  const [allhotelList, setAllhotelList] = useState([]);
  const [allRooms, setAllRoom] = useState([]);
  const [days, setDays] = useState(0);
  const [price, setPrice] = useState();
  const [buttonId, setButtonId] = useState(-1);
  const [quantity, setQuantity] = useState();
  const [disableBtn, setDisableBtn] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [available, setAvailable] = useState(false);
  const [dataFromChild, setDataFromChild] = useState("");
  const [selectRoomchild, setSelectRoomChild] = useState(false);
  // const [totalRoomPriceSum, setTotalRoomPriceSum] = useState(0);
  const [isStriked, setIsStriked] = useState(false);
  // const [showAll, setShowAll] = useState(false);
  const [expandedRooms, setExpandedRooms] = useState({}); // Track expanded state per room
  const [showAll, setShowAll] = useState(false); // Track whether all rooms are shown

  let realDet = selectedInfo;
  let realDet2 = selectedInfo?.hotelId;
  let genHotelname = selectedInfo?.hotelName;
  let percentageDiscount = selectedInfo?.tiptopGoCommission;
  let hotelHasFlatRate = selectedInfo?.hotelHasFlatRate;
  const storedDates = JSON.parse(localStorage.getItem("dateRange")) || [
    dayjs().toISOString(), // Today
    dayjs().add(1, "day").toISOString(), // Tomorrow
  ];

  const dates2 = [storedDates[0], storedDates[1]];
  let dates = dates2;

  //  useEffect(() => {
  //    setDates(dates2);
  //  }, []);

  const handleDataFromChild = (breakfastFlatCost) => {
    setDataFromChild(breakfastFlatCost);
  };

  let hotelList = [];
  const navigation = useNavigate();
  const roomQty = GetCartData();

  useEffect(() => {
    if (realDet?.showInBookSite === "true" && realDet?.allowRooms === "true") {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [selectedInfo]);

  const totalRoomPriceSum = useMemo(() => {
    return roomQty.reduce((sum, item) => {
      const price = realDet?.hotelHasFlatRate
        ? item.newKey
        : item.totalRoomPrice
        ? item.totalCost
        : item.newKey;

      return sum + price * item.qty + (item.actualBreakfastCost || 0);
    }, 0);
  }, [roomQty, realDet]);

  const totalAmount = totalRoomPriceSum * days;

  // console.log("totalRoomPriceSum ==> wwee", totalAmount);

  // const onChangeHandler = (e, room) => {
  //   setQuantity(e.target.value);
  //   handleAdjustQty(room.roomTypeId, e.target.value);
  // };

  useEffect(() => {
    if (dates.length === 2) {
      const date_1 = new Date(dates[0]);
      const date_2 = new Date(dates[1]);

      const calculateDays = (start, end) => {
        const difference = end.getTime() - start.getTime();
        return Math.floor(difference / (1000 * 3600 * 24));
      };

      const totalDays = calculateDays(date_1, date_2);
      setDays(totalDays);
    }
  }, [dates]);

  useEffect(() => {
    // handleClearStore();
  }, []);

  let roomQties = roomQty.length;
  let hasError = roomQty.map((roomCart, i) => roomCart.hasError);

  const addRoom = (roomTypeId) => {
    setCount(count + 1);
  };

  const removeRoom = () => {
    setCount(count - 1);
  };

  let HandleRoomNo = (room, roomTypeId) => {
    setSelectRoom(!selectRoom);
    setShowButton(!showButton);

    getRoomId(room);
    setButtonId(roomTypeId);
    getBookingDetails();
  };

  const getRoomId = (room) => {
    const newRoomId = room.roomTypeId;
    const newRoomVar = room.minimumPrice * days;
    setPrice(newRoomVar);
  };

  useEffect(() => {
    const getSelectedHotel = async () => {
      setIsLoading(true);
      const allhotelLists = await axios.get(
        `/registrations/hotels?sourceType=TiptopgoWeb&genFilter=${genHotelname}`
      );

      // console.log("allhotelLists", allhotelLists);
      setAllhotelList(allhotelLists && allhotelLists.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    };
    getSelectedHotel();
    // console.log("axios", axios);
  }, [genHotelname]);

  const checkRoomAvailability = () => {
    hotelList = allhotelList.filter((e) => e.hotelId === realDet2);
    handlePullRooms();
  };

  const handlePullRooms = async () => {
    setIsLoading(true);
    const authToken = "internal";
    const breakFast = await axios.get(`/hotels/${realDet2}/breakfasts`, {
      headers: {
        "x-access-token ": ` ${authToken}`,
      },
    });
    let breakFastObj = breakFast.data[0];
    let selectedDateArr;

    let defDate2 = dates2[0];
    const startDate = dayjs(dates[0]).format("YYYY-MM-DD HH:mm:ss");
    const endDate = dayjs(dates[1]).format("YYYY-MM-DD HH:mm:ss");
    // console.log("dates", startDate, "endDate", endDate);
    if (!dates || dates.length === 0) {
      let today = startDate;
      let tomorrow = endDate;
      let tom = moment(tomorrow.setDate(today.getDate() + 1)).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      // setDates([defDate2, tom]);
      dates = [today, tomorrow];
      selectedDateArr = [today, tomorrow];
    } else {
      let today = startDate;
      let tomorrow = endDate;
      let dateDefault = [today, tomorrow];
      selectedDateArr = dateDefault;
      // setDates(selectedDateArr);
      dates = selectedDateArr;
    }
    // console.log("selectedDateArr", selectedDateArr);
    await axios
      .get(
        `/hotels/${realDet2}/roomAvailabilitySummary?startDate=${selectedDateArr[0]}&endDate=${selectedDateArr[1]}&sourceType=TiptopgoWeb`
      )
      .then((res) => {
        // console.log("res back end", res);
        if (res.data.length > 1) {
          setIsLoading(false);
          // console.log("rooms", rooms);
          const combinedRooms = [];

          for (const room of res.data) {
            const [roomImage] = hotelList[0].roomTypeImages.filter(
              (hotel) => hotel.roomTypeId == room.roomTypeId
            );
            if (roomImage) {
              combinedRooms.push({
                ...room,
                ...roomImage,
                ...breakFastObj,
                discountPrice: roomImage.price,
                percentageDiscount,
                hotelHasFlatRate,
                taxLevies: hotelList[0].taxLevies,
                hasTaxLevies: hotelList[0].taxLevies.length > 0 ? true : false,
              });
            } else {
              combinedRooms.push({
                ...room,
                ...breakFastObj,
                roomTypePicture: null,
                percentageDiscount,
                hotelHasFlatRate,
                taxLevies: hotelList[0].taxLevies,
                hasTaxLevies: hotelList[0].taxLevies.length > 0 ? true : false,
              });
            }
          }
          if (realDet.hotelHasFlatRate === true) {
            const newArrayOfRoom = combinedRooms.map((obj) => {
              if (realDet.hotelHasFlatRate === true) {
                const commissionBonus =
                  (obj.roomCost * obj.percentageDiscount) / 100 / 2;
                const commissionDiscountAmount = commissionBonus || 0;
                const newKey = obj.roomCost - commissionBonus || 0;
                return {
                  ...obj,
                  newKey,
                  commissionDiscountAmount,
                };
              }
            });
            setIsStriked(true);
            setAllRoom(newArrayOfRoom);
          } else {
            setIsStriked(false);
            setAllRoom(combinedRooms);
          }
        }
      })
      .catch((err) => {
        console.log("there is error", err);
      });
  };

  useEffect(() => {
    const onLoad = () => {
      hotelList = allhotelList.filter((e) => e.hotelId == realDet2);
    };

    onLoad();
    handlePullRooms();
  }, [allhotelList]);

  useEffect(() => {
    const getPrice = () => {
      // console.log("allroom test", allRooms)
      allRooms.map((price, i) => {
        const price2 = price.minimumPrice;
        const total = price2;
        let total2 = total * days * count;
        setPrice(total2);
      });
    };
    getPrice();
  }, []);

  const getBookingDetails = () => {
    const cartDetails = [count, days, price];

    // console.log("newcart", cartDetails)
  };

  const notify1 = () => {
    toast.error("Quantity greater than available Rooms", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const notify2 = () => {
    toast.error("Please select atleast one room", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  let allowed = true;

  const generateRandomCode = (length) => {
    const characters = "0123456789";
    let code = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  let dateArr = [];
  const checkinDate = storedDates[0];
  const checkOutDate = storedDates[1];
  dateArr.push(checkinDate, checkOutDate);

  const condition = () => {
    if (roomQty.length < 1) {
      notify2();
    }

    if (totalAmount > 0 && roomQties > 0) {
      if (!hasError[0]) {
        allowed = true;

        // const newDate = moment(dates[0]).format("YYYY-MM-DD");
        // const newDate2 = moment(dates[1]).format("YYYY-MM-DD");
        // dateArr.push(newDate, newDate2);

        const replaceSpacesWithDashes = (str) => {
          return str.replace(/\s+/g, "-");
        };
        const randomCode = generateRandomCode(5);
        const hotelId = selectedInfo.hotelId;
        const hotelName = selectedInfo.hotelName;
        const replacedString = replaceSpacesWithDashes(hotelName);

        navigation(`/book`, {
          state: {
            totalAmount,
            days,
            selectedInfo,
            dateArr,
          },
        });
      } else {
        allowed = false;
        notify1();
      }
    } else {
      allowed = false;
    }
  };

  const setStateBack = () => {
    setSelectRoomChild(false);
  };

  // const toggleViewMore = () => {
  //   setShowAll(!showAll);
  // };
  const isSearchDisabled = dates.length !== 2;

  const getSelectedCurrency = GetCurrencyData();
  const selectedCurrencyObject = getSelectedCurrency.selectedCurrency;
  const { currencySymbol, currencyRate, currencyCode } = selectedCurrencyObject;

  const toggleExpand = (roomId) => {
    setExpandedRooms((prev) => ({
      ...prev,
      [roomId]: !prev[roomId], // Toggle specific room's expansion state
    }));
  };

  const toggleViewMore = () => {
    setShowAll((prev) => !prev); // Toggle between showing 4 or all rooms
  };

  const memoizedTotalAmount = useMemo(
    () => totalRoomPriceSum * days,
    [totalRoomPriceSum, days]
  );

  // console.log("dateArr", dateArr);

  useEffect(() => {
    const storedDates = localStorage.getItem("dateRange");
    if (storedDates) {
      const parsedDates = JSON.parse(storedDates);
      dates = parsedDates.map((date) => dayjs(date, dateFormat));
    }
  }, []);

  const handleDateChange = (valm) => {
    if (valm) {
      const formattedDates = valm.map((item) => dayjs(item).format(dateFormat));
      // setDates(formattedDates);
      dates = formattedDates;
      handleClearStore();
      setStateBack();
      localStorage.setItem("dateRange", JSON.stringify(formattedDates));
    }
  };

  const getStoredDateRange = () => {
    const storedRange = localStorage.getItem("dateRange");

    // Check if there's a stored value, else return null
    if (storedRange) {
      const [checkIn, checkOut] = JSON.parse(storedRange);
      return [dayjs(checkIn, dateFormat), dayjs(checkOut, dateFormat)];
    }
    return null; // If no dates are found, return null
  };

  const defaultDates = getStoredDateRange();

  return (
    <>
      <div className='parent_hotel_book'>
        {/* {
          roomtype && roomtype.map
        } */}
        <h2> {realDet?.hotelName}</h2>
        <div className='form'>
          {/* <span>{dates2}  {realDet.hotelId}</span> */}
          <div className='form1'>
            {/* <RangePicker
              className='pickDate pickDate2'
              allowClear={false}
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
              defaultValue={defaultDates}
              onChange={(valm) => {
                // const newDates = convertDateTimeToOBj(valm);
                handleClearStore();
                setStateBack();
                // console.log("dates", dates)
                setDates(
                  valm.map((item) => {
                    const selectedDate = new Date(item);
                    return moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
                  })
                );
              }}
            /> */}

            <RangePicker
              className='pickDate pickDate2'
              allowClear={false}
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
              placeholder={["CheckIn date", "CheckOut date"]}
              defaultValue={defaultDates}
              onChange={handleDateChange}
            />

            <button
              className='roomCheck'
              onClick={checkRoomAvailability}
              disabled={isSearchDisabled}
            >
              Check Room
            </button>
          </div>

          <div className='total'>
            <h3>
              {roomQties} {roomQties > 1 ? "Room Types" : "Room Type"} , {days}{" "}
              {days > 1 ? "Days" : "Day"}
            </h3>

            <p className='totalPrice'>
              Total Price: {currencySymbol || "₦"}
              {convertAmount(
                totalAmount,
                currencyRate,
                currencyCode
              ).toLocaleString()}
            </p>

            {disableBtn ? (
              <button
                className='Book_now'
                onClick={condition}
                // disabled={allowed ? false : true}
                disabled
              >
                Select Room to book
              </button>
            ) : (
              <p className='cant__bok'>
                This hotel can not take booking at the moment
              </p>
            )}
          </div>

          {/* <div className="form1">
            {/* <label>Enter your phone number to start the booking process</label> */}
          {/* <div className="enter_num">
              <select>
                <option placeholder="country">select country</option>
                <option placeholder="country">Nigeria</option>
                <option placeholder="country">USA</option>
                <option placeholder="country">England</option>
              </select>
              <input type="number" placeholder="1" />
            </div>
          </div>
          {showButton && (
               <div className='enter_num_button'>
               <Link to='/book'>
                 <button>Book Now</button>
                 </Link>
                 </div>
          )}  */}

          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            allRooms.slice(0, showAll ? allRooms.length : 4).map((room) => {
              const isExpanded = expandedRooms[room.roomTypeId] || false;
              const shouldShowReadMore = room?.shortDescription.length > 50;

              return (
                <div className='form1' key={room.roomTypeId}>
                  <div className='room_type'>
                    <div className='book_img'>
                      <img
                        src={
                          room.roomTypePicture === null
                            ? hotelplace
                            : room.roomTypePicture
                        }
                        alt='room'
                      />
                    </div>

                    <div className='namediv'>
                      <div className='roomNamePlc'>
                        <span className='nameQty'>{room.roomTypeName}</span>
                        <hr className='horizon' />
                      </div>

                      <span className='room_type_price'>
                        <p className='monyDiv'>
                          <span className='payOpt'>
                            <span className='textOpt'>pay at hotel</span>
                            <span
                              className={
                                isStriked ? "flatRatePrice" : "price_span"
                              }
                            >
                              {currencySymbol || "₦"}
                              {convertAmount(
                                room.roomCost,
                                currencyRate,
                                currencyCode
                              ).toLocaleString()}
                            </span>
                            {!isStriked && <i>avg/night</i>}
                          </span>

                          {isStriked && (
                            <span className='payOpt2'>
                              <span className='textOnline'>pay online</span>
                              <span className='price_span'>
                                {currencySymbol || "₦"}
                                {convertAmount(
                                  room.newKey,
                                  currencyRate,
                                  currencyCode
                                ).toLocaleString()}
                              </span>
                              <i>avg/night</i>
                            </span>
                          )}
                        </p>

                        {buttonId === room.roomTypeId ? null : (
                          <div className='addRoomDiv'>
                            {disableBtn ? (
                              <RoomCards
                                // condition={condition}
                                dateArr={dateArr}
                                totalAmount={memoizedTotalAmount}
                                selectedInfo={selectedInfo}
                                roomData={room}
                                days={days}
                                onData={handleDataFromChild}
                                selectRoom2={selectRoomchild}
                                selectRoom2Setter={setSelectRoomChild}
                              />
                            ) : (
                              <button className='disableBtn'>
                                Select Room
                              </button>
                            )}
                          </div>
                        )}
                      </span>

                      {/* Short Description with "Read More" logic */}
                      <div className='descriptionDiv'>
                        <div
                          className={
                            isExpanded ? "full-text" : "truncated-text"
                          }
                        >
                          {room?.shortDescription}
                        </div>
                        {shouldShowReadMore && (
                          <span
                            className='read-more'
                            onClick={() => toggleExpand(room.roomTypeId)}
                          >
                            {isExpanded ? "Read less" : "Read more"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}

          {/* View More/View Less Button */}
          {allRooms && allRooms.length > 4 && (
            <button onClick={toggleViewMore} className='bounce'>
              {showAll ? (
                <>
                  View Less Rooms <br /> <i className='fas fa-arrow-up'></i>
                </>
              ) : (
                <>
                  View More Rooms <br /> <i className='fas fa-arrow-down'></i>
                </>
              )}
            </button>
          )}

          <ToastContainer />
          <div className='total'>
            <h3>
              {roomQties} {roomQties > 1 ? "Rooms" : "Room"} , {days}{" "}
              {days > 1 ? "Days" : "Day"}
            </h3>

            <p className='totalPrice'>
              Total Price: {currencySymbol || "₦"}
              {convertAmount(
                totalAmount,
                currencyRate,
                currencyCode
              ).toLocaleString()}
            </p>

            {disableBtn ? (
              <button
                className='Book_now'
                onClick={condition}
                // disabled={allowed ? false : true}
                disabled
              >
                {/* Book Now */}
              </button>
            ) : (
              <p className='cant__bok'>
                This hotel can not take booking at the moment
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const calcQuickTotal = (items) => {
  if (items) {
    return items.reduce((a, b) => {
      if (b.discountRate) {
        return a + b.qty * b.price; // NB: b.discountPrice
      } else {
        return a + b.qty * b.price;
      }
    }, 0);
  }
  return 0;
};

const mapStateToProps = (state) => {
  const cart = state.cart;
  return {
    totalBills: calcQuickTotal(state.cart),
    cart,
  };
};

export default connect(mapStateToProps, {
  handleAddToCart,
  handleAdjustQty,
  handleClearStore,
})(HotelBooking);
