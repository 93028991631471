import React from 'react'
import './BookingModal.css'

const BookingModal = ({closeModal}) => {
  return (
    <div className='bookingModalParent'>
        <div className='bookingModalDiv'>
            <div className='cancelBtnDiv'><button onClick={closeModal}>X</button></div>
        
            <p className='modalText'>oops!!, some of the selected quantity exceeds our availability. 
                    Please adjust your reservation.</p>
            
        </div>

    </div>
  )
}

export default BookingModal