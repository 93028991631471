import React from "react";
import NavBar from "../../../NavBar/NavBar";
import Subscribe from "../../../Subscribe/Subscribe";
import Footer from "../../Footer";
import "./TipTopGoRewards.css";

const TipTopGoRewards = () => {
  return (
    <div className="RewardMain">
      <NavBar />
      <div className="RewardsTermDiv">
      <div className='RewardsTerm'>
        <h1>TipTopGo.com Rewards Terms</h1>

        <h2>Rewards, Credits & Wallet</h2>

        <ol>
          <li>
            We may issue Rewards to you – at our sole discretion, and subject to
            (a) the terms here in this section and (b) any Individual Reward
            Criteria that apply. If we make a clerical error (i) in calculating
            your Rewards or (ii) in converting currencies related to your
            Rewards, we can always change or correct any balances shown.
          </li>
          {/* <p></p> */}
          <li>How to get Rewards. You might, for example, get a Reward by booking at a participating hotel, or by making a certain number of Bookings in a given time period. When a Reward is available, we’ll explain what the specific conditions are and how to use it.</li>
          <li> Where to find your Rewards. Once you’ve received one or more Rewards, you’ll find a ‘Rewards’ link in your Tiptopgo.com Account menu. Under the ‘Rewards’ tab, you’ll see all the Rewards you’ve earned, what actions (if any) still need to be taken to obtain the Reward(s), and any other terms and conditions.</li>
          <li>Types of Rewards. Rewards give you (a) Credits in your Wallet or (b) something different (e.g. a Credit Card Cashback, or a voucher). We’ll explain each Reward at the appropriate time.</li>
          <li>To receive any type of Rewards, you must, when qualifying for and using the Credits:</li>
          <ul>
            <li>have an Account with us</li>
            <li>be at least 18 years old</li>
            <li>meet the Individual Reward Criteria</li>
            <li>not violate the Rewards & Wallet Terms, and</li>
            <li>have a valid credit card, to qualify for Credit Card Cashback Rewards.</li>
          </ul>
          <p></p>
          <li>When a Reward is available, the Individual Reward Criteria will explain how (and if) you can qualify for it. There may be:</li>
          <ul>
            <li>time-sensitive restrictions (e.g. offers with expiry dates)</li>
            <li>platform restrictions (e.g. promotional codes that can only be used in our app)</li>
            <li>property restrictions (e.g. offers that can only be used with specific Service Providers)</li>
            <li>A minimum spend (e.g. a Reward that you’ll only earn when you spend at least a certain amount on a Booking)</li>
            <li>A maximum Reward value (for either monetary or non-monetary Rewards).</li>
          </ul>
          <p></p>
          <li> Rewards cannot be sold, encumbered or transferred in any way to a third party. In the event of an Account holder’s death, their Account will be closed and their Rewards (if any) will be cancelled.</li>
          <li>. If a Reward was issued because you booked a accomodtion, any associated Credits that haven’t been used will be deleted from your Wallet if that accomodation is cancelled.</li>

          <li>We reserve the right to cancel any Reward that was obtained by fraud.</li>
          <li>If you think you’ve not received a Reward that you should have, please contact our Customer Service team no more than 12 months after you did whatever it was that you believe qualified you for it. Please provide any supporting documentation you have. If you don’t do this within 12 months, you won’t be able to claim the Reward.</li>

          <li> All Credits have an expiration date, which you’ll find in the ‘Credits’ section of your Wallet.</li>

          <li>All data, including personal data, will be processed in accordance with our privacy policy and applicable data protection laws and regulations. It will be shared with Group Companies or Service Providers as required by the Wallet programme. Lost, stolen or expired Rewards will not be replaced.</li>

          <li>Your obligations:</li>

          <ul>
            <li>You’re responsible for making sure that all information is (and stays) correct, complete and up to date</li>
            <li>If we ask you for proof of ID, please provide it within 30 days</li>
            <li>You’re responsible for keeping your Wallet sign-in details safe and secure.</li>
          </ul>
          <p></p>
          <li> If you don’t adhere to the rules in this section, we may automatically suspend or cancel your Wallet.</li>

          <li>You may not use your Wallet or Rewards in any way that is misleading, unfair or harmful.</li>
          <li>We may set off/settle any or all of your Credits against any claim we (or a Group Company) have against you. We may do this at any time, and without advance notice.</li>
          <li>We may change, suspend or end any aspect of the Wallet and rewards. In particular, we might change:</li>
          <ul>
            <li>these Rewards & Wallet terms</li>
            <li>which users we allow to have a Wallet</li>
            <li>which Rewards or Credits we provide</li>
            <li>the expiration dates of any Rewards or Credits</li>
            <li>any Individual Reward Criteria.</li>
          </ul>
          <p></p>
            <li>We will make reasonable efforts to give you prior notice if we make any changes or stop providing Wallets altogether.</li>
            <li>If we stop providing Wallets, all Credits and Rewards that haven’t expired will be valid for another 12 months.</li>
        </ol>
      </div>
      </div>
      
      <Subscribe />
      <Footer />
    </div>
  );
};

export default TipTopGoRewards;
