import React, { useState } from "react";
import NavBar from "../../../NavBar/NavBar";
import Subscribe from "../../../Subscribe/Subscribe";
import Footer from "../../Footer";
import { FaUnlock } from "react-icons/fa";
import { FaPenFancy } from "react-icons/fa";
import * as Yup from "yup";
import axios from "../../../../utilities/axios";
import { ContractCodeLoader } from "../../../../utilities/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik, Formik, Field, Form } from "formik";
import "./Agreement.css";
import AgreementFormFirstPage from "../../AgreementForm/AgreementFormFirstPage";

const Agreement = () => {
  const [isValidCode, setIsValidCode] = useState(false);
  const [hotelResponse, setHotelResponse] = useState();
  const [message, setMessage] = useState("");
  const [constractCode, setContractCode] = useState("")

  const hideForm = () => {
    setIsValidCode(false);
  };

  const notifySuccess = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const notify = (res) => {
    toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const formik = useFormik({
    initialValues: {
      contractCode: "",
      actionType: "checkCode",
    },
    validationSchema: Yup.object({
      contractCode: Yup.string()
        .max(6, "contract code must be 6")
        .required("contract code is Required"),
    }),

    onSubmit: async (values) => {
      // console.log("values", values);
      setContractCode(values);
      await axios
        .put("/hotels/confirmElectronicCode", values)
        .then((res) => {
          if (res.data.success === true) {
            setIsValidCode(true);
            notifySuccess(res);
            setMessage(res.data.message);
            setHotelResponse(res.data.checkEcode);
            // console.log("res", res)
          } else if (res.data.message === "code has already been used") {
            notify(res);
            setIsValidCode(true);
            // console.log("res", res)
            setHotelResponse(res.data.getUsedContracDetails);
            setMessage(res.data.message);
          } else {
            notify(res);
            setIsValidCode(false);
            // console.log("res", res)
          }
        })
        .catch((err) => console.log(err));
    },
  });

  return (
    <div>
      <NavBar />
      <div className='agreementParent'>
        <div className='sideImageDiv'></div>
        <div className='holderDiv'>
          <div className='formDiv'>
            <h3 className={isValidCode ? "newheadpartform" : "headpartFrom"}>
              AGREEMENT BETWEEN <br /> HOTEL AND TIPTOPGO.COM{" "}
              <span className='agreePen'>
                <FaPenFancy className='penIcon' />
              </span>
            </h3>
            {isValidCode ? (
              <AgreementFormFirstPage
                hideForm={hideForm}
                hotelResponse={hotelResponse}
                errorMessage={message}
                constractCode={constractCode}
              />
            ) : (
              <>
                <div className='mainAgreeDiv'>
                  <form className='agreeDiv' onSubmit={formik.handleSubmit}>
                    <input
                      type='text'
                      name='contractCode'
                      id='contractCode'
                      value={formik.values.contractCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder='please enter your 6 digit contract code '
                      // className='agreementCode'
                      className={
                        formik.touched.contractCode &&
                        formik.errors.contractCode
                          ? "agreementCode2"
                          : "agreementCode"
                      }
                    />

                    <button className='agreementButton' type='submit'>
                      <span className='unlock'>
                        <FaUnlock />
                      </span>
                      unlock Form
                    </button>
                  </form>
                  {formik.touched.contractCode && formik.errors.contractCode ? (
                    <p className='error'>{formik.errors.contractCode}</p>
                  ) : null}
                </div>
                {/* <ContractCodeLoader /> */}
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Agreement;
