import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../utilities/axios";
import moment from "moment";
// import NavBar from "../../../HomePage/NavBar/NavBar";
// import Subscribe from "../../../HomePage/Subscribe/Subscribe";
// import Footer from "../../../HomePage/Footer/Footer";
// import GetApp from "../../../HomePage/Get App/GetApp";
import { MdFavorite } from "react-icons/md";
// import SideBar from "../SideBar/SideBar";
import { Loader } from "../../../utilities/Loader";
import "./Favourite.scss";

const Favourite = () => {
  const [hotels, setHotels] = useState([]);

  // const [inputValue, setInputValue] = useState([]);

  // const [inputValue2, setInputValue2] = useState([]);

  const [load, setLoad] = useState(false);

  // let history = useLocation();

  const navigation = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));

  // const guestId = userData.guestId

  const tipTopGoGuestId = userData.tipTopGoGuestId;

  const getFavouriteCount = async () => {
    setLoad(true);
    const favouriteDet = await axios.get(
      `/registrations/getGuestFavoriteHotels/${tipTopGoGuestId}`
    );
    // console.log()
    setHotels(favouriteDet.data);
    setLoad(false);
  };

  const removeFavHotel = async (hotel) => {
    await axios.post("registrations/addFavoriteHotel", {
      hotelId: hotel.hotelId,
      guestId: userData.guestId,
      tipTopGoGuestId: userData.tipTopGoGuestId,
      type: "unlike",
      sourceType: "TiptopgoWeb",
    });

    getFavouriteCount();
    // setAddFav(true)
  };

  useEffect(() => {
    getFavouriteCount();
  }, []);

  // const getNewValue = (e) => {
  //   let newVal = e.target.value;
  //   setInputValue(newVal);

  //   for (const hotel of hotels) {
  //     setInputValue2(hotel.hotelName);

  //     if (hotel.hotelName === inputValue) {
  //       inputValue2.filter();
  //     }
  //   }

  //   // if(inputValue === )
  // };

  const defDate = new Date();
  let defDate2 = moment(defDate).format("YYYY-MM-DD");

  const today = new Date();
  let tomorrow = new Date();
  let tom = moment(tomorrow.setDate(today.getDate() + 1)).format("YYYY-MM-DD");

  const joinDates = [defDate2, tom];

  // const passInf2o = (hotel) => {
  //   const { hotelId, hotelName } = hotel;
  //   // console.log('{hotelId, hotelName', hotelId, hotelName);
  //   navigation("/hotel_info", {
  //     state: {
  //       indivHotel: hotel,
  //       allResult2: joinDates,
  //       hotelId,
  //       hotelName,
  //     },
  //   });
  // };

  const generateRandomCode = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const passInfo = (hotel) => {
    const replaceSpacesWithDashes = (str) => {
      return str.replace(/\s+/g, "-");
    };
    const randomCode = generateRandomCode(20);
    const hotelId = hotel.hotelId;
    const hotelName = hotel.hotelName;
    const replacedString = replaceSpacesWithDashes(hotelName);

    navigation(
      `/hotel_info/${hotelId}/${randomCode}/${replacedString}/${hotelName}`,
      {
        state: {
          indivHotel: hotel,
          allResult2: joinDates,
        },
      }
    );
  };
  return (
    <div className='newcardFav'>
      {load ? (
        <Loader />
      ) : (
        <div className='card-list'>
          {hotels &&
            hotels.map((hotel) => (
              <article
                className='card3'
                key={hotel.hotelId}
                onClick={() => passInfo(hotel)}
              >
                <div className='card-image'>
                  <img src={hotel.picture} alt='' />
                </div>
                <div className='card-header'>
                  <span>{hotel.hotelName}</span>
                  <button className='icon-button'>
                    <MdFavorite
                      className='favIcon'
                      onClick={() => removeFavHotel(hotel)}
                    />
                  </button>
                </div>
                <div className='card-footer'>
                  <div className='card-meta card-meta--views'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      display='block'
                      id='EyeOpen'
                    >
                      <path d='M21.257 10.962c.474.62.474 1.457 0 2.076C19.764 14.987 16.182 19 12 19c-4.182 0-7.764-4.013-9.257-5.962a1.692 1.692 0 0 1 0-2.076C4.236 9.013 7.818 5 12 5c4.182 0 7.764 4.013 9.257 5.962z' />
                      <circle cx='12' cy='12' r='3' />
                    </svg>
                    {hotel.location}
                  </div>
                </div>
              </article>
            ))}
        </div>
      )}
    </div>
  );
};

export default Favourite;
