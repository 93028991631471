import React from "react";
import trending1 from "../../assets/blogImages/trending/trending_1.jpg";
import trending2 from "../../assets/blogImages/trending/trending_2.jpg";
import trending3 from "../../assets/blogImages/trending/trending_3.jpg";
import trending4 from "../../assets/blogImages/trending/trending_4.jpg";

const TrendingPost = () => {
  return (
    <div className="newpostDiv">
      <h2>Trending Post</h2>
      <div className="parentTrendingPost">
        <div className="someTrendingPost">
          <div className="trendingpostimg">
            <img src={trending1} alt="" />
          </div>
          <div className="trendingdetails">
            <span>23 Dec 2021</span>
            <span>Sample article title</span>
            <p>
              On a windy night in 2017, three cups of coffee deep, I found
              myself banging my head trying to figure out how I could create a
              custom website using HTML and CSS.
            </p>
          </div>
        </div>
        <div className="someTrendingPost">
          <div className="trendingpostimg">
            <img src={trending2} alt="" />
          </div>
          <div className="trendingdetails">
            <span>23 Dec 2021</span>
            <span>Sample article title</span>
            <p>
              On a windy night in 2017, three cups of coffee deep, I found
              myself banging my head trying to figure out how I could create a
              custom website using HTML and CSS.
            </p>
          </div>
        </div>
        <div className="someTrendingPost">
          <div className="trendingpostimg">
            <img src={trending3} alt="" />
          </div>
          <div className="trendingdetails">
            <span>23 Dec 2021</span>
            <span>Sample article title</span>
            <p>
              On a windy night in 2017, three cups of coffee deep, I found
              myself banging my head trying to figure out how I could create a
              custom website using HTML and CSS.
            </p>
          </div>
        </div>

        <div className="someTrendingPost">
          <div className="trendingpostimg">
            <img src={trending4} alt="" />
          </div>
          <div className="trendingdetails">
            <span>23 Dec 2021</span>
            <span>Sample article title</span>
            <p>
              On a windy night in 2017, three cups of coffee deep, I found
              myself banging my head trying to figure out how I could create a
              custom website using HTML and CSS.
            </p>
          </div>
        </div>
      </div>

      <div className="blogsubscribemailparent">
        <div className="blogsubscribemail">
          <h3>Keep up with latest booking trends</h3>
          <p>
            Subscribe to our weekly newsletter and stay in the loop with the
            latest hospitality trends, insights, and exclusive offers.
          </p>
          <div>
            <form className="email">
              <input
                type="email"
                placeholder="email"
                name="emailAddress"
                id="emailAddress"
                className="subscribe"
              />

              <button>Subscribe</button>
              {/* <br />
          {formik.touched.emailAddress && formik.errors.emailAddress ? (
            <p className='error'>{formik.errors.emailAddress}</p>
          ) : null} */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingPost;
