import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'
import './Partnerships.css'

const Partnerships = () => {
  return (
    <div>
    <NavBar />
    <div className='patnerShip'>
      <div className='patnerShipMainDiv'>
          <h1>Partner with Tiptopgo.com - Your Premier Hotel Booking Platform</h1>

          <h2>Driving Innovation, Empowering Collaborations: </h2>
          <p>Join Forces with Tiptopgo
            Welcome to the collaboration hub of Tiptopgo.com, your premier destination for hotel bookings. We are eager to explore new horizons and establish impactful partnerships with individuals and businesses who share our commitment to innovation and excellence in the hospitality industry.
            </p>

            <h2>Why Partner with Tiptopgo? </h2>
          <p>
            <ul>
              <li>Innovative Technologies: Collaborate with a team at the forefront of cutting-edge technologies, driving innovation in the hotel booking sector.
              </li>
              <li>Shared Values:
              Tiptopgo is built on the belief that combining experience with energy completes a team. We seek partners who resonate with our vision and are prepared to make a positive impact.
              </li>
              <li>Global Reach:
              Together, we can extend our influence and make a meaningful difference in the global hotel booking landscape.
              </li>
            </ul>

            <h2>Who Should Partner with Us?</h2>

            <p>
              <ul>
                <li>Visionary Entrepreneurs:
                If you have a groundbreaking idea or startup that aligns with our mission, we want to collaborate with you.
                </li>
                <li>Forward-Thinking Businesses:
                Explore mutually beneficial opportunities that leverage the strengths of both parties for sustained growth in the hotel booking market.
                </li>
                <li>Industry Experts:
                Share your expertise and contribute to our collective success in revolutionizing the hotel booking experience.
                </li>
              </ul>
            </p>

            <h2>How to Get Started</h2>

            <p>
              <ul>
                <li>Connect: 
                Reach out to us via email, phone, or schedule a meeting to express your interest in a partnership with Tiptopgo.
                </li>
                <li>Share Your Vision: Let us know about your goals, values, and the vision you have for the partnership.</li>
                <li>Explore Together: Together, we'll delve into potential synergies, collaborative projects, and opportunities that align with our shared goals of enhancing the hotel booking experience.</li>
              </ul>
            </p>

            <h2>Let's Shape the Future of Hotel Booking Together</h2>

            <p>At Tiptopgo, we believe that partnerships are the catalysts for growth and innovation in the hospitality sector. If you're excited about the prospect of collaborating with us to redefine hotel bookings, we invite you to connect today.</p>

            <p>
              <span className='contactInfo'>Contact Information:</span>
              <p></p>
              
              <span>Email: partnerships@tiptopgo.com </span>
              <p></p>
              <span>Phone: +2347047208665</span>
              <p></p>
              <span>We look forward to embarking on this collaborative journey with you!</span>
            </p>
            </p>
      </div>
    </div>
<Subscribe/>
 <Footer/>
</div>
  )
}

export default Partnerships