import {
  ADD_TO_CART,
  ADJUST_QTY,
  REMOVE_FROM_CART,
  CLEAR_ROOM_CART,
  BREAKFAST_QTY,
} from "../Types/room_types";

const INITIAL_STATE = [];

const roomReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  // console.log("payload", payload);
  let quantity;
  // let amount;
  let breakFastQty;
  switch (type) {
    case ADD_TO_CART:
      const item = state.find((prod) => prod.roomTypeId === payload.roomTypeId);
      if (!item) {
        return [
          ...state,
          {
            ...payload,
            qty: 1,
            quantity: 1,
            totalCost: 1 * payload.price,
            totalCostNoDiscount: 1 * payload.roomCost * payload.numOfDays,
            breakFastprice: payload.breakfastFlatCost,
            breakfastCost: 0,
            numOfBreakfast: 0,
            totalRoomPrice: 0,
            totalRoomPriceNoDiscount: 0,
            actualBreakfastCost: 0,

            // breakFastTotalCost: payload.breakfastFlatCost,
            hasError: false,
          },
        ];
      }
      return [...state];

    case BREAKFAST_QTY:
      breakFastQty = state.find(
        (itemQty) => itemQty.roomTypeId === payload.roomTypeId
      );
      //  console.log('in reducer breakFastQty', breakFastQty);
      // console.log("payload ==>", payload);
      if (breakFastQty) {
        const breakfastCost =
          payload.numOfBreakfast *
          breakFastQty.breakFastprice *
          breakFastQty.qty;
        let totalRoomPrice = breakFastQty.newKey + +breakfastCost;
        let totalCostNoDiscount =
          breakFastQty.totalCostNoDiscount + +breakfastCost * payload.numOfDays;
        let totalRoomPriceNoDiscount =
          breakFastQty.roomCost * breakFastQty.qty + +breakfastCost;
        return state.map((value) =>
          value.roomTypeId === payload.roomTypeId
            ? {
                ...value,
                breakfastCost: breakFastQty.breakfastFlatCost,
                numOfBreakfast: payload.numOfBreakfast,
                totalRoomPrice: totalRoomPrice,
                actualBreakfastCost: breakfastCost,
                totalRoomPriceNoDiscount: totalRoomPriceNoDiscount,
                totalCostNoDiscount: totalCostNoDiscount,
              }
            : value
        );
      }

      return state;

    case ADJUST_QTY:
      quantity = state.find(
        (itemQty) => itemQty.roomTypeId === payload.roomTypeId
      );
    // console.log("payload.numOfDays", quantity.numOfDays);
      if (quantity) {
        const hasError = payload.qty > quantity.availableRooms;
        const totalCost =
          quantity.hotelHasFlatRate === true
            ? payload.qty * quantity.newKey
            : payload.qty * quantity.price;
        const totalCostNoDiscount =
          +payload.qty * +quantity.price * +quantity.numOfDays;
        let totalRoomPrice = quantity.totalCost;
        let totalRoomPriceNoDiscount = quantity.totalRoomPriceNoDiscount;
        // console.log("quantity", quantity);
        // console.log("totalCost", totalCost)
        // const roomQty = true
        // (quantity.discountRate ? quantity.discountPrice : quantity.price);
        // prettier-ignore

        return state.map((value) =>
          value.roomTypeId === payload.roomTypeId
            ? {
                ...value,
                qty: payload.qty,
                totalCost,
                totalCostNoDiscount,
                hasError,
                totalRoomPrice: totalRoomPrice,
                totalRoomPriceNoDiscount: totalRoomPriceNoDiscount,
              }
            : value
        );
      }
      return state;

    case REMOVE_FROM_CART:
      return state.filter(
        (rmvprod) => rmvprod.roomTypeId !== payload.roomTypeId
      );

    case CLEAR_ROOM_CART:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default roomReducer;
