import React, {useState, useEffect} from 'react'

import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from 'react-router-dom'
import './checkBooking.css'
import axios from '../../utilities/axios'
import * as Yup from "yup"
import { useFormik } from 'formik'
import NavBar from './NavBar';
import Footer from '../Footer/Footer';
import Subscribe from '../Subscribe/Subscribe';
import sideimage from '../../assets/customerCare3.png';
import tiptop from '../../assets/TiptopGoLogo.png'
import {BiFileFind} from 'react-icons/bi'
import {
  handleAddToCart,
  handleAdjustQty,
  handleClearStore,
  GetCartData
} from '../../Redux/Actions/room_actions';
import { connect } from 'react-redux';


const CheckBooking = ({handleClearStore}) => {
 // const [reservation, setReservation] = useState()

 useEffect(()=>{
  handleClearStore()
 },[])
  
   const [loading, setLoading] = useState(false)

  let reservation;
       
  
  const navigation = useNavigate()

  const formik = useFormik({
    initialValues: {
      bookingCode: '',
      Name: ""
    },
    validationSchema: Yup.object({
      bookingCode: Yup.string().max(10,"Booking Code is required").required("Booking code is Required"),
      // password: Yup.string().max(20, "Must be 6 characters or less").required("password is Required"),
    }),
  
    onSubmit:  async (values) => {
      //console.log(values)

      let value = values.bookingCode

      setLoading(true)

      
  
      const sourceType = 'TiptopgoWeb';
      const reservationResult = await axios.get(`registrations/getAppReservationRecords?filterType=${sourceType}&bookingCode=${value}`);  
      const searchResult = reservationResult.data
      
      reservation = searchResult
      // console.log("reservation",searchResult)   

      
      setTimeout(()=>{
        setLoading(false)
       },5000)

      navigation('/receipt', {state: reservation})     
  
     
    }

   
  })
  

  return (
    <>
    <NavBar />
    <div className='check_main'>
    <div className='sideImg2'>
      
      <img src={sideimage} alt='logo' />
    </div>
    
        
       <div className='check_parent'>
        {
        loading ? <ClipLoader color={"#1E53A9"} loading={loading}  size={100}  data-testid={loading} className="loader"/>
        :     
        <>
        <h1>Find Your Reservation</h1>

      <form onSubmit={formik.handleSubmit}>
          <input type='text' placeholder='Booking Code'
           value={formik.values.bookingCode.trim()}   
          onChange={formik.handleChange}         
           onBlur={formik.handleBlur}
           name='bookingCode'
           id='bookingCode'
           />
            {formik.touched.bookingCode && formik.errors.bookingCode ? <h1 style={{color: "red", marginTop: "-3%", fontSize: "0.6em", marginLeft: "-30%"}}>{formik.errors.bookingCode}</h1>: null}
          <input type='text' placeholder='Name'
          value={formik.values.Name.trim()}   
          onChange={formik.handleChange} 
           onBlur={formik.handleBlur}
           name='Name'
           id='Name'
          />
          
          <button type='submit' > <BiFileFind className='findicon' /> Find My Booking</button>   
          
          {/* <div className='tiptop'>
              <img src={tiptop} alt='tiptop'/>  
          </div> */}
         
         
          {/* <button>Find My Booking</button> */}
      </form>

      </>
        }
      
  </div>
  </div>
  <Subscribe/>
    <Footer/>
  </>
  )    
}

const mapStateToProps = (state) => {
  const cart = state.cart;
  return {
    cart
  };
};

export default connect(mapStateToProps, {handleClearStore})(
  CheckBooking
);