// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './Reducers';
// // import thunk from 'redux-thunk';
// // const middleware = [thunk];

// const store = configureStore({
//   reducer: {
//     rootReducer
//   }
// });

// export default store;


// store.js

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './Reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['currency'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };

   