import React, {useState}from 'react'
import NavBar from '../../HomePage/NavBar/NavBar'
import { useNavigate, useLocation } from 'react-router-dom'
import './Forget.css'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import axios from '../../utilities/axios';
  import {BsFillEyeSlashFill} from 'react-icons/bs'
  import {AiFillEye} from 'react-icons/ai'
  import {FaSignInAlt} from 'react-icons/fa';  
import Subscribe from '../../HomePage/Subscribe/Subscribe'
import Footer from '../../HomePage/Footer/Footer'
import reg from '../../assets/login.png'

const ForgetPassword = () => {

    const [showPassword, setShowPassword] = useState(false);
    const navigation = useNavigate();
    const history = useLocation();

    const {tipTopGoGuestId} = history.state

    // console.log("tiptopgoGuestId", tipTopGoGuestId)

    
const setShowPass = () => {
    setShowPassword(!showPassword)
  }

    const notify = (res) => {
        toast.success(res.data.message,{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        
        })
      }
      
    //   const notifySuccess = (res,values) => {
    //     toast.success(res.data.message,{
    //      position: "top-right",
    //      autoClose: 5000,
    //      hideProgressBar: false,
    //      closeOnClick: true,
    //      pauseOnHover: true,
    //      draggable: true,
    //      progress: undefined,
    //      theme: "dark",
       
    //    })

    // }

    const formik = useFormik({
        initialValues: {
          tipTopGoGuestId: tipTopGoGuestId,
          newPassword:"",
          // confirmPassword:"",      
          sourceType: "TiptopgoWeb"
        },
        validationSchema: Yup.object({
          // tipTopGoGuestId: Yup.string().max(20, "too short").required(" is Required"),
          newPassword: Yup.string().max(6, "Must be 6 characters or less").required("password is required"),
          confirmPassword: Yup.string()
          .test('passwords-match', 'Passwords must match', function(value){
            return this.parent.newPassword === value
          })
        }),
      
        onSubmit:  async (values) => {
          // console.log("tipTopGoGuestId",values.tipTopGoGuestId)
      
          await axios.post('/registrations/forgotPassword', values)
          .then((res) => {
            
              notify(res)
              // console.log("response", res);
           
          })
          .catch(err => console.log("connection error", err))
            
      
          navigation('/Login', {state: {
            
          }})
      
        }
      })
      
  return (
    <div className='parent_forget'>
    <NavBar/>   
     
{/* <Banner /> */}
<div className='loginSwap'>
    <div className='loginImg'>
      <img src={reg} alt='liquid'/>
    </div>
<div className="containerforget2">
{/* <form className='login_form' onSubmit={formik.handleSubmit}>
<p>Welcome</p>
<input 
className='forgetInput'
type="text" 
placeholder="email Address" 
value={formik.values.tipTopGoGuestId}
 onChange={formik.handleChange} 
 onBlur={formik.handleBlur}
 name="tiptopgoGuestId"
  id='tiptopgoGuestId'
  />
 
  <br/>
  {formik.touched.tipTopGoGuestId && formik.errors.tipTopGoGuestId ? <p className='error'>{formik.errors.tipTopGoGuestId}</p>: null}
  <div  className='passwordInput'>
<input 
 type={showPassword ? 'text' : 'password'}
 placeholder="New Password"
 value={formik.values.newPassword} 
 onChange={formik.handleChange} 
 onBlur={formik.handleBlur}
 name="newPassword" 
 id='newPassword'
 />{showPassword ? <BsFillEyeSlashFill onClick={setShowPass} className='togglePass'/>:  <AiFillEye onClick={setShowPass} className='togglePass'/> }
</div>
 <br/>
 {formik.touched.newPassword && formik.errors.newPassword ? <p className='error'>{formik.errors.newPassword}</p> : null}
 <button type="submit" ><FaSignInAlt className='signinIcon'/>Change password</button> <br/>

</form> */}


<form className='forget_form' onSubmit={formik.handleSubmit}>
    <p>Please!!!<br/> Input your<br/> New password </p>
          <div  className='passwordInput'>
    <input 
     type={showPassword ? 'text' : 'password'}
     placeholder="Password"
     value={formik.values.password} 
     onChange={formik.handleChange} 
     onBlur={formik.handleBlur}
     name="password" 
     id='password'
     />{showPassword ? <BsFillEyeSlashFill onClick={setShowPass} className='togglePass'/>:  <AiFillEye onClick={setShowPass} className='togglePass'/> }
    </div>
     <br/>
     {formik.touched.password && formik.errors.password ? <p className='error'>{formik.errors.password}</p> : null}
     
     <div className='passwordInput'>
    <input 
    type="password" 
    placeholder="confirmPassword" 
    value={formik.values.confirmPassword}
     onChange={formik.handleChange} 
     onBlur={formik.handleBlur}
     name="confirmPassword"
      id='confirmPassword'
      />
      {/* <MdMarkEmailRead className='togglePass2'/> */}
     </div>
      <br/>
      {formik.touched.confirmPassword && formik.errors.confirmPassword ? <p className='error'>{formik.errors.confirmPassword}</p>: null}

     <button type="submit" ><FaSignInAlt className='signinIcon'/>Change password</button> <br/>
    {/* <Link to='/confirmEmail'><a className='forget'>Forgot Password?</a></Link> */}
    {/* <div className='regHere'><span>Have an account? <br/> {" "} <Link to='/register'>Register</Link></span></div> */}
  </form>

{/* <div className="drops">
<div className="drop drop-1"></div>
<div className="drop drop-2"></div>
<div className="drop drop-3"></div>
<div className="drop drop-4"></div>
<div className="drop drop-5"></div>
</div> */}
</div>
</div>
<ToastContainer />
<Subscribe/>
{/* <GetA/> */}
<Footer/>
</div>
  )
}

export default ForgetPassword