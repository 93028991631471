import React from 'react'
import NavBar from '../../../NavBar/NavBar'
import Subscribe from '../../../Subscribe/Subscribe'
import Footer from '../../Footer'
import './HotelsRewards.css'

const HotelsRewards = () => {
  return (
    <div>
  <NavBar />
    <div className='hotelRewards'>
    <div className='hotelRewardsDiv'>
        <h1>Tiptopgo Hotel Rewards Terms</h1>
        <p>
        <p>Welcome to Tiptopgo's Hotel Rewards Program. These terms outline the agreement between Tiptopgo and participants in our rewards program. By enrolling in and participating in the program, you agree to comply with the following terms:</p>

        1. Eligibility:

        <ul>
          <li><span className='tickMark'>&#10004;</span> The Tiptopgo Hotel Rewards Program is open to individuals aged 18 and above who create an account on our platform.</li>

          <li><span className='tickMark'>&#10004;</span> Eligibility is subject to compliance with all applicable laws and regulations.</li>
        </ul>

        2. Earning Points:

        <ul>
          <li><span className='tickMark'>&#10004;</span> Members earn points for eligible hotel bookings made through the Tiptopgo platform.</li>
          <li><span className='tickMark'>&#10004;</span> Points are credited to the member's account after the completion of the stay.</li>
          <li><span className='tickMark'>&#10004;</span> The number of points earned per booking may vary and is subject to the terms specified for each hotel.</li>
        </ul>

        3. Redeeming Points:

        <ul>
          <li><span className='tickMark'>&#10004;</span> Accumulated points can be redeemed for discounts on future hotel bookings within the Tiptopgo platform.</li>
          <li><span className='tickMark'>&#10004;</span> Redemption options and point values are subject to change, and members are encouraged to review the current redemption terms regularly.</li>
        </ul>

        4. Point Expiry:

        <ul>
          <li><span className='tickMark'>&#10004;</span> Points may have an expiration date, and members should be aware of the validity period.</li>
          <li><span className='tickMark'>&#10004;</span> Tiptopgo reserves the right to adjust or expire points at its discretion.</li>
        </ul>

        5. Program Changes:

        <ul>
          <li><span className='tickMark'>&#10004;</span> Tiptopgo reserves the right to modify, suspend, or terminate the Hotel Rewards Program, including points earning and redemption terms, at any time without prior notice.</li>

          <li><span className='tickMark'>&#10004;</span> Changes will be effective upon posting on the Tiptopgo website.</li>
        </ul>

        6. Member Responsibilities:

        <ul>
          <li><span className='tickMark'>&#10004;</span> Members are responsible for keeping their account information accurate and up-to-date.</li>
          <li><span className='tickMark'>&#10004;</span> Tiptopgo is not responsible for any unauthorized use of a member's account.</li>
        </ul>

        7. Termination:

        <ul>
          <li><span className='tickMark'>&#10004;</span> Tiptopgo reserves the right to terminate a member's participation in the Hotel Rewards Program for any reason, including but not limited to violation of program terms or abuse of the program.</li>
        </ul>

        8. Governing Law:

        <ul>
          <li><span className='tickMark'>&#10004;</span> These terms are governed by and construed in accordance with the laws of Nigeria, without regard to its conflict of law principles.</li>
        </ul>

        9. Contact Information:

        <ul>
          <li><span className='tickMark'>&#10004;</span> For inquiries or assistance related to the Hotel Rewards Program, members can contact Tiptopgo's customer support at support@tiptopgo.com.</li>
        </ul>

        <p>By participating in the Tiptopgo Hotel Rewards Program, you acknowledge that you have read, understood, and agreed to these terms. Tiptopgo reserves the right to update these terms at any time, and it is the member's responsibility to stay informed about any changes.</p>
        </p>
        
     </div>
    </div>
<Subscribe/>
 <Footer/>
    </div>
  )
}

export default HotelsRewards