import React from "react";
import "./AgreementFormFirstpage.css";
import * as Yup from "yup";
import { useFormik, Formik, Field, Form } from "formik";
import { CiSaveUp2 } from "react-icons/ci";
import { IoReturnDownBackOutline } from "react-icons/io5";
import axios from "../../../utilities/axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const AgreementFormFirstPage = ({
  hideForm,
  hotelResponse,
  errorMessage,
  constractCode,
}) => {
  const { contractCode } = constractCode;
  // console.log("contractCod", contractCode);
  const notify = () => {
    toast.success("aggreement was successfully signed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const formik = useFormik({
    initialValues: {
      name: hotelResponse?.hotelName,
      hotelName: hotelResponse?.hotelName,
      hotelAddress:
        errorMessage === "code has already been used"
          ? hotelResponse?.hotelAddress
          : hotelResponse?.address,
      commissionRate: hotelResponse?.commissionRate,
      accName:
        errorMessage === "code has already been used"
          ? hotelResponse?.accName
          : "",
      bank:
        errorMessage === "code has already been used"
          ? hotelResponse?.bank
          : "",
      accNumber:
        errorMessage === "code has already been used"
          ? hotelResponse?.accNumber
          : "",

      mainContactName:
        errorMessage === "code has already been used"
          ? hotelResponse?.mainContactName
          : "",
      mainContPhone:
        errorMessage === "code has already been used"
          ? hotelResponse?.mainContPhone
          : "",
      mainContEmail:
        errorMessage === "code has already been used"
          ? hotelResponse?.mainContEmail
          : "",
      managerName:
        errorMessage === "code has already been used"
          ? hotelResponse?.managerName
          : "",
      managerPnone:
        errorMessage === "code has already been used"
          ? hotelResponse?.managerPnone
          : "",
      managerEmail:
        errorMessage === "code has already been used"
          ? hotelResponse?.managerEmail
          : "",
      //  readTerms : errorMessage === "code has already been used" ? true : false,
      sign:
        errorMessage === "code has already been used"
          ? hotelResponse?.sign
          : "",
      dateSign: moment(hotelResponse?.createdDateTime).format(
        "YYYY-MM-DD HH:mm:ss A"
      ),
      actionType: "validCode",
      contractCode: contractCode,
    },
    validationSchema: Yup.object({
      accName: Yup.string()
        .max(150, "please enter your account name")
        .required("account name is Required"),
      bank: Yup.string()
        .max(100, "please enter your bank name")
        .required("bank name is Required"),
      accNumber: Yup.string()
        .min(6, "password must be 6 characters")
        .required("password is Required"),
      mainContactName: Yup.string()
        .max(100, "please enter your country")
        .required("country is Required"),
      mainContPhone: Yup.string()
        .matches(/^[0-9]{11}$/, "Phone number must be exactly 11 digits")
        .required("Phone number is required"),
      mainContEmail: Yup.string()
        .matches(/^[^\s]+$/, "Email must not contain spaces")
        .email("Invalid email address")
        .required("Email is required"),
      managerName: Yup.string()
        .max(100, "please enter your name")
        .required("name is Required"),
      managerPnone: Yup.string()
        .matches(/^[0-9]{11}$/, "Phone number must be exactly 11 digits")
        .required("Phone number is required"),
      managerEmail: Yup.string()
        .matches(/^[^\s]+$/, "Email must not contain spaces")
        .email("Invalid email address")
        .required("Email is required"),
      // readTerms: Yup.boolean().oneOf(
      //   [true],
      //   "You must read the terms and conditions"
      // ),
      sign: Yup.string()
        .max(100, "please enter your name")
        .required("name is Required"),
    }),

    onSubmit: async (values) => {
      // console.log("values", values);
      // hideForm();
      // console.log("values", values);
      await axios
        .put("/hotels/confirmElectronicCode", values)
        .then((res) => {
          if (res.data.success === true) {
            notify();
            hideForm();
            // console.log("res", res);
          } else {
            // console.log("res", res);
          }
        })
        .catch((err) => console.log(err));
    },
  });

  const goBack = () => {
    hideForm();
  };
  return (
    <>
      <form className='textMainForm' onSubmit={formik.handleSubmit}>
        <p className='firstpara'>
          <span className='tiptopIndicate'>TipTopGo.com:</span> For{" "}
          <span className='tiptopIndicate'>Intellims Systems Limited,</span> a
          private limited liability company incorporated in accordance with the
          provisions of the laws of the Federal Republic of Nigeria with RC
          No.792942, and having its registered address at 3C, DURBAN STREET,
          WUSE 2, ABUJA, FCT (hereinafter referred to as the “Company”, and
          which expression shall where the context so admits include its
          successors- in-title and assigns) of the one part.
        </p>

        <p>AND</p>

        <p className='agremmentNameSec'>
          Name{" "}
          <input
            name='name'
            id='name'
            type='text'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className='inputName'
            readOnly
          />{" "}
          Hotel, owned and operated by{" "}
          <input
            name='hotelName'
            id='hotelName'
            type='text'
            value={formik.values.hotelName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className='inputName'
            readOnly
          />
          , and having its hotel address at{" "}
          <input
            name='hotelAddress'
            id='hotelAddress'
            type='text'
            value={formik.values.hotelAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className='inputName'
            readOnly
          />{" "}
          (hereinafter referred to as the “Hotel”, and which expression shall
          where the context so admits include its successors- in-title and
          assigns) of the other part
        </p>

        <p className='thirdPara'>
          The following terms and conditions constitute a definitive agreement
          between Hotel and the Company. By clicking the 'Accept' or similar
          option, the Hotel agrees to terms of this Agreement inter alia along
          with other terms of use of the Company listed on the website, as and
          when updated. This Agreement shall be read along with the aforesaid
          and in case of any inconsistency, the terms and conditions of this
          Agreement shall prevail.
        </p>

        <p className='listPara'>
          <ol>
            <li>
              <span className='tiptopIndicate'>Execution & Performance:</span>{" "}
              The Agreement shall be effective on and from the date on which
              Hotel has provided its acceptance to the Agreement which shall
              become enforceable upon confirmation by the Company ("Effective
              Date").
            </li>

            <li>
              (a){" "}
              <span className='tiptopIndicate'>
                Commission Rate Terms: [ {hotelResponse?.commissionRate} ]%
              </span>{" "}
              It is agreed that TipTopGo.com will earn and deduct this
              commission before remitting payment to the Hotel,In the case of
              prepaid bookings.
              <p></p>
              2. (b) However, in the case of postpaid bookings, the hotel remits
              agreed commission to Tiptopgo, on or before 14days after invoice
              has been issued.
              <p></p>
              2. (c){" "}
              <span className='tiptopIndicate'>
                {" "}
                Payment Options: Check one: Allow Payment, <br />
              </span>
              <div className='checkOpt'>
                {hotelResponse?.allowPaymentType || "Online Only"}:{" "}
                {errorMessage === "code has already been used" ? (
                  <input
                    type='checkbox'
                    name='allowPaymentType'
                    id='Both'
                    value='Both'
                    checked
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <>
                    <input
                      type='checkbox'
                      name='allowPaymentType'
                      id='OnlineOnly'
                      value='Online Only'
                      checked={formik.values.allowPaymentType === "Online Only"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked) {
                          formik.setFieldValue(
                            "allowPaymentType",
                            "Online Only"
                          );
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />{" "}
                    At Hotel Only:{" "}
                    <input
                      type='checkbox'
                      name='allowPaymentType'
                      id='AtHotelOnly'
                      value='At Hotel Only'
                      checked={
                        formik.values.allowPaymentType === "At Hotel Only"
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked) {
                          formik.setFieldValue(
                            "allowPaymentType",
                            "At Hotel Only"
                          );
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />{" "}
                    Both:{" "}
                    <input
                      type='checkbox'
                      name='allowPaymentType'
                      id='Both'
                      value='Both'
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked) {
                          formik.setFieldValue("allowPaymentType", "Both");
                        }
                      }}
                      checked={formik.values.allowPaymentType === "Both"}
                      onBlur={formik.handleBlur}
                    />
                  </>
                )}
              </div>
            </li>

            <li className='thirdItem'>
              <span className='tiptopIndicate'>
                Payment Remittance Terms (From Tiptopgo to Hotel): Check One:{" "}
              </span>
              <br />
              <div className='checkOpt'>
                {hotelResponse?.paymentRemitTerms || "Per Booking"}:{" "}
                {errorMessage === "code has already been used" ? (
                  <input
                    type='checkbox'
                    name='paymentRemitTerms'
                    id='perbooking'
                    value='per booking'
                    checked
                    onBlur={formik.handleBlur}
                  />
                ) : (
                  <>
                    <input
                      type='checkbox'
                      name='paymentRemitTerms'
                      id='perbooking'
                      value='per booking'
                      checked={
                        formik.values.paymentRemitTerms === "per booking"
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked) {
                          formik.setFieldValue(
                            "paymentRemitTerms",
                            "per booking"
                          );
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />{" "}
                    Daily:{" "}
                    <input
                      type='checkbox'
                      name='paymentRemitTerms'
                      id='Daily'
                      value='Daily'
                      checked={formik.values.paymentRemitTerms === "Daily"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked) {
                          formik.setFieldValue("paymentRemitTerms", "Daily");
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />{" "}
                    Weekly:{" "}
                    <input
                      type='checkbox'
                      name='paymentRemitTerms'
                      id='Weekly'
                      value='Weekly'
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked) {
                          formik.setFieldValue("paymentRemitTerms", "Weekly");
                        }
                      }}
                      checked={formik.values.paymentRemitTerms === "Weekly"}
                      onBlur={formik.handleBlur}
                    />
                  </>
                )}
                . Remit Payment to:
              </div>

              <br />
              <div className='spanInputpayment'>
                <label htmlFor=''>Account Name</label>{" "}
                <input
                  name='accName'
                  id='accName'
                  type='text'
                  value={formik.values.accName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // className='paymentDetails'
                  className={
                    formik.touched.accName && formik.errors.accName
                      ? "paymentDetails2"
                      : "paymentDetails"
                  }
                  readOnly={
                    errorMessage === "code has already been used" ? true : false
                  }
                />
              </div>
              <br />
              <div className='spanInputpayment'>
                <label htmlFor=''>Bank Name;</label>{" "}
                <input
                  name='bank'
                  id='bank'
                  type='text'
                  value={formik.values.bank}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // className='paymentDetails'
                  className={
                    formik.touched.bank && formik.errors.bank
                      ? "paymentDetails3"
                      : "paymentDetails"
                  }
                  readOnly={
                    errorMessage === "code has already been used" ? true : false
                  }
                />
              </div>
              <br />
              <div className='spanInputpayment'>
                <label htmlFor=''>Acc Number;</label>{" "}
                <input
                  name='accNumber'
                  id='accNumber'
                  type='text'
                  value={formik.values.accNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // className='paymentDetails'
                  className={
                    formik.touched.accNumber && formik.errors.accNumber
                      ? "paymentDetails4"
                      : "paymentDetails"
                  }
                  readOnly={
                    errorMessage === "code has already been used" ? true : false
                  }
                />
              </div>
              <br />
              {/* <div className='spanInputpayment'>
                <label htmlFor=''>Sort Code</label>{" "}
                <input
                  name='sortCode'
                  id='sortCode'
                  type='text'
                  value={formik.values.sortCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='paymentDetails'
                  // className={
                  //   formik.touched.contractCode && formik.errors.contractCode
                  //     ? "paymentDetails5"
                  //     : "paymentDetails"
                  // }
                  readOnly={
                    errorMessage === "code has already been used" ? true : false
                  }
                />
              </div> */}
            </li>

            <li>
              <span className='tiptopIndicate'> Contact Information: </span>
              <div className='contactInfoDivagree'>
                <div className='firstcontactInfopart'>
                  <div className='contactInputDiv'>
                    <label htmlFor=''>Main Contact Name</label>
                    <input
                      name='mainContactName'
                      id='mainContactName'
                      type='text'
                      value={formik.values.mainContactName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // className='contactInfoField'
                      className={
                        formik.touched.mainContactName &&
                        formik.errors.mainContactName
                          ? "contactInfoField12"
                          : "contactInfoField"
                      }
                      readOnly={
                        errorMessage === "code has already been used"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='contactInputDiv'>
                    <label htmlFor=''>Phone</label>{" "}
                    <input
                      name='mainContPhone'
                      id='mainContPhone'
                      type='text'
                      value={formik.values.mainContPhone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // className='contactInfoField'
                      className={
                        formik.touched.mainContPhone &&
                        formik.errors.mainContPhone
                          ? "contactInfoField3"
                          : "contactInfoField"
                      }
                      readOnly={
                        errorMessage === "code has already been used"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='contactInputDiv'>
                    <label htmlFor=''>Email</label>{" "}
                    <input
                      name='mainContEmail'
                      id='mainContEmail'
                      type='text'
                      value={formik.values.mainContEmail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // className='contactInfoField'
                      className={
                        formik.touched.mainContEmail &&
                        formik.errors.mainContEmail
                          ? "contactInfoField4"
                          : "contactInfoField"
                      }
                      readOnly={
                        errorMessage === "code has already been used"
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>

                <div className='secondcontactInfopart'>
                  <div className='contactInputDiv'>
                    <label htmlFor=''>Manager Name</label>
                    <input
                      name='managerName'
                      id='managerName'
                      type='text'
                      value={formik.values.managerName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // className='contactInfoField2'
                      className={
                        formik.touched.managerName && formik.errors.managerName
                          ? "contactInfoField21"
                          : "contactInfoField2"
                      }
                      readOnly={
                        errorMessage === "code has already been used"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='contactInputDiv'>
                    <label htmlFor=''>Phone</label>{" "}
                    <input
                      name='managerPnone'
                      id='managerPnone'
                      type='text'
                      value={formik.values.managerPnone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // className='contactInfoField2'
                      className={
                        formik.touched.managerPnone &&
                        formik.errors.managerPnone
                          ? "contactInfoField22"
                          : "contactInfoField2"
                      }
                      readOnly={
                        errorMessage === "code has already been used"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='contactInputDiv'>
                    <label htmlFor=''>Email</label>{" "}
                    <input
                      name='managerEmail'
                      id='managerEmail'
                      type='text'
                      value={formik.values.managerEmail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // className='contactInfoField2'
                      className={
                        formik.touched.managerEmail &&
                        formik.errors.managerEmail
                          ? "contactInfoField23"
                          : "contactInfoField2"
                      }
                      readOnly={
                        errorMessage === "code has already been used"
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </div>
            </li>

            {/* <li className='lastParaList'>
              <span className='tiptopIndicate'>General Delivery Terms:</span>{" "}
              The Hotel declares that it has read and hereby accepts the terms
              and conditions as mentioned herein.
            </li> */}
          </ol>

          <div className='readAgree'>
            {/* <div className='checkReadInput'>
              <input
                name='readTerms'
                id='readTerms'
                type='checkbox'
                checked={formik.values.readTerms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  border:
                    formik.touched.readTerms && formik.errors.readTerms
                      ? "2px solid red"
                      : "2px solid initial",
                }}
                disabled={
                  errorMessage === "code has already been used" ? true : false
                }
              />
              I have read the terms and conditions (Annexure A)
              {formik.touched.readTerms && formik.errors.readTerms ? (
                <div className='error-message'>{formik.errors.readTerms}</div>
              ) : null}
            </div> */}
            <br />

            <br />
            <div className='spanSign'>
              <p className='signPara'>
                <label htmlFor=''>Sign:</label>
                <input
                  name='sign'
                  id='sign'
                  type='text'
                  value={formik.values.sign}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // className='dateInput'
                  className={
                    formik.touched.sign && formik.errors.sign
                      ? "dateInput2"
                      : "dateInput"
                  }
                  readOnly={
                    errorMessage === "code has already been used" ? true : false
                  }
                />
              </p>
              <p className='signPara'>
                <label htmlFor=''>Date:</label>
                <input
                  type='text'
                  name='dateSign'
                  id='dateSign'
                  value={formik.values.dateSign}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='dateInput'
                  readOnly
                />
              </p>
            </div>
          </div>
        </p>
        <div className='saveBtnDiv'>
          <div className='readMore'>
            <Link to='/hotels-terms-and-conditions'>
              Read more on hotel terms and conditions
            </Link>
          </div>
          <div className='buttonDivSave'>
            <button type='button' onClick={goBack} className='cancelAgreement'>
              <IoReturnDownBackOutline className='saveIcon2' />
              Back
            </button>

            <button
              type='submit'
              disabled={
                errorMessage === "code has already been used" ? true : false
              }
              style={
                errorMessage === "code has already been used"
                  ? { cursor: "not-allowed" }
                  : null
              }
            >
              <CiSaveUp2 className='saveIcon2' />
              save
            </button>
          </div>
        </div>
        {/* <Addendum /> */}
      </form>
      <ToastContainer />
    </>
  );
};

export default AgreementFormFirstPage;
