import { combineReducers } from "redux";
import roomReducer from "./room_reducers";
import currencyReducer from "./currency_reducers";


const rootReducer = combineReducers({
  cart: roomReducer,
  currency: currencyReducer,
});

export default rootReducer;
