import React from "react";
import "./Addendum.css";

const Addendum = () => {
  return (
    <div className='addendumDiv'>
      <h2 className='annexure'>Annexure A: GENERAL TERMS AND CONDITIONS</h2>

      <ol>
        <li>
          <span className='listNum'> UNDERSTANDING OF THE PARTIES</span>
          <p>
            During the Term (defined hereinafter) and for the consideration set
            out herein, Hotel shall commit to Company all inventory of Hotel
            rooms and packages ("Inventory") as may be available and incase the
            Hotel accepts to use the IPMS free version, available at
            ipms.intellims.com, all inventory avialiable per the reservation
            management system ("System") available from the Company for
            reservations/ distributions by the Company through various channels
            available to it.
          </p>
        </li>
        <li>
          <span className='listNum'>TERM</span>
          <p>
            2.1 This Agreement shall be deemed to have come into effect on and
            from the Effective Date and shall continue for a period of one (1)
            year post the Effective Date ("Term") unless terminated by Company
            by giving one month’s written notice to the Hotel ("Notice Period").
          </p>
          <p>
            2.2 The Parties hereby agree that upon expiry of the Term or the
            renewed term, as the case may be, this Agreement shall automatically
            renew for a further term of 1 (One) year. Even after the termination
            of this Agreement, Hotel shall honor any bookings made by Company’s
            customers up till the effective date of termination or expiry of
            this Agreement. Further, the Company reserves the right to terminate
            this Agreement with immediate effect in the event of any material or
            other breach of the provision of this Agreement by Hotel including
            without limitation on the Hotel's inability to offer Inventory,
            Inventory and rate parity not being maintained by the Hotel, failure
            to issue invoices to customers, bankruptcy or winding up proceedings
            against the Hotel, change of Control of the Hotel or multiple
            escalations from customers against the Hotel i.e., customer
            satisfaction index.
          </p>
        </li>
        <li>
          <span className='listNum'>CONSIDERATION</span>
          <p>
            3.1. In lieu of the Company providing services to the Hotel/
            Accommodation, the Hotel/ Accommodation agrees to pay the
            considerations as stated in the Commission Rate Terms above as
            accepted and signed between Parties ("Consideration").
          </p>
          <p>
            3.2. All payments and considerations payable to the Hotel pursuant
            to this Agreement shall be after commission deduction and on payment
            terms indicated in the Payment Terms above. Hotel is responsible for
            any VAT, Withholding Tax, or any other deductions required by
            Government.{" "}
          </p>
          <p>
            3.3 Company shall receive payments from the customers through a
            third party payment gateway and shall be responsible to remit that
            amount to Hotel.
          </p>
        </li>
        <li>
          <span className='listNum'>MINIMUM ALLOCATION AND PARITY</span>
          <p>
            4.1 The Hotel/ Accommodation commit itself to make available a
            minimum number of rooms available for reservation via
            ipms.intellims.com (the "Minimum Allocation") or allow the IPMS
            reservation management system to allow for sale of room through the
            system.
          </p>
          <p>
            4.2 The Hotel shall ensure that Rate Parity is maintained in respect
            of the Inventory being offered pursuant to this Agreement. For the
            purpose of this Agreement, "Rate Parity" shall mean the same
            accommodation, same room type, same dates, same bed type, same or
            better policies such as breakfast, as are available on the Hotel
            websites, apps or call-centers (including the customer reservation
            system), or directly at the Hotel, with any competitor of the
            Company (which includes any online or offline reservation or booking
            agency or intermediary) and/or with any other (online or offline)
            third party that is a business partner of or in any other way
            related with or connected to the Hotel. In the event, the Company
            becomes aware that the prices being offered by the Hotel are not at
            Rate Parity, the same shall be considered material breach of this
            Agreement and the Company shall be entitled to terminate this
            Agreement in accordance with Clause 2 of this Agreement.
          </p>
        </li>

        <li>
          <span className='listNum'>SERVICE CONDITIONS</span>
          <p>
            5.1 Company shall notify all reservations made to the Hotel via
            email, IPMS Reservation System, or as per the reservation formats
            mutually agreed between the Parties. During this process, Hotel
            shall receive a notification from the Company at the time of a
            successful booking and it shall be mandatory for the Hotel to accept
            and confirm the notification with immediate effects.
          </p>

          <p>
            5.2 The rates shall be fixed by Hotel and updated to Company's
            platform. Additionally, Company may at its discretion also offer
            promotions and offers to promote the booking of Hotel rooms.
          </p>
          <p>
            5.3 By making a reservation through the Company channels, a direct
            contract (and therefore legal relationship) is created solely
            between the Hotel and the customer by means of the customer
            reservation. To the extent necessary, the Hotel hereby empowers and
            grants the Company explicit authorization to conclude customer
            reservations on its behalf arising out of online Hotel reservations
            for the Hotel made by customers via the Company. Further, it is
            agreed by the Hotel that, being the service provider to the
            customer, Hotel shall be responsible and liable for providing the
            invoice at the time checkout by or otherwise to the customer.
          </p>

          <p>
            5.4 Company will provide the Hotel with a user ID and password which
            allow the Hotel/ Accommodation to access the IPMS (Free version).
          </p>
        </li>

        <li>
          <span className='listNum'>REPRESENTATION AND WARRANTIES:</span> The
          Hotel/ Accommodation represents and warrants to the Company that:
          <p>
            6.1 It has all requisite power and authority to execute, deliver and
            perform its obligations under this Agreement and has been fully
            authorized by all requisite corporate actions to do so.
          </p>
        </li>

        <li>
          <span className='listNum'>
            CANCELLATION REFUNDS AND RETENTION POLICIES
          </span>
          <p>
            7.1 Every booking shall be made, amended or cancelled by informing
            the Company in writing. The Hotel undertakes that it shall ensure
            parity in respect of cancellation policy as well, across channels.
          </p>
          <p>
            7.2. The cancellation, retention and refund policies of the Hotel
            shall be uploaded/updated by the Hotel to System and any
            modification thereto shall be intimated in writing to Company.
          </p>
        </li>
        <li>
          <span className='listNum'> INDEMNITY</span>
          <p>
            8.1. Hotel agrees and undertakes to defend, indemnify and hold
            harmless the Company and its affiliates, directors, officers and
            employees from any and all claims, demands, action suits or
            proceedings, liabilities, losses, costs, expenses (including legal
            fees) or damages asserted against the Company arising out or in
            relation to (i) any consumer complaints arising out of Hotel’s
            services under this Agreement including but not limited to
            unavailability, deficiency and/or misbehaviors by employees; (ii)
            breach of any applicable laws pertaining to the subject of this
            Agreement; and (iii) breach of any of representations, warranties
            and obligations under this Agreement. Similarly, the Company shall,
            subject to the provisions of sub-clause (d) below, defend, indemnify
            and hold harmless the Hotel and its directors, officers and
            employees from any and all claims, demands, action, suits or
            proceedings, liabilities, losses, costs, expenses (including legal
            fees) or damages asserted against the Hotel arising out or in
            relation to (i) breach of any applicable laws pertaining to the
            subject of this Agreement; and (ii) breach of any representations,
            warranties and obligations under this Agreement.
          </p>
          <p>
            8.2. The obligations set out in this clause shall survive the Term
            of this Agreement.
          </p>
        </li>
        <li>
          <span className='listNum'>MISCELLANEOUS</span>
          <p>
            9.1. Amendment No modification, amendment, waiver, discharge or
            termination of any of the provisions of this Agreement shall be
            effective unless made in writing specifically referring to this
            Agreement and duly signed by each of the Parties.
          </p>

          <p>
            9.2. Arbitration and Jurisdiction All claims arising under this
            Agreement shall be resolved amicably by the Parties. If such dispute
            is not resolved amicably between the Parties within 30 days then the
            same shall be referred to arbitration. The arbitration proceedings
            shall be carried out by a sole arbitrator appointed mutually by the
            Parties in accordance with the rules and regulations under the
            Arbitration and Conciliation Act, 1996. The arbitration shall be
            conducted at Abuja in English language. Subject to the above, the
            Parties agree to submit to the exclusive jurisdiction of courts at
            Abuja in respect of any dispute under this Agreement. However, the
            Parties shall have the right to approach the courts at Abuja at any
            time for injunctive relief.
          </p>

          <p>
            9.3. Notice All correspondence and notices under this Agreement
            shall be given in writing at the following address: In case of a
            change in address, each Party shall notify the other Party, in
            writing, about such change. <p></p>
            <span className='listNum'>If to Hotel:</span> <br /> At the address
            provided by the Hotel at execution of this Agreement. <p></p>
            <span className='listNum'>If to Company:</span> Address- Intellims
            Systems Limited (Tiptopgo.com), 3C Durban Street, Wuse2, Abuja, FCT,
            Nigeria.{" "}
          </p>
          <p>
            9.4. <span className='listNum'>Severability: </span> If any
            provision of this Agreement shall contravene or be illegal, invalid
            or unenforceable under the laws of any state in which this Agreement
            shall be performed or enforced, then such contravention, illegality,
            invalidity or unenforceability shall not invalidate the entire
            Agreement and the Agreement shall be modified to the extent
            necessary to make it enforceable.
          </p>
          <p>
            9.5. <span className='listNum'>Independent Parties: </span> Parties
            acknowledge and agree that the relationship between them is solely
            that of independent contractors and nothing in this Agreement is to
            be construed as employer / employee, franchise/ franchisee, agent /
            principal, partners, joint ventures, co-owners, or otherwise
            participants in joint or common undertaking and the relationship is
            purely on principal to principal basis.
          </p>
          <p>
            9.6. <span className='listNum'>Survival: </span> The clauses of this
            Agreement that are by their nature intended to survive shall so
            survive the Term of this Agreement.
          </p>

          <p>
            9.7. <span className='listNum'>Assignment: </span> Each of the
            Parties understands and acknowledges that each Party shall not
            assign or otherwise transfer its rights or obligations under the
            Agreement, in whole or in part, without the prior written consent of
            the other Party. However, the Company may at any time assign or
            transfer all or any part of its rights or obligations arising under
            or in connection with this Agreement to any of its affiliate
            entities without requiring the prior written consent of the Hotel.
          </p>
          <p>
            9.8. <span className='listNum'>Entire Agreement: </span> This
            Agreement with signed, online or offline, and executed Annexure A
            contains the entire Agreement of the Parties with respect to the
            subject matter of this Agreement and supersedes all previous
            communications, representations, understandings and agreements,
            either oral or written, between the Parties with respect to said
            subject matter.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default Addendum;
