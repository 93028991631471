import React, { useEffect, useState } from "react";
import "./currencyChangeButton.css";
import axios from "../../../../utilities/axios";
import { FaAngleDown } from "react-icons/fa";
import CurrencyChange from "../currencyComponents/currencyChangeComponent";
import { handleCurrencyChange } from "../../../../Redux/Actions/currency_actions";
import { GetCurrencyData } from "../../../../Redux/Actions/currency_actions";
import { connect } from "react-redux";

const CurrencyChangeButton = ({ handleCurrencyChange }) => {
  const [showCurrencyTray, setShowCurrencyTray] = useState(false);
  const [curreImage, setCurrencyImage] = useState("");
  const [currName, setCurrencyName] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState([]);
  const getSelectedCurrency = GetCurrencyData();
  const selectedCurrencyObject = getSelectedCurrency.selectedCurrency;

  const selectedCurrency = [{
    currencyId: "45TgggU7DsavbmlkC",
    currencyName: "Nigeria Naira",
    currencyCode: "NGN",
    currencySymbol: "₦",
    currencyImage: "https://flagcdn.com/w320/ng.png",
    currencyRate: 1,
  }];

  useEffect(() => {
    const fetchData = async () => {
      // console.log("selectedCurrency", selectedCurrency);

      const ngnCurrency = selectedCurrency.find(
        (currency) => currency.currencyCode === "NGN"
      );

      if (!selectedCurrencyObject) {
        handleCurrencyChange(ngnCurrency);
      }

      setDefaultCurrency(ngnCurrency);
      setCurrencies(selectedCurrency);
      // try {
      //   const response = await axios.get(
      //     "/hotels/current-currency-rates/TiptopgoWeb"
      //   );

      //   console.log("response", response)

      //   const ngnCurrency = selectedCurrency.find(
      //     (currency) => currency.currencyCode === "NGN"
      //   );

      //   if (!selectedCurrencyObject) {
      //     handleCurrencyChange(ngnCurrency);
      //   }

      //   setDefaultCurrency(ngnCurrency);
      //   setCurrencies(selectedCurrency);
      // } catch (error) {
      //   console.error("Error fetching currency data:", error);
      // }
    };

    fetchData();
  }, []);

  // console.log("currency chang store", getSelectedCurrency.selectedCurrency);

  // const storedJsonString = localStorage.getItem("currency");
  // const storedObject = JSON.parse(storedJsonString);
  let storedCurrencyName = selectedCurrencyObject
    ? selectedCurrencyObject.currencyCode
      ? selectedCurrencyObject.currencyCode
      : defaultCurrency.currencyCode
    : defaultCurrency.currencyCode;

  let storedCurrencyImage = selectedCurrencyObject
    ? selectedCurrencyObject.currencyImage
      ? selectedCurrencyObject.currencyImage
      : defaultCurrency.currencyImage
    : defaultCurrency.currencyImage;

  const updateCurrencyImage = (newImage, newName) => {
    setCurrencyImage(newImage);
    setCurrencyName(newName);
    if (newImage) {
      setShowCurrencyTray(false);
    }
  };

  return (
    <>
      <div
        className='currencyButton'
        onClick={() => setShowCurrencyTray(!showCurrencyTray)}
      >
        <div className='currencyIMG'>
          <img src={curreImage ? curreImage : storedCurrencyImage} alt='' />
        </div>
        <div className='currencyName'>
          {currName ? currName : storedCurrencyName}
          <FaAngleDown />
        </div>
      </div>
      {showCurrencyTray ? (
        <CurrencyChange
          updateImage={updateCurrencyImage}
          currencies={currencies}
        />
      ) : null}
    </>
  );
};

// export default CurrencyChangeButton;

export default connect(null, { handleCurrencyChange })(CurrencyChangeButton);
