import React, { useEffect, useState } from "react";
import { AiFillFolderOpen } from "react-icons/ai";
import { MdFavorite } from "react-icons/md";
import { RiCoupon3Fill } from "react-icons/ri";
import { MdFreeCancellation } from "react-icons/md";
// import './LoginDashBoard.css'
import { useNavigate } from "react-router-dom";
import axios from "../../../../utilities/axios";
import profile from "../../../../assets/profile.jpg";
import maleprofile from "../../../../assets/maleProfile.png"
import femaleprofile from "../../../../assets/femaleProfile2.jpg"
// import SideBar from './SideBar/SideBar'
import { Loader } from "../../../../utilities/Loader";
import "./MainDash.css";
// import { useNavigate } from 'react-router-dom'

const MainDashBoard = () => {
  const [countDetails, setCountDetails] = useState([]);
  const [favouriteHotelCount, setFavouriteHotelCount] = useState([]);
  const [cancelledReserved, setCancelledReserved] = useState(0);

  const [load, setLoad] = useState(false);

  let reservationDetails;
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));
      
  const guestId = userData.tipTopGoGuestId;
  const getReservation = async () => {
    setLoad(true);
    navigate("/LoginDash/history", {
      state: {
        reservationDetails,
        userData,
      },
    });
    setLoad(false);
  };

  const getReservationToCancel = async () => {
    setLoad(true);
    navigate("/LoginDash/cancel-reservation", {
      state: {
        reservationDetails,
        userData,
      },
    });
    setLoad(false);
  };

  const getReserveCount = async () => {
    const reservation = await axios.get(
      `/registrations/getAppReservationRecords?filterType=bookHistory&tipTopGoGuestId=${guestId}`
    );
    setCountDetails(reservation.data.length);
    const newArray = reservation.data.map((item) => ({
      ...item,
      bookings: item.bookings.filter(
        (booking) => booking.statuss === "cancelled"
      ),
    }));
    // console.log("Total Cancelled Reservations:", reservation.data);

    const totalCancelled = newArray.reduce((total, item) => {
      return (
        total +
        item.bookings.filter((booking) => booking.statuss === "cancelled")
          .length
      );
    }, 0);

    setCancelledReserved(totalCancelled);
  };

  useEffect(() => {
    getReserveCount();
  }, [countDetails]);

  const getFavouriteDetails = () => {
    navigate("/LoginDash/favourite-hotel");
  };

  const getFavouriteCount = async () => {
    const favouriteDet = await axios.get(
      `/registrations/getGuestFavoriteHotels/${guestId}`
    );
    setFavouriteHotelCount(favouriteDet.data.length);
  };

  useEffect(() => {
    getFavouriteCount();
  }, []);

  return (
    <div className='parent__log'>
      <div className='parent_loginDiv'>
        <div className='parent_Logout'>
          <div className='profil_pic'>
            {userData.gender.toLowerCase() == "male" ? (
              <img src={maleprofile} alt='profile' />
            ) : (
              <img src={femaleprofile} alt='profile' />
            )}
          </div>
          <h2 className='dashboard__header'>
            welcome!! <br />{" "}
            <span className='userNameSpan'>
              {userData.firstName} {userData.lastName}
            </span>
          </h2>
        </div>

        <div className='parentCard'>
          <div className='functionDiv' onClick={getReservation}>
            {load ? (
              <Loader />
            ) : (
              <>
                <div className='upperSession'>
                  <div className='divInfo'>
                    <AiFillFolderOpen className='historyicon' />
                    <span>
                      View <br /> Reservation
                    </span>
                  </div>
                </div>
                <hr className='hrblow' />
                <span>
                  {countDetails} <br /> Reservation
                </span>
              </>
            )}
          </div>

          <div
            className='functionDiv functionDiv2'
            onClick={getFavouriteDetails}
          >
            <div className='upperSession2'>
              <div className='divInfo'>
                <MdFavorite className='historyicon' />
                <span>
                  Favourite <br /> Hotel
                </span>
              </div>
            </div>
            <hr className='hrblow' />
            <span>
              {" "}
              {favouriteHotelCount} <br /> Favourite added{" "}
            </span>
          </div>

          <div
            className='functionDiv functionDiv3'
            onClick={getReservationToCancel}
          >
            <div className='upperSession3'>
              <div className='divInfo'>
                <MdFreeCancellation className='historyicon' />
                <span>
                  Cancel <br /> Reservation
                </span>
              </div>
            </div>
            <hr className='hrblow' />
            <span>
              {cancelledReserved}
              <br /> cancelled reservations
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashBoard;
