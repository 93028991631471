import React from "react";
import "./BookingTips.css";
import NavBar from "../../NavBar/NavBar";
import Subscribe from "../../Subscribe/Subscribe";
import GetApp from "../../Get App/GetApp";
import Footer from "../Footer";
import bookingtipsimg from '../../../assets/bookingtipsimg.png'

const BookingTips = () => {
  return (
    <>
      <NavBar />
      <div className='parentBoodkingTips'>
        <div className='bookingTipsHero'>
          <div className='bookingtipstext'>
            <p>
              <span className='firstLine'>   
                5 <span style={{ color: "#10A0FF" }}>Essential </span>Tips
              </span>{" "}
              <span className='secondLine'>for a Smart </span>
              <span className='thirdLine'>Hotel Booking</span>
            </p>

            <ol>
              <li>
                <a href='#fistbookingTips'>Location</a>
              </li>
              <li>
                <a href='#secondbookingTips'>Budget Wisely</a>
              </li>
              <li>
                <a href='#thirdbookingTips'>Amenities Matter</a>
              </li>
              <li>
                <a href='#fourthbookingTips'>Understand Policies</a>
              </li>
              <li>
                <a href='#fifthbookingTips'>Check the Buzz</a>
              </li>
            </ol>
          </div>
          <div className='bookingtipsimage'>
            <img src={bookingtipsimg} alt='' />
          </div>
        </div>
        <div className='parentmainbookingtips'>
          <div className='bookingtipsHolder'>
            <div className='firstBookingTip' id='fistbookingTips'>
              <div className='firstBookingTipimage'>
                <img
                  src='https://st2.depositphotos.com/1561359/12173/v/450/depositphotos_121739224-stock-illustration-3d-shiny-blue-number-1.jpg'
                  alt=''
                />
              </div>
              <div className='firstBookingTiptext'>
                <h2>
                  Location{" "}
                  <span className='considerLoc'>
                    Consider what's nearby such as:
                  </span>
                </h2>
                <div>
                  <ul className='considerItem'>
                    <li>supermarkets</li>
                    <li>restaurants</li>
                    <li>public transport</li>
                    <li>malls</li>
                    <li>and many more</li>
                  </ul>
                  <span>Choose a spot that fits your needs</span>
                </div>
              </div>
            </div>
            <div className='firstBookingTip' id='secondbookingTips'>
              <div className='firstBookingTipimage'>
                <img
                  src='https://st2.depositphotos.com/1561359/12173/v/450/depositphotos_121738770-stock-illustration-3d-shiny-blue-number-2.jpg'
                  alt=''
                />
              </div>
              <div className='firstBookingTiptext'>
                <h2>Budget Wisely</h2>
                <p>
                  Search within your price range and compare rates to get the
                  best deal.
                </p>
              </div>
            </div>
            <div className='firstBookingTip' id='thirdbookingTips'>
              <div className='firstBookingTipimage'>
                <img
                  src='https://st2.depositphotos.com/1561359/12173/v/450/depositphotos_121739778-stock-illustration-3d-shiny-blue-number-3.jpg'
                  alt=''
                />
              </div>
              <div className='firstBookingTiptext'>
                <h2>
                  Amenities Matter
                  <span className='considerLoc'>
                    Ensure your hotel offers essentials like
                  </span>
                </h2>
                <div>
                  <ul className='considerItem'>
                    <li>Wi-Fi</li>
                    <li>parking</li>
                    <li>gym</li>
                    <li>breakfast</li>
                    <li>and smart TVs</li>
                  </ul>
                  <span>
                    Choose hotels with good amenities for your comfort
                  </span>
                </div>
              </div>
            </div>
            <div className='firstBookingTip' id='fourthbookingTips'>
              <div className='firstBookingTipimage'>
                <img
                  src='https://st.depositphotos.com/1435425/2135/v/450/depositphotos_21354213-stock-illustration-3d-glossy-number-vector.jpg'
                  alt=''
                />
              </div>
              <div className='firstBookingTiptext'>
                <h2 className='checkBuzz'>Understand Policies</h2>
                <p>
                  Know cancellation and refund terms before booking. Flexible
                  options can save the day if plans change.
                </p>
              </div>
            </div>
            <div className='firstBookingTip' id='fifthbookingTips'>
              <div className='firstBookingTipimage'>
                <img
                  src='https://st2.depositphotos.com/1561359/12186/v/450/depositphotos_121867382-stock-illustration-3d-shiny-blue-number-5.jpg'
                  alt=''
                />
              </div>
              <div className='firstBookingTiptext '>
                <h2>Check the Buzz</h2>
                <p>
                  Scan recent reviews on hotels and platforms to gauge quality
                  and service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Subscribe />
      <GetApp />
      <Footer />
    </>
  );
};

export default BookingTips;
